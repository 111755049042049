import React from "react";
import { ImCheckmark, ImCross } from "react-icons/im";
import { formateDate, dynamicDate, getDayName } from "../../../utils/dateFunctions";

const Table = ({
  startDate,
  weekStartDate,
  weekContentStartDate,
  reportData,
  message,
  handlePaginationUp,
}) => {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT", ,];

  const styles = {
    border: "1px solid black",
    padding: "0.4rem",
    backgroundColor: "#d1e7dd",
  };
  const styled = {
    borderLeft: "1px dotted black",
    padding: "0.7rem",
    backgroundColor: "#d1e7dd",
  };
  const styl = {
    border: "1px solid black",
    padding: "0.1rem",
    textAlign: "center",
    backgroundColor: "#cfe2ff",
  };

  return (
    <div>
      <div
      
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "3rem",
        }}
      >
        <table>
          <thead>
            <tr style={styles}>
              <th style={styles}>Id</th>
              <th style={styled}>Name</th>
              {Array.from({ length: 7 }, (_, index) => (
                <th style={styled} key={index}>
                  {formateDate(
                    dynamicDate(startDate.firstMonday, index + weekStartDate)
                  )}
                  <div>
                    {getDayName(
                      dynamicDate(startDate.firstMonday, index),
                      days
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {reportData &&
              reportData.date_1.map((a) => (
                <tr key={a.userId}>
                  <td style={styles}>{a.userId}</td>
                  <td style={styles}>{a.name}</td>
                  {Array.from({ length: 7 }, (_, i) => {
                    const userData = reportData[
                      `date_${i + weekContentStartDate}`
                    ]?.find((i) => i.userId === a.userId);
                    return (
                      <td key={i} style={styl}>
                        {userData ? (
                          userData?.present ? (
                            <ImCheckmark fontSize="1.3rem" fill="green" />
                          ) : (
                            <ImCross fontSize="1.2rem" fill="red" />
                          )
                        ) : (
                          "NA"
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>{" "}
      </div>
      {reportData === undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "4rem",
            flexDirection: "column",
            fontFamily: "cursive",
            fontSize: "1.8rem",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default Table;
