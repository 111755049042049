import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const Privaterout = () => {
  const { token: reduxToken } = useSelector((state) => state.data); 
  const cookieToken = Cookies.get("token"); // From cookies
  const localStorageToken = localStorage.getItem("token"); 

  const isAuthenticated = reduxToken || cookieToken || localStorageToken;

  return isAuthenticated ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default Privaterout;
