import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../../UI_Components/Tabs";
import SubHeader from "../../../UI_Components/SubHeader";
import Inprocess from "../../../Components/OnboardingStatus/Inprocess";
import Completed from "../../../Components/OnboardingStatus/Completed";
import Pending from "../../../Components/OnboardingStatus/Pending";
import apiManager from "networking/baseAPIManager";
import { AlertMessage, Button, CommonModal, CoreLoader, Input, Label } from "common/components";
import isResourceAvailable from "utils/permit_util";
import "../../../assets/style/Responsive.css";
import { SearchFilter } from "Components/AttendaceReport";
const EmployeeOnboarding = () => {
  const [employeeOnboardingData, setEmployeeOnboardingData] = useState([]);
  const [employeeSearch, setEmployeeSearch] = useState([])
  const [formData, setFormData] = useState({});
  const [open, setOpen] = useState();
  const [errors, setErrors] = useState({});
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [loading, setLoading] = useState(false);
  const [debounceVal, setDebounceVal] = useState('');
  const [searchString, setSearchString] = useState("");
  const showStartOnboarding = isResourceAvailable("onboarding", "create");
  const rolePermission = isResourceAvailable("role");
  const employeesList = () => {
    setLoading(true);
    apiManager
      .employeeList()
      .then((response) => {
        setLoading(false);
        setEmployeeOnboardingData(response.data);
        setEmployeeSearch(response.data)
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    employeesList();
  }, []);

  let trueStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "completed");
  let approvedData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "approved");
  let pendingStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "pending");

  let arrData = [
    { title: "Pending", content: <Pending data={pendingStatusData} /> },
    { title: "In Process", content: <Inprocess data={approvedData} /> },
    { title: "Completed", content: <Completed data={trueStatusData} /> },
  ];

  const [tabs, setTabs] = useState(arrData);
  useEffect(() => {
    setTabs([...arrData]);
  }, [employeeOnboardingData]);

  const navigate = useNavigate();

  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = debounce((inputValue) => {
    setDebounceVal(inputValue);
  }, 1000);

  useEffect(() => {
    debouncedChange(searchString);
  }, [searchString])

  useEffect(() => {
    if (debounceVal) {
      setLoading(true);
      apiManager
        .employeeSearchList(debounceVal)
        .then((response) => {
          setLoading(false);
          setEmployeeOnboardingData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      setEmployeeOnboardingData(employeeSearch);
    }
  }, [debounceVal, employeeSearch]);
  const handleChange = (event, key, val) => {
    let value = event?.target?.value || val;
    formData[key] = value;
    errors[key] = "";
    setFormData({ ...formData });
  };
  const handleSaveData = () => {
    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^[6-9]\d{9}$/; // Validates Indian 10-digit mobile numbers starting with 6,7,8,9
    let allErrors = {};
    if (!formData?.firstname) {
      allErrors.firstname = "Firstname is required";
    }
    // if (!formData?.middlename) {
    //   allErrors.middlename = "Middlename is required";
    // }
    // if (!formData?.lastname) {
    //   allErrors.lastname = "Lastname is required";
    // }

    
    if (!formData?.company_email) {
      allErrors.company_email = "Company email is required";
    } else if (typeof formData.company_email !== "string" || !emailRegex.test(formData.company_email)) {
      allErrors.company_email = "Invalid company email format";
    }
    
    if (!formData?.mobile_number) {
      allErrors.mobile_number = "Mobile number is required";
    } else if (typeof formData.mobile_number !== "string" || !mobileRegex.test(formData.mobile_number)) {
      allErrors.mobile_number = "Invalid mobile number format";
    }
    
    if (!formData?.documentDob) {

      allErrors.documentDob = "Document dob is required";
    } else if (formData.documentDob) {
      const dob = new Date(formData.documentDob);
      const today = new Date();
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
      const sixtyFiveYearsAgo = new Date();
      sixtyFiveYearsAgo.setFullYear(today.getFullYear() - 65);
      const status = dob <= eighteenYearsAgo && dob >= sixtyFiveYearsAgo;
      if (!status) {
        allErrors.documentDob = "Age must be between 18 to 65 years old";
      }
    }


    setErrors({ ...allErrors });

    if (Object.keys(allErrors).length) {
      return;
    }


    let payload = {
      firstname: formData?.firstname,
      middlename: formData?.middlename,
      lastname: formData?.lastname,
      company_email: formData?.company_email?.toLowerCase(),
      mobile_number: formData?.mobile_number,
      documentDob: formData?.documentDob,
    };
    setLoading(true);
    apiManager
      .appAddCandidate(payload)
      .then((response) => {
        setLoading(false);
        setOpen(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        setFormData({});
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "failed to update");
        setOpen(false);
        setLoading(false);
      });
  };
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="container-fluid">
        <div className="AttendanceReporttop">
          <SubHeader location={useLocation()} />
          <SearchFilter
            data={searchString}
            setSearchString={setSearchString}
            placeholder="Search Employee By Name/ID"
          />
        </div>
        <div className="tab-container">
          <div className="start_onboarding gap-2">
            {rolePermission === "ADMIN" ?
              <>
                <button
                  className="button addcandidate"
                  onClick={() => setOpen(true)}>
                  Add Candidate
                </button>
                <button
                  className="button"
                  onClick={() => navigate("/hrtools/employee-onboarding/candidatedetails")}>
                  Start Onboarding
                </button>

              </> : <></>}
          </div>
          <Tabs tabs={tabs} />
        </div>
      </div>

      <CommonModal title="Add Candidate" size="md" open={open} onClose={() => setOpen(false)}>

        <div className="p-4">
          <div className=" row form-group">
            <div className="col-md-6">
              <Label label="Firstname" required labelclasses="pb-1" />
              <Input
                type="Email"
                name="firstname"
                value={formData?.firstname || ""}
                parentclasses="pb-2"
                onChange={(e) => handleChange(e, "firstname")}
                placeholder="firstname"
                touched={true}
                error={errors?.firstname}
                inputClass="w-50"
                maxLength={45}
              />

            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Middlename" labelclasses="pb-1" />
                <Input
                  valueKey="id"
                  type="text"
                  name={"middlename"}
                  value={formData["middlename"]}
                  onChange={(e) => {
                    handleChange(e, "middlename");
                  }}
                  touched={true}
                  error={errors["middlename"]}
                />
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-md-6">
              <div className="form-group">
                <Label label="Lastname" labelclasses="pb-1" />
                <Input
                  valueKey="id"
                  type="text"
                  name={"lastname"}
                  value={formData["lastname"]}
                  onChange={(e) => {
                    handleChange(e, "lastname");
                  }}
                  touched={true}
                  error={errors["lastname"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Mobile Number" required labelclasses="pb-1" />
                <Input
                  valueKey="id"
                  type="text"
                  name={"mobile_number"}
                  value={formData["mobile_number"]}
                  onChange={(e) => {
                    handleChange(e, "mobile_number");
                  }}
                  maxLength={10}
                  touched={true}
                  error={errors["mobile_number"]}
                />
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Company Email" required labelclasses="pb-1" />
                <Input
                  valueKey="id"
                  type="text"
                  name={"company_email"}
                  value={formData["company_email"]}
                  onChange={(e) => {
                    handleChange(e, "company_email");
                  }}
                  touched={true}
                  error={errors["company_email"]}
                />
              </div>

            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Document Dob" required labelclasses="pb-1" />
                <Input
                  valueKey="id"
                  type="date"
                  name={"documentDob"}
                  value={formData["documentDob"]}
                  onChange={(e) => {
                    handleChange(e, "documentDob");
                  }}
                  touched={true}
                  error={errors["documentDob"]}
                  min="1950-01-01"
                  max="2090-12-31"
                />
              </div>
            </div>
          </div>


          <div className="pt-3 text-end">
            <Button onClick={handleSaveData} label="Send" />
          </div>
        </div>
      </CommonModal>

      <CoreLoader show={loading} />
    </>
  );
};

export default EmployeeOnboarding;
