import { SCard, SubHeader, Table } from "UI_Components";
import React, { useEffect, useState } from "react";
import candidates from "../../../assets/images/useremployee.png";
import employeeId from "../../../assets/images/LeaveEmployee.png";
import joiningDate from "../../../assets/images/EmployeeOnboarding.png";
import department from "../../../assets/images/ProbationEmployee.png";
import profileimg from "../../../assets/images/profileimg.png";
import { IoBagOutline, IoCalendarNumberOutline } from "react-icons/io5";
import { LiaBirthdayCakeSolid, LiaTasksSolid } from "react-icons/lia";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, CoreLoader } from "common/components";
import CalendarApp from "common/components/CalendarApp";
import apiManager from "networking/baseAPIManager";
import "../../../assets/style/Responsive.css";
import "../../../assets/style/employeecard.css";
import "../../../assets/style/MarkAttendance.css";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { employeeAniversary, formatDateCharcter } from "utils/dateFunctions";
import NoDataFound from "common/components/NoDataFound";

export default function EmployeeHome() {
  let baseURL = process.env.REACT_APP_BASE_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeSalarylistData, setEmployeeSalarylistData] = useState({});
  const [loading, setLoading] = useState(false);
  const [InformationData, setInformationData] = useState();
  const [LeaveCountData, setLeaveData] = useState();
  const [CheackInOutData, setCheackInOutData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("checkedOut");
  const [breakStatus, setbreakStatus] = useState("Break end");
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
   const [aniversary, setaniversary] = useState([])
    const [dobemployee, setdobEmployee] = useState({
      dob_day: "",
      dob_month: ""
    })
  const [breakTime, setBreakTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [overtimeMinutes, setOvertimeMinutes] = useState({
    hours: 0,
    minutes: 0,
  });
  const [remainingTime, setRemainingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const location = useLocation();
  const [weeklyWork, setemployeeWork] = useState({
    series: [
      { name: "Working Hour", data: [] },
      { name: "Break Time", data: [] },
      { name: "Over Time", data: [] },
    ],
  });
  const [view, setView] = useState("weekly");
  console.log(InformationData?.info.joining_date, "InformationData");

  const navigate = useNavigate();

  const formatTime = ({ hours, minutes, seconds }, includeSeconds = true) => {
    if (includeSeconds) {
      return `${hours} Hrs: ${minutes} Min:  ${seconds} Sec`;
    } else {
      return `${hours} Hrs: ${minutes} Min:  ${seconds} Sec`;
    }
  };

  const formatDate = (date) => moment(date).format("DD MMM YYYY");
  const handleButtonClick = () => {
    navigate("/employee/attendance-tracking/leave-request");
  };
  const tools = [
    {
      cardTitle: `${InformationData?.Designation || "N/A"}`,
      cardContent: `${InformationData?.FullName || "N/A"}`,
      image: candidates,
    },
    {
      cardTitle: "Employee ID",
      cardContent: `${InformationData?.EmployeeID || "N/A"}`,
      image: employeeId,
    },
    {
      cardTitle: "Joining Date",
      image: joiningDate,

      cardContent: formatDate(InformationData?.info?.joining_date),
    },
    {
      cardTitle: "Department",
      cardContent: `${InformationData?.Department || "N/A"}`,
      image: department,
    },
  ];

  const itemsPerPage = 10;
  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData =
      employeeData &&
      employeeData.slice(start, end).map((item, index) => ({
        ...item,
        sno: start + index + 1,
      }));
    setDisplayedData(paginatedData);
  }, [activePage, employeeData]);
  const calculateTimeDifference = () => {
    const savedTime = JSON.parse(localStorage.getItem("saveTime")); // Assuming savedTimer is stored as JSON
    if (!savedTime) {
      console.error("No saved timer found");
      return;
    }
    const savedMoment = moment(savedTime);
    const now = moment(); // Current time
    const diffInMilliseconds = now.diff(savedMoment); // Difference in milliseconds
    const duration = moment.duration(diffInMilliseconds); // Convert to duration
    const hours = duration.hours(); // Extract hours
    const minutes = duration.minutes(); // Extract minutes
    const seconds = duration.seconds(); // Extract seconds
    const timerState = { hours, minutes, seconds };
    setTimer(timerState);
  };
  useEffect(() => {
    const savedStatus = localStorage.getItem("checkInStatus");
    const breakStatus = localStorage.getItem("breakStatus");
    const savedTimer = localStorage.getItem("checkInTimer");
    const breakedTimer = localStorage.getItem("breakTimer");
    if (savedStatus) setCurrentStatus(savedStatus);
    if (breakStatus) setbreakStatus(breakStatus);
    if (savedTimer) setTimer(JSON.parse(savedTimer));
    if (breakedTimer) setBreakTime(JSON.parse(breakedTimer));
    PersonalInformationData();
    calculateTimeDifference();
    WeeklyWork();
    EmployeeLeavecount();
  }, []);

  useEffect(() => {
    let interval;
    if (currentStatus === "checkedIn") {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const seconds = prevTimer.seconds + 1;
          if (seconds === 60) {
            const minutes = prevTimer.minutes + 1;
            if (minutes === 60) {
              const hours = prevTimer.hours + 1;
              return { hours, minutes: 0, seconds: 0 };
            }
            return { ...prevTimer, minutes, seconds: 0 };
          }
          return { ...prevTimer, seconds };
        });

        localStorage.setItem("checkInTimer", JSON.stringify(timer));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentStatus, timer]);
  useEffect(() => {
    let interval;
    if (breakStatus === "Break start") {
      interval = setInterval(() => {
        setBreakTime((prevTimer) => {
          const seconds = prevTimer.seconds + 1;
          if (seconds === 60) {
            const minutes = prevTimer.minutes + 1;
            if (minutes === 60) {
              const hours = prevTimer.hours + 1;
              return { hours, minutes: 0, seconds: 0 };
            }
            return { ...prevTimer, minutes, seconds: 0 };
          }
          return { ...prevTimer, seconds };
        });

        localStorage.setItem("breakTimer", JSON.stringify(breakTime));
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [breakStatus, breakTime]);
  const handleCheckInOut = () => {
    setLoading(true);
    const newStatus =
      currentStatus === "checkedOut" ? "checkedIn" : "checkedOut";

    apiManager
      .CheackInCheckOut()
      .then((response) => {
        setLoading(false);
        setCheackInOutData(response.data);
        setCurrentStatus(response?.data?.status);
        localStorage.setItem("checkInStatus", newStatus);
        localStorage.setItem("totalWork", response.data?.workingTime?.worktime);
        if (response?.data?.status === "checkedOut") {
          const duration = moment.duration(
            moment(
              response?.data?.workingTime?.check[
                response.data?.workingTime?.check.length - 1
              ]?.checkout
            ).diff(
              response?.data?.workingTime?.check[
                response.data?.workingTime?.check.length - 1
              ]?.checkin
            )
          );
          const timerState = {
            hours: duration?._data?.hours,
            minutes: duration?._data?.minutes,
            seconds: duration?._data?.seconds,
          };
          setTimer(timerState);
          localStorage.removeItem("checkInTimer");
        } else if (response?.data?.status === "checkedIn") {
          localStorage.setItem(
            "saveTime",
            JSON.stringify(
              response?.data?.workingTime?.check[
                response.data?.workingTime?.check.length - 1
              ]?.checkin
            )
          );
          const timerState = { hours: 0, minutes: 0, seconds: 0 };
          setTimer(timerState);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  //break time
  const handleBreakTime = () => {
    setLoading(true);
    const newStatus = breakStatus === "Break end" ? "Break start" : "Break end";

    apiManager
      .BreakTime()
      .then((response) => {
        setLoading(false);
        setbreakStatus(response?.data?.status);
        localStorage.setItem("breakStatus", newStatus);
        if (response?.data?.status === "Break end") {
          const duration = moment.duration(
            moment(
              response?.data?.workingTime?.breaks[
                response.data?.workingTime?.breaks.length - 1
              ]?.breakEnd
            ).diff(
              response?.data?.workingTime?.breaks[
                response.data?.workingTime?.breaks.length - 1
              ]?.breakStart
            )
          );
          const timerState = {
            hours: duration?._data?.hours,
            minutes: duration?._data?.minutes,
            seconds: duration?._data?.seconds,
          };
          setBreakTime(timerState);
          localStorage.setItem("breakDuration", response.data?.breakDuration);
          localStorage.removeItem("breakTimer");
        } else if (response?.data?.status === "Break start") {
          localStorage.setItem(
            "saveBreak",
            JSON.stringify(
              response?.data?.workingTime?.breaks[
                response.data?.workingTime?.breaks.length - 1
              ]?.breakStart
            )
          );
          const timerState = { hours: 0, minutes: 0, seconds: 0 };
          setBreakTime(timerState);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const PersonalInformationData = () => {
    setLoading(true);

    apiManager
      .PersonalInformation()
      .then((response) => {
        setLoading(false);
        setInformationData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const EmployeeLeavecount = () => {
    setLoading(true);

    apiManager
      .EmployeeleaveConut()
      .then((response) => {
        setLoading(false);
        setLeaveData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // Dummy data for weekly view
  const weeklyData = {
    series: weeklyWork.series,
    options: {
      chart: {
        type: "bar",
        toolbar: { show: false }, // Hides the chart toolbar
      },
      xaxis: {
        categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        title: { text: "", style: { fontWeight: 600 } },
      },
      yaxis: {
        title: { text: "Hours", style: { fontWeight: 600 } },
      },
      legend: {
        position: "bottom", // Position legend at the top
        horizontalAlign: "left", // Align to the right
        markers: { radius: 2 }, // Rounded markers
      },
      colors: ["#18BDC3", "#F26532", "#FEB019"], // Custom colors for each series
      dataLabels: { enabled: false }, // Disable data labels on bars
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%", // Adjust bar width
          borderRadius: 2, // Add rounded corners to bars
        },
      },
      tooltip: {
        theme: "light",
        y: { formatter: (value) => `${value} hours` },
      },
    },
  };

  const monthlyData = {
    series: [
      {
        name: "Working Hour",
        data: [16, 22, 18, 24, 20, 25, 27, 19, 23, 18, 20, 22],
      },
      { name: "Break Time", data: [6, 5, 7, 6, 5, 6, 5, 6, 5, 6, 7, 6] },
      {
        name: "Remaining Time",
        data: [50, 46, 46, 45, 37, 43, 31, 35, 34, 54, 43, 43],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: { show: false },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        title: { text: "", style: { fontWeight: 600 } },
      },
      yaxis: {
        title: { text: "Hours", style: { fontWeight: 600 } },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        markers: { radius: 2 },
      },
      colors: ["#18BDC3", "#F26532", "#FEB019"],
      dataLabels: { enabled: false },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 2,
        },
      },
      tooltip: {
        theme: "light",
        y: { formatter: (value) => `${value} hours` },
      },
    },
  };

  // Select data based on the view
  const chartData = weeklyData;
  const totalWork = () => {
    const totalWork = localStorage.getItem("totalWork");
    const totalMinutes = Number(totalWork);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
    return `${hours} hrs,${minutes} min`;
  };
  const totalBreakTime = () => {
    const totalWork = localStorage.getItem("breakDuration");
    const totalMinutes = Number(totalWork);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
    return `${hours} hrs,${minutes} min`;
  };
  const WeeklyWork = () => {
    try {
      apiManager
        .EmployeeWeeklyWork()
        .then((response) => {
          if (response?.data?.data) {
            const apiData = response.data.data;
            const workingHours = apiData.map(
              (item) => Math.round(item.worktime) || 0
            );
            const breakTime = apiData.map(
              (item) => Math.round(item.breaktime) || 0
            );
            const overTime = apiData.map(
              (item) => Math.round(item.overtime) || 0
            );
            setemployeeWork((prevState) => ({
              ...prevState,
              series: [
                { name: "Working Hour", data: workingHours },
                { name: "Break Time", data: breakTime },
                { name: "Over Time", data: overTime },
              ],
            }));
          }
        })
        .catch((error) => { });
    } catch (error) { }
  };
  const employeeBirthday = () => {
    setLoading(true);
    try {
      apiManager.EmployeeBirthday().then((response) => {
        setLoading(false)
        setdobEmployee((prev) => ({
          ...prev,
          dob_day: response.data
        }))
      }).catch()

    } catch (error) {
      console.error(error);
    }
  }
  const employeesAniversay = () => {
    setLoading(true);
    try {

      apiManager.EmployeeAniversary().then((response) => {
        setLoading(false)
        setaniversary(response?.data?.data)
      }).catch()
    } catch (error) {
      console.error(error);
    }
  }
  const employeeBirthByMonth = () => {
    setLoading(true);
    try {
      apiManager.EmployeeBirthmonth().then((response) => {
        setLoading(false)
        setdobEmployee((prev) => ({
          ...prev,
          dob_month: response.data
        }))
      }).catch()

    } catch (error) {
      console.error(error);
    }
  }
   useEffect(() => {
      employeeBirthday()
      employeeBirthByMonth()
      employeesAniversay()
    
    }, []);
  return (
    <>
      <div className="container-fluid">
        <SubHeader location={location} />

        <SCard tools={tools} />

        <div className="row employeetoprow">
         

          <div className="col-8">
            <div className="card">
              <div className="card-top">
                <div className="card-title">Weekly Working Hours</div>
                {/* <Button className='selectbtn'
                  label={
                    view === "weekly" ? " Monthly" : " Weekly"
                  }
                  variant="primary"
                  onClick={() =>
                    setView((prev) =>
                      prev === "weekly" ? "monthly" : "weekly"
                    )
                  }
                >
                  {view === "weekly" ? "Switch to Monthly" : "Switch to Weekly"}
                </Button> */}
              </div>

              {/* ApexCharts */}
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={276}
              />
            </div>
            {/* <WorkingHours/> */}
          </div>
          {/* Leave Details Section */}
          <div className="col-4">
            <div className="card">
              {/* Header */}
              <div className="card-top">
                <div className="card-title">Leave Details</div>
              </div>

              {/* Leave Stats */}
              <div className="row LeaveDetailsrow">
                {/* Left Column */}
                <div className="col-md-6">
                  <div className="leave-section">
                    <div className="leave-title">Total Leave</div>
                    <div className="leave-count">
                      {LeaveCountData?.totalLeavesPerYear}
                    </div>
                  </div>
                  <div className="leave-section">
                    <div className="leave-title">Remaining Leave</div>
                    <div className="leave-count">
                      {LeaveCountData?.remainingLeaves}
                    </div>
                  </div>
                </div>
                {/* Right Column */}
                <div className="col-md-6">
                  <div className="leave-section">
                    <div className="leave-title">Unpaid Leave</div>
                    <div className="leave-count">
                      {LeaveCountData?.unpaidLeaves}
                    </div>
                  </div>
                  <div className="leave-section">
                    <div className="leave-title">Paid Leave</div>
                    <div className="leave-count ">
                      <span>{LeaveCountData?.paidLeaves}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer Button */}
              <div className="text-end">
                <Button   
                  label="Apply Leave"
                  onClick={handleButtonClick}
                />
              </div>
            </div>
          </div>
          </div>
          <div className="row employeeBottom">
               {/* Calendar Section */}
               <div className="col-4">
            <div className="card card-Calendar-head">
            <div class="card-title"><span>Upcoming</span> Holiday</div>
              <CalendarApp />
            </div>
          </div>
          {/* Projects Section */}
          <div className="col-5 ">
            <div className="card">
              <div className="card-top">
                <div className="card-title">Projects</div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="project-card">
                    <h6>Office Management</h6>

                    <div className="Management-info clearfix">
                      <figure>
                        <img src={profileimg} alt="" />
                      </figure>
                      <div className="Management-title">
                        <strong>Leader</strong> Disha Tailang
                      </div>
                    </div>

                    <div className="Management-info clearfix">
                      <figure className="i-bg">
                        <IoCalendarNumberOutline />
                      </figure>
                      <div className="Management-title">
                        <strong>Deadline</strong> 14 Jan 2024
                      </div>
                    </div>

                    <div className="ProjectsTasks">
                      <figure>
                        <LiaTasksSolid />
                      </figure>
                      <strong>Tasks : </strong> 6 / 10
                    </div>

                    <div className="TimeSpent-bg">
                      <strong>Time Spent:</strong> <span>65 / 120 Hrs</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="project-card">
                    <h6>Office Management</h6>

                    <div className="Management-info clearfix">
                      <figure>
                        <img src={profileimg} alt="" />
                      </figure>
                      <div className="Management-title">
                        <strong>Leader</strong> Disha Tailang
                      </div>
                    </div>

                    <div className="Management-info clearfix">
                      <figure className="i-bg">
                        <IoCalendarNumberOutline />
                      </figure>
                      <div className="Management-title">
                        <strong>Deadline</strong> 14 Jan 2024
                      </div>
                    </div>

                    <div className="ProjectsTasks">
                      <figure>
                        <LiaTasksSolid />
                      </figure>
                      <strong>Tasks : </strong> 6 / 10
                    </div>

                    <div className="TimeSpent-bg">
                      <strong>Time Spent:</strong> <span>65 / 120 Hrs</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="col-3">
            <div className="card">
              <div className="card-top">
                <div className="card-title">
                  Birthday / Work Anniversary
                </div>
              </div>
              {dobemployee.dob_day?.data?.length || dobemployee.dob_month?.data?.length || aniversary.length ?
                <div className="BirthdayAnniversary-box" >
                  <div className="Birthday-day">
                    {dobemployee?.dob_day?.data?.length ?
                      <h5>Today Birthday </h5> : <></>}
                    <ul>
                      {dobemployee.dob_day?.data?.map((dob) => (
                        <>
                          <li>
                            <div className="Birthday-text clearfix">
                              <figure> <img src={dob.image ? `${baseURL}/${dob.image}` : profileimg} alt="User" /> </figure>
                              <div className="Birthday-name">
                                <h6>
                                  {dob.firstname}
                                  {dob.middlename || !dob.middlename === undefined ? ` ${dob.middlename}` : ""}
                                  {dob.lastname ? ` ${dob.lastname}` : ""}
                                </h6>
                                <span>{dob.designation}</span>
                              </div>
                            </div>
                            <div className="Birthday-info">
                              <div className="Birthday-date">
                                <figure><LiaBirthdayCakeSolid /></figure>
                                <p>{formatDateCharcter(dob.originalDob)}</p>
                              </div>
                            </div>
                          </li>

                        </>
                      ))}
                    </ul>
                    <ul>
                    {aniversary?.length ?
                      <h5 className="">Work Anniversary</h5> : <></>}
                      {aniversary
                        ?.filter((data) => employeeAniversary(data?.joining_date)) 
                        ?.map((data, index) => ( 
                          <li key={index}>
                            <div className="Birthday-text clearfix">
                              <figure>
                                <img src={data?.image ? `${baseURL}/${data?.image}` : profileimg} alt="User" />
                              </figure>
                              <div className="Birthday-name">
                                <h6>
                                  {data.firstname} {data.middlename ? ` ${data.middlename}` : ""} {data.lastname ? ` ${data.lastname}` : ""}
                                </h6>
                                <span>{data.designation}</span>
                              </div>
                            </div>
                            <div className="Birthday-info Anniversary">
                              <div className="Anniversarytext">{employeeAniversary(data?.joining_date)} Year Completed</div>
                              <div className="Birthday-date">
                                <figure>
                                  <IoBagOutline />
                                </figure>
                                <p>{formatDateCharcter(data?.joining_date?.slice(0, 10))}</p>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>

                  </div>
                  <div className="Birthday-day">
                    {dobemployee?.dob_month?.data?.length ?
                      <h5>Upcoming Birthday</h5> : <></>}
                    <ul>
                      {dobemployee?.dob_month?.data?.map((data, i) => (
                        <li>
                          <div className="Birthday-text clearfix">
                            <figure> <img src={data.image ? `${baseURL}/${data.image}` : profileimg} alt="User" /> </figure>
                            <div className="Birthday-name">
                              <h6>
                                {data.firstname}
                                {data.middlename && !data.middlename === undefined ? ` ${data.middlename}` : ""}
                                {data.lastname ? ` ${data.lastname}` : ""}
                              </h6>

                              <span>{data?.profile}</span>
                            </div>
                          </div>
                          <div className="Birthday-info">
                            <div className="Birthday-date">
                              <figure><LiaBirthdayCakeSolid /></figure>
                              <p>{formatDateCharcter(data?.originalDob)}</p>
                            </div>
                          </div>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>

                : <NoDataFound />
              }
            </div>
          </div>
          </div>

        <CoreLoader show={loading} />
      </div>
    </>
  );
}
