import { Button } from "common/components";
import React from "react";
import { useLocation } from "react-router-dom";
import { SubHeader, Tabs } from "UI_Components";
import userimage from "../../assets/images/userImage.jpg";
import { FaEnvelope, FaUserTie } from "react-icons/fa";
import TabsComponent from "common/components/TabsComponent";
import { FaUser, FaBriefcase, FaGraduationCap, FaClock } from "react-icons/fa";
import Personal from "./profile/Personal";

const Profile = () => {
  const location = useLocation();
  const tabs = [
    {
      key: "personal",
      label: "Personal Details",
      icon: <FaUser />,
      content: <Personal />,
    },
    {
      key: "professional",
      label: "Professional Details",
      icon: <FaBriefcase />,
      content: <p>Professional Info Here</p>,
    },
    {
      key: "education",
      label: "Education",
      icon: <FaGraduationCap />,
      content: <p>Education Info Here</p>,
    },
    {
      key: "experience",
      label: "Experience",
      icon: <FaClock />,
      content: <p>Experience Info Here</p>,
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <SubHeader location={location} />
        <div className="card mt-2">
          <div className="profile-card d-flex flex-column flex-md-row align-items-center p-3 text-center text-md-start">
            {/* Profile Image */}
            <img
              src={userimage}
              alt="Profile"
              className="mb-3 mb-md-0 me-md-3"
              style={{ borderRadius: "15px", width: "126px", height: "126px" }}
            />

            {/* Profile Info */}
            <div className="profile-info flex-grow-1">
              <h3 className="text-xl font-semibold">Meenu</h3>
              <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                <FaUserTie className="text-orange me-2" /> UI & UX Designer
              </p>
              <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                <FaEnvelope className="text-orange me-2" />{" "}
                meenu.s@singhsoft.com
              </p>
            </div>

            {/* Edit Button */}
            <div className="mt-3 mt-md-0">
              <Button
                label="Edit Profile"
                onClick={() => {}}
                variant="primary"
              />
            </div>
          </div>

          <TabsComponent tabs={tabs} />
        </div>
      </div>
    </>
  );
};

export default Profile;
