import React from "react";
import { FaEye, FaDownload } from "react-icons/fa";
import "../../../assets/style/TabsComponent.css"
const Personal = () => {
  return (
    <div className="mt-4 p-4 shadow-sm rounded bg-white">
      <div className="row align-items-center mb-3">
        <div className="col">
          <h4 className="fw-bold">Personal Details</h4>
        </div>
        {/* <div className="col text-end">
          <button className="btn btn-info text-white">Edit Profile</button>
        </div> */}
      </div>

      <div className="row border rounded p-3">
        {/** First Name **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">First Name</p>
          <div className="pb-2 border-bottom">Disha</div>
        </div>

        {/** Last Name **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Last Name</p>
          <div className="pb-2 border-bottom">Tailang</div>
        </div>

        {/** Email ID **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Email ID</p>
          <div className="pb-2 border-bottom">dishatailang@gmail.com</div>
        </div>

        {/** Documental DOB **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Documental DOB</p>
          <div className="pb-2 border-bottom">26/12/1999</div>
        </div>

        {/** Original DOB **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Original DOB</p>
          <div className="pb-2 border-bottom">26/12/1993</div>
        </div>

        {/** Gender **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Gender</p>
          <div className="pb-2 border-bottom">Female</div>
        </div>

        {/** Mobile No **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Mobile No.</p>
          <div className="pb-2 border-bottom">8740865095</div>
        </div>

        {/** Emergency Contact No **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Emergency Contact No.</p>
          <div className="pb-2 border-bottom">7023314027</div>
        </div>

        {/** Marital Status **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Marital Status</p>
          <div className="pb-2 border-bottom">Married</div>
        </div>

        {/** Aadhar Card **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Aadhar Card</p>
          <div className="pb-2 border-bottom">Aadhar Card No
          <FaEye className="ms-2 text-muted" />
          <FaDownload className="ms-2 text-primary" /> 
          </div>
        </div>

        {/** PAN Card **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">PAN Card</p>
          <div className="d-flex align-items-center pb-2 border-bottom">
            PAN No <FaEye className="ms-2 text-muted" />
            <FaDownload className="ms-2 text-primary" /> 
          </div>
        </div>

        {/** Address **/}
        <div className="col-md-6 mb-3">
          <p className="d-block text-muted">Address</p>
          <div className="pb-2 border-bottom">102/41 Pratap Nagar</div>
        </div>

        {/** City **/}
        <div className="col-md-6 mb-3">
          <p className="d-block text-muted">City</p>
          <div className="pb-2 border-bottom">Jaipur</div>
        </div>

        {/** State **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">State</p>
          <div className="pb-2 border-bottom">Rajasthan</div>
        </div>

        {/** Zip **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Zip</p>
          <div className="pb-2 border-bottom">302033</div>
        </div>

        {/** Country **/}
        <div className="col-md-4 mb-3">
          <p className="d-block text-muted">Country</p>
          <div className="pb-2 border-bottom">India</div>
        </div>
      </div>
    </div>
  );
};

export default Personal;
