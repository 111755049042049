import { AlertMessage, Button, CoreLoader, Input, Label } from "common/components";
import { useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import { useState } from "react";

export default function EmployeeDetail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const handleSubmit = (e) => {
    e.preventDefault();

    const deatials = `?company_email=${email}`;
    setLoading(true);
    apiManager
      .createdocument(deatials)
      .then((res) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
        navigate(`/hrtools/generate-documents/employee-details`, { state: res.data });
      })
      .catch((error) => {
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "failed to update");
        setLoading(false);
        console.log(error?.response?.data?.message, "message");
      });
  };

  return (
    <div>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <form onSubmit={handleSubmit}>
          <div className="p-3">
            <div className="form-group">
              <Label label="Employee Email"  />
              <Input

                name="email_id"
                type="email"
                placeholder="Enter employee email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-end pt-2">
              <Button label="Submit" type="submit" />
            </div>
          </div>

        </form>
        <CoreLoader show={loading} disableLines />
    </div>
  );
}
