import Cookies from "js-cookie";
import { decryptData, encryptData } from "./crypto";


export const setSecureLocalData = (key, data) => {
  if (data) {
    localStorage.setItem(key, encryptData(data)); 
  } else {
    const storedData = localStorage.getItem(key);
    return storedData ? decryptData(storedData) : null;
  }
};

export const setSecureSessionData = (key, data) => {
  if (data) {
    sessionStorage.setItem(key, encryptData(data)); 
  } else {
    const storedData = sessionStorage.getItem(key);
    return storedData ? decryptData(storedData) : null;
  }
};

export const setSecureCookie = (key, data, days = 7) => {
  Cookies.set(key, encryptData(data), { expires: days, secure: true, sameSite: "Strict" });
};

export const getSecureCookie = (key) => {
  const storedData = Cookies.get(key);
  return storedData ? decryptData(storedData) : null;
};
