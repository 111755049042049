import { AlertMessage, Button, CoreLoader, DownloadIcon, Dropdown, Input, Label } from "common/components";
import { useFormik } from "formik";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import userDefaultImage from "../../../../assets/images/UserDefultImage.png";
import { OnboardingFormSchema } from "utils/formValidationUtils";
import { IoCheckbox } from "react-icons/io5";
import { convertDateFormat, convertDateFormatDash } from "utils/dateFunctions";



function Candidatedetails() {
  const paramsData = useParams();
  let baseURL = process.env.REACT_APP_BASE_URL;
  const imageInputRef = useRef();
  const navigate = useNavigate();
  let empid = paramsData?.empid;
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [loading, setLoading] = useState(false);
  const [empdata, setEmpdata] = useState({});
  const [relationshipData, setRelationshipData] = useState([]);
  const [companylist, setCompanyList] = useState([]);
  const [martialstatus, setMartialstatus] = useState([]);
  const [nextPage, setnextPage] = useState(false);


  const getRelationshipData = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("familyRelations")
      .then((response) => {
        setLoading(false);
        setRelationshipData(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  //get companylist
  const getCompanyListData = () => {
    setLoading(true);

    apiManager
      .getDropdownCompany()
      .then((response) => {
        setLoading(false);
        setCompanyList(response?.data?.data)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getCandidateDetails = () => {
    setLoading(true);
    apiManager
      .candidateDetails(empid)
      .then((candidate) => {
        setLoading(false);
        setEmpdata(candidate?.data?.data);
        setnextPage(!!candidate?.data?.nextPage);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (empid) getCandidateDetails();
  }, [empid]);

  useEffect(() => {
    getRelationshipData();
    getCompanyListData()
  }, []);

  const forminitialValue = {
    profileImage: empdata?.image || "",
    firstName: empdata?.firstname || "",
    middleName: empdata?.middlename || "",
    lastName: empdata?.lastname || "",
    dobActual: empdata?.originalDob ? empdata?.originalDob : "",
    dobDocument:empdata?.documentDob || "",
    // dobActual: empdata?.originalDob || "",
    joining_date: convertDateFormatDash(empdata?.joining_date) || "",
    marital_status: empdata?.marital_status || "",
    gender: empdata?.gender || "",
    email: empdata?.email || "",
    mobileNum: empdata?.mobile_number || "",
    sec_mobileNum: empdata?.emergency_number || "",
    aadharcard: empdata?.aadharcard_no?.toString() || "",
    pancard: empdata?.pancard_no || "",
    pancardfile: empdata?.pan_image || "",
    aadharcardfile: empdata?.aadhar_image || "",
    Pf_number: empdata?.pf_number || "",
    Uan_number: empdata?.uan_number || "",
    company_name: empdata?.company_name || "",
    family_member_first_name: empdata?.family_member_first_name || "",
    family_member_last_name: empdata?.family_member_last_name || "",
    relationship: empdata?.relationship || "",
    family_member_dob: empdata?.family_member_dob || "",
    family_member_phone: empdata?.family_member_phone || "",
    family_member_email: empdata?.family_member_email || "",
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: OnboardingFormSchema,
    onSubmit: (values) => {
      handleSave(values);
    },
    enableReinitialize: true,
  });
  
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   // Remove whitespace
  //   const trimmedValue = value.replace(/\s+/g, "");
  //   setFieldValue(name, trimmedValue);
  // };


  let userImage = values.profileImage ? `${baseURL}/${values.profileImage}` : userDefaultImage;

  if (typeof values.profileImage == "object") {
    userImage = URL.createObjectURL(values.profileImage);
  }

  let handleUpdadate = (formData) => {
    setLoading(true);
    apiManager
      .updateEmployee(formData)
      .then((res) => {
        setLoading(false);
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`);
      })
      .catch((err) => {
        setLoading(false);
        setShowMSG(err?.response?.data?.errors || "Failed to Update Details");
        setmsgType("error");
        console.log(err);
      });
  };

  const handleSave = (data) => {
    let formData = new FormData();
    if (empid) {
      formData.append("empid", empid);
    }
    const formattedDob = data.dobActual ? data.dobActual : "";

    formData.append("firstname", data.firstName);
    formData.append("middlename", data.middleName);
    formData.append("lastname", data.lastName);
    formData.append("documentDob", data.dobDocument);
    formData.append("originalDob", formattedDob);
    formData.append("joining_date", new Date(data.joining_date));
    formData.append("gender", data.gender);
    formData.append("email", data?.email?.toLowerCase());
    formData.append("mobile_number", data.mobileNum);
    formData.append("emergency_number", data.sec_mobileNum);
    formData.append("aadharcard_no", data.aadharcard);
    formData.append("pancard_no", data.pancard);
    formData.append("pan_image", data.pancardfile);
    formData.append("aadhar_image", data.aadharcardfile);
    formData.append("marital_status", data.marital_status);
    formData.append("pf_number", data.Pf_number);
    formData.append("uan_number", data.Uan_number);
    formData.append("company_name", data.company_name);
    formData.append("image", data.profileImage);
    formData.append("family_member_first_name", data.family_member_first_name);
    formData.append("family_member_last_name", data.family_member_last_name);
    formData.append("relationship", data.relationship);
    formData.append("family_member_dob", data.family_member_dob);
    formData.append("family_member_phone", data.family_member_phone);
    formData.append("family_member_email", data.family_member_email);

    if (empid) {
      handleUpdadate(formData);
      return;
    }
    setLoading(true);
    apiManager
      .addEmployee(formData)
      .then((res) => {
        setLoading(false);
        let empId = res?.data?.data?._id;
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/addressdetails/${empId}`);
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.errors || "Failed to Update Details");
      });
  };

  const renderFileInput = (name) => {
    if (values[name] && typeof values[name] == "string") {
      const downloadAttachment = async () => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        const attachmentData = `${BASE_URL}/${values[name]}`;

        if (!attachmentData) {
          console.error("No attachment data found.");
          return;
        }

        try {
          const response = await fetch(attachmentData);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values[name]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error("Error fetching or triggering download:", error);
        }
      };
      return (
        <div className="form-control">
          <div className="downloadAttachmentform">
            <Button className="button" onClick={() => downloadAttachment()} label="Download" type="button" />
            {empdata.status == "pending" || empdata.status == "completed" ? (
              <span
                className="material-symbols-outlined pointer button"
                onClick={() => {
                  setFieldValue([name], undefined);
                }}
              >
                edit
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    return (
      <Input
        type="file"
        label="Upload Result"
        accept="image/*, .pdf"
        name={name}
        onChange={(e) => {
          setFieldValue(name, e.target.files[0]);
        }}
        onBlur={handleBlur}
        isValid={touched[name] && !errors[name]}
        touched={touched[name]}
        error={errors[name]}
      />
    );
  };
  const handleFirstletterChange = (event) => {
    const { name, value } = event.target;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    handleChange({ target: { name, value: capitalizedValue } });
  };
  return (
    <>

      <div className="card">
        <div className="Onboarding-title">Candidate Onboarding</div>
        <div className="Onboarding-info-row">
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Candidate Details</div>
          </div>
          <div className="Onboarding-check">
            <IoCheckbox />
            <div className="Onboarding-check-title">Current Address</div>
          </div>
          <div className="Onboarding-check">
            <IoCheckbox />
            <div className="Onboarding-check-title">Education Details</div>
          </div>
          <div className="Onboarding-check">
            <IoCheckbox />
            <div className="Onboarding-check-title">Experience Details</div>
          </div>
        </div>

      </div>


      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <form className="mt-3" onSubmit={handleSubmit}>
        <div className="card">
          <div className="card-top"><div className="card-title">Candidate Details</div></div>
          <div className="top-details Clearfix">
            <div className="details-user-img">
              <div className="d-flex justify-content-center pb-2">
                <figure>
                  <img className="userImage" src={userImage} width="100px" height="100px" alt="User" />
                </figure>

                <input
                  id="profileImageInput" // Assign an ID for selection
                  accept="image/jpeg, image/jpg ,image/png"
                  type="file"
                  name="profileImage"
                  className="d-none"
                  onChange={(e) => {
                    const file = e.target.files[0];

                    if (file) {
                      setFieldValue("profileImage", file);
                    }
                  }}
                />
                 
              </div>

              {/* Show validation error */} 
              <span className="filesize text-muted fw-light">Image accept jpg jpeg png Max: 2MB.</span>
              {touched.profileImage && errors.profileImage && (
                <p className="fw-12 pt-1 d-flex justify-content-center " style={{ color: "#ff0606" }}>{errors.profileImage}</p>
              )}

              <div className="d-flex justify-content-center mt-2">
                <Button
                  type="button"
                  label="Upload Photo"
                  onClick={() => document.getElementById("profileImageInput")?.click()} // Use ID selector
                />
              </div>
            </div>

            <div className="details-top-right">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label label="First Name" required labelclasses="" />
                    <Input
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleFirstletterChange}
                      onBlur={handleBlur}
                      maxLength={15}
                      isValid={touched.firstName && !errors.firstName}
                      touched={touched.firstName}
                      error={errors.firstName}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label label="Middle Name" labelclasses="" />
                    <Input
                      type="text"
                      name="middleName"
                      value={values.middleName}
                      onChange={handleFirstletterChange}
                      onBlur={handleBlur}
                      maxLength={15}
                      touched={touched.middleName}
                      // isValid={touched.middleName && !errors.middleName}
                      error={errors.middleName}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label label="Last Name" labelclasses="" />
                    <Input
                      type="text"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleFirstletterChange}
                      onBlur={handleBlur}
                      maxLength={15}
                      isValid={touched.lastName && !errors.lastName}
                      touched={touched.lastName}
                      error={errors.lastName}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label labelclasses="pb-1" label="Documental DOB" required />
                    <Input
                      type="date"
                      name="dobDocument"
                      value={values.dobDocument}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.dobDocument && !errors.dobDocument}
                      touched={touched.dobDocument}
                      error={errors.dobDocument}
                      min="1950-01-01"
                      max="2999-12-31"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label labelclasses="pb-1" label="Original DOB" required />
                    <Input
                      type="datetime-local"
                      name="dobActual"
                      value={values.dobActual}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.dobActual && !errors.dobActual}
                      touched={touched.dobActual}
                      error={errors.dobActual}
                      min="1950-01-01"
                      max="2999-12-31"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <Label labelclasses="pb-1" label="Date Of Joining" required />
                    <Input
                      type="date"
                      name="joining_date"
                      value={values.joining_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.joining_date && !errors.joining_date}
                      touched={touched.joining_date}
                      error={errors.joining_date}
                      min="2012-01-01"
                      max="2999-12-31"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              {/* <div className="form-group">
                <label className="lables" for="Gender"> Gender</label>
                <select className="form-control form-select" name="Gender"><option value="male">male</option><option value="Female">Female</option><option value="Other">Other</option></select>

                <div className="d-none">
                  <Label labelclasses="" label="Gender" htmlFor="" required />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="male"
                      name="gender"
                      id="male"
                      checked={values.gender === "male"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.gender && !errors.gender}
                    />
                    <label className="form-check-label" for="male">
                      Male
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="female"
                      name="gender"
                      id="female"
                      checked={values.gender === "female"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.gender && !errors.gender}
                    />
                    <label className="form-check-label" for="female">
                      Female
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="other"
                      name="gender"
                      id="other"
                      checked={values.gender === "other"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.gender && !errors.gender}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  {touched.gender && errors.gender && <div className="error_message">{errors.gender}</div>}
                </div>

              </div> */}
              <div className="form-group">
                <Label labelclasses="" label="Gender" required />
                <Dropdown
                  type="text"
                  name="gender"
                  options={[{ key: "male", value: "Male" }, { key: "female", value: "Female" }, { key: "others", value: "Others" }]}
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.gender && !errors.gender}
                  touched={touched.gender}
                  error={errors.gender}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Personal Email" required />
                <Input
                  name="email"
                  value={values.email.toLowerCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.email && !errors.email}
                  touched={touched.email}
                  error={errors.email}
                  maxLength={64}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Mobile No." required />
                <Input
                  type="text"
                  name="mobileNum"
                  value={values.mobileNum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.mobileNum && !errors.mobileNum}
                  maxLength={10}
                  touched={touched.mobileNum}
                  error={errors.mobileNum}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Emergency Contact" required />
                <Input
                  type="text"
                  name="sec_mobileNum"
                  value={values.sec_mobileNum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.sec_mobileNum && !errors.sec_mobileNum}
                  maxLength={10}
                  touched={touched.sec_mobileNum}
                  error={errors.sec_mobileNum}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Aadhaar Number" required />
                <Input
                  type="text"
                  name="aadharcard"
                  value={values.aadharcard}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.aadharcard && !errors.aadharcard}
                  maxLength={12}
                  touched={touched.aadharcard}
                  error={errors.aadharcard}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                {/* <label className="lables" for="Aadhaar"> Aadhaar card</label> */}
                <Label labelclasses="" label="Aadhaar card" required />     
                <span className="filesize ml-2">(Maximum size: 2MB.)</span>
                <p className="mb-0 ">
                  {renderFileInput("aadharcardfile")}
                </p>
                <p className="mb-0 " style={{fontSize:'12px'}}>(Upload a single PDF both the front and back of Aadhaar card.)</p>
      
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Pan Number" required />
                <Input
                  type="text"
                  name="pancard"
                  value={values.pancard.toUpperCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.pancard && !errors.pancard}
                  maxLength={10}
                  touched={touched.pancard}
                  error={errors.pancard}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                {/* <label  for="Pan" required> Pan card</label> */}
                <Label labelclasses="" label=" Pan card" required />

                <p className="mb-0 ">
                  {renderFileInput("pancardfile")}
                </p>

                <span className="filesize">Maximum file size allowed : 2MB.</span>


              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Marital Status" required />
                <Dropdown
                  type="text"
                  name="marital_status"
                  options={["married", "unmarried", "other"]}
                  value={values.marital_status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.marital_status && !errors.marital_status}
                  touched={touched.marital_status}
                  error={errors.marital_status}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="PF No." />
                <Input
                  type="text"
                  name="Pf_number"
                  value={values.Pf_number}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.Pf_number && !errors.Pf_number}
                  maxLength={22}
                  touched={touched.Pf_number}
                  error={errors.Pf_number}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="UAN No." />
                <Input
                  type="text"
                  name="Uan_number"
                  value={values.Uan_number}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.Uan_number && !errors.Uan_number}
                  maxLength={12}
                  touched={touched.Uan_number}
                  error={errors.Uan_number}

                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Onboarding Company" required />
                <Dropdown
                  type="text"
                  name="company_name"
                  options={companylist}
                  value={values.company_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.company_name && !errors.company_name}
                  touched={touched.company_name}
                  error={errors.company_name}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-2 mb-2">
              <div className="card-top"><div className="card-title">Family Information</div></div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="First Name" required />
                <Input
                  type="text"
                  name="family_member_first_name"
                  value={values.family_member_first_name}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_first_name && !errors.family_member_first_name}
                  maxLength={15}
                  touched={touched.family_member_first_name}
                  error={errors.family_member_first_name}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Last Name" />
                <Input
                  type="text"
                  name="family_member_last_name"
                  value={values.family_member_last_name}
                  onChange={handleFirstletterChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_last_name && !errors.family_member_last_name}
                  maxLength={15}
                  touched={touched.family_member_last_name}
                  error={errors.family_member_last_name}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Relationship" required />
                <Dropdown
                  type="text"
                  name="relationship"
                  options={relationshipData}
                  value={values.relationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.relationship && !errors.relationship}
                  touched={touched.relationship}
                  error={errors.relationship}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Date Of Birth" required />
                <Input
                  type="date"
                  name="family_member_dob"
                  value={values.family_member_dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.family_member_dob && !errors.family_member_dob}
                  touched={touched.family_member_dob}
                  error={errors.family_member_dob}
                  min="1900-01-01"
                  max="2999-12-31"
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Phone" required />
                <Input
                  type="tel"
                  name="family_member_phone"
                  value={values.family_member_phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={10}
                  isValid={touched.family_member_phone && !errors.family_member_phone}
                  touched={touched.family_member_phone}
                  error={errors.family_member_phone}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <Label labelclasses="" label="Email" />
                <Input
                  type="text"
                  name="family_member_email"
                  value={values.family_member_email.toLowerCase()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={35}
                  isValid={touched.family_member_email && !errors.family_member_email}
                  touched={touched.family_member_email}
                  error={errors.family_member_email}
                />
              </div>
            </div>
          </div>
        </div>


        <div className="d-flex gap-3 justify-content-end mt-4">
          <Button type="submit" label="Save & Continue"       classes="responsiver-button" />
          {nextPage ? (
            <Button
              type="button"
              label="Next"
                classes="btn-Continue"
              onClick={() => navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`)}
            />
          ) : (
            ""
          )}
        </div>

      </form>
      <CoreLoader show={loading} />
    </>
  );
}

export default Candidatedetails;
