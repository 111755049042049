import React from "react";
import { useNavigate } from "react-router-dom";

function SCard({ tools, }) {
  const navigate = useNavigate();
  const colors = ["#18BDC3", "#FDB731", "#18BDC3", "#F26532"];
  const handleCardClick = (NavigationPath) => {
    navigate(NavigationPath);
  };

  return (

    <div className="row topcard-row scard">
      {tools.map((tool, index) => (



<div key={index} className="col-lg-3  col-sm-61 " onClick={() => handleCardClick(tool.path)} >
          <div className="topcard clearfix" style={{ backgroundColor: colors[index % colors.length] }} >
            <figure> <img src={tool.image} alt="" /> </figure>
            <div className="topcard-info">
              <h4>{tool.cardContent}</h4>
              <p>{tool.cardTitle} </p>
            </div>
          </div>
        </div>
      ))}
    </div>


  );
}

export default SCard;
