import React, { useEffect, useState } from "react";
import { Breadcrumb } from "rsuite";
import { FaEye } from "react-icons/fa";
import { AlertMessage, Button } from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import { Table } from "UI_Components";
import AdvanceSalaryRequest from "Components/ManageFund/AdvanceSalary/AdvanceSalaryRequest";
import apiManager from "networking/baseAPIManager";
import EmployeeAdvanceSalaryView from "./EmployeeAdvanceSalaryView";

const AdvancedSalary = () => {
  const [loading, setLoading] = useState(false);
  const [advance, setAdvanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [showAddLeave, setShowAddLeave] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const moment = require("moment");

  const dateHtml = (date) => moment(date).format("DD MMM YYYY");

  const handleItemClick = (data) => {
    setModalContent(data);
    setOpenModal(true);
  };

  const customHtml = (data) => (
    <Button
      onClick={() => handleItemClick(data)}
      label={
        <>
          <FaEye style={{ marginRight: "5px" }} /> View
        </>
      }
    />
  );

  const columns = [
    { header: "Salary Type", field: "advance_salary_type" },
    { header: "Amount", field: "amount" },
    { header: "Instalment", field: "instalment" },
    { header: "EMI Amount", field: "emi_amount" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status.toLowerCase() === "approved"
            ? "green"
            : data.status.toLowerCase() === "rejected"
            ? "red"
            : "blue";
        return <span style={{ color: statusColor }}>{data.status}</span>;
      },
    },
    { header: "Actions", field: "actions", customHtml },
  ];

  useEffect(() => {
    fetchAdvanceSalaryData();
  }, []);

  const fetchAdvanceSalaryData = () => {
    setLoading(true);
    apiManager
      .EmployeeAdvance()
      .then((response) => {
        setLoading(false);
        const listData = response.data.List || [];
        setAdvanceData(listData);
        setFilteredData(listData);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);

    // Filter data based on selected status
    if (value) {
      const filtered = advance.filter(
        (item) => item.status.toLowerCase() === value.toLowerCase()
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(advance);
    }
  };

  const handleAddLeaveClick = () => {
    setShowAddLeave(true);
  };

  const handleCloseModal = () => {
    setShowAddLeave(false);
  };
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="container-fluid">


          <div className="card-top align-items-center mt-3">
            <div className="card-head-left">
            <div className="head-title-bg">
          <div className="page-title">
            <Breadcrumb separator=">">
              <Breadcrumb.Item href="/employee">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Advanced Salary
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          </div>
              </div>
          
            <div className="card-head-right">
            <Button label="Create Advance Salary" onClick={handleAddLeaveClick} />

              <SelectPickerInput
                sidelabel="Sort by Status"
                defaultselect={currentYear}
                data={[
                  { label: "All", value: "" },
                  { label: "Approved", value: "approved" },
                  { label: "Rejected", value: "rejected" },
                  { label: "Cancelled", value: "cancelled" },
                ]}
                value={statusFilter}
                valueKey="value"
                className="w-100"
                onChange={handleStatusFilter}
              />
            </div>
            </div>

        <div className="card mt-2">
      
          <div className="responsive-table">
            <Table columns={columns} Data={filteredData} />
          </div>
        </div>
      </div>

      {showAddLeave && (
        <AdvanceSalaryRequest openModal={showAddLeave} closeModal={handleCloseModal} />
      )}
      {openModal && (
        <EmployeeAdvanceSalaryView
          Data={modalContent}
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          fetchapi={fetchAdvanceSalaryData}
        />
      )}
    </>
  );
};

export default AdvancedSalary;
