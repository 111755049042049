import React, { useState, useEffect, useCallback } from "react";
import { Button, CommonModal, CoreLoader, Dropdown, Input, Label } from "common/components";
import { getFirstMondayOfMonth } from "utils/dateFunctions";
import apiManager from "networking/baseAPIManager";
import { SubHeader } from "UI_Components";
import { useLocation } from "react-router-dom";
import "../../../../assets/style/Responsive.css";


import useremployee from "../../../../../src/assets/images/useremployee.png";
import LeaveEmployee from "../../../../../src/assets/images/LeaveEmployee.png";
import EmployeeOnboarding from "../../../../../src/assets/images/EmployeeOnboarding.png";
import ProbationEmployee from "../../../../../src/assets/images/ProbationEmployee.png";


import { EmpRawData, MainContentReport, SearchFilter } from "Components/AttendaceReport";
import { WeeklyAttendance } from "Components/AttendaceReport/WeeklyAttendance";
import moment from "moment";
import SreachDropDown from "common/components/SreachDropDown";

function AttendanceReport() {
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [viewMode, setViewMode] = useState("monthly");
  const [titleData, setTitleData] = useState({});
  const [reportData, setReportData] = useState([]);
  const [reportfilter, setReportFilter] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [message, setMessage] = useState("");
  const [showWeeklyMonthlyData, setWeeklyMonthlyData] = useState([]);
  const [filterData, setFilterData] = useState({
    year: new Date().getFullYear(),
    month: moment().subtract(1, 'month').format("MMMM"),
  });
  const [startDate, setStartDate] = useState({});
  const [open, setOpen] = useState(false);
  const [nameOption, setNameOption] = useState({});
  const [nameOptionValue, setNameOptionValue] = useState("");
  const [attendanceOptionValue, setAttendanceOptionValue] = useState("");
  const [optionDate, setOptionDate] = useState("");
  const monthNumber = moment().month(filterData.month).format("M");
  const today = moment().format("YYYY-MM-DD");
  const currentMonth = moment().month(); // Get current month (0-based index)
  const currentYear = moment().year(); // Get current year

  // Min date should be 26th of the previous month
  const minDate = moment()
    .set("year", currentMonth === 0 ? currentYear - 1 : currentYear) // Subtract year only if January
    .set("month", currentMonth === 0 ? 11 : currentMonth - 1) // If January, set to December of previous year
    .set("date", 26)
    .format("YYYY-MM-DD");

  const maxDate = today; // Max date is always today
  useEffect(() => {
    fetchTitleData();
    updateStartDate(filterData);
    if (!open) {
      fetchReportMonthlyData(filterData);
    }
  }, []);

  useEffect(() => {
    updateStartDate(filterData);
    if (viewMode === "monthly") {
      fetchReportMonthlyData(filterData);
    } else {
      fetchReportWeeklyData();
    }
  }, [filterData, viewMode]);

  const updateStartDate = (filterData) => {
    const startDateData = getFirstMondayOfMonth(filterData.year, moment().month(filterData.month).toDate().getMonth());
    setStartDate(startDateData);

  };
  const monthMapping = {
    jan: 1,
    feb: 2,
    mar: 3,
    apr: 4,
    may: 5,
    jun: 6,
    jul: 7,
    aug: 8,
    sep: 9,
    oct: 10,
    nov: 11,
    dec: 12,
  };

  const getMonthNumber = (monthRange) => {
    const [startMonth, endMonth] = monthRange.split("-").map((m) => m.trim().toLowerCase());
    const startMonthNumber = monthMapping[startMonth];
    const endMonthNumber = monthMapping[endMonth];

    // Calculate sequential number
    if (startMonthNumber === 12 && endMonthNumber === 1) {
      return 1; // Special case for dec-jan
    } else {
      return startMonthNumber + 1; // Normal case
    }
  };

  const fetchTitleData = () => {
    setLoading(true);
    apiManager
      .employeecount()
      .then((response) => {
        setLoading(false);
        setTitleData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const fetchReportMonthlyData = () => {
    setLoading(true);
    // Get the month number from filterData.month
    const monthNumber = getMonthNumber(filterData.month);
    apiManager
      .attendanceList({ month: monthNumber || 2, year: filterData.year })
      .then((response) => {
        setLoading(false);

        setReportData(response?.data);
        setReportFilter(response?.data)
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setMessage("No Data Available");
      });
  };

  const fetchReportWeeklyData = () => {
    setLoading(true);
    apiManager
      .WeeklyAttendance()
      .then((response) => {
        setLoading(false);
        setWeeklyMonthlyData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setMessage("No Data Available");
      });
  };

  const updateEmployeeAttendance = () => {
    let payload = {
      empid: nameOptionValue,
      date: optionDate,
      attendance: attendanceOptionValue.toLowerCase(),
    };
    apiManager
      .updateAttendance(payload)
      .then((response) => {
        setOpen(false);
        fetchReportMonthlyData();
        setOptionDate("");
        setAttendanceOptionValue("");
        setNameOptionValue("");
      })
      .catch((error) => {
        console.log(error, "errorData");
      });
  };
  const handleViewModeToggle = () => {
    const newViewMode = viewMode === "monthly" ? "weekly" : "monthly";
    setViewMode(newViewMode);
    if (newViewMode === "weekly") {
      fetchReportWeeklyData();
    } else {
      fetchReportMonthlyData();
    }
  };

  const handleYearChange = (event) => {
    setFilterData({ ...filterData, year: parseInt(event.target.value) });
  };

  const getYearOptions = useCallback(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  }, []);



  const getMonthOptions = useCallback(() => {
    const shortMonths = moment.monthsShort(); // Array of short month names
    return shortMonths.map((month, index) => {
      const currentMonth = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase(); // Capitalize the first letter
      const nextMonth = shortMonths[(index + 1) % shortMonths.length];
      const formattedNextMonth = nextMonth.charAt(0).toUpperCase() + nextMonth.slice(1).toLowerCase(); // Capitalize the first letter of next month

      return {
        index: index + 1, // Index starts from 1
        value: `${currentMonth}-${formattedNextMonth}`, // Example: "dec-jan"
        label: `${currentMonth}-${formattedNextMonth}`, // Example: "Dec-Jan"
      };
    });
  }, []);


  const handleMonthChange = (event) => {
    setFilterData({ ...filterData, month: event.target.value });
  };



  useEffect(() => {
    if (searchString?.length > 0) {
      const result = reportfilter.filter((rowObj) => {
        return (
          rowObj.employeeID?.toLowerCase().includes(searchString?.toLowerCase()) ||
          (`${rowObj.firstname} ${rowObj.lastname}`)
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          rowObj.firstname?.toLowerCase().includes(searchString?.toLowerCase()) || rowObj.lastname?.toLowerCase().includes(searchString?.toLowerCase())
        );
      });
      setReportData(result);
    } else {
      setReportData(reportfilter);
    }

  }, [searchString, reportfilter]);
 
  useEffect(() => {
    if (reportData?.length > 0) {
      const options = reportData.map((data) => ({
        id: data.empid,
        name: `${data.firstname} ${data.middlename} ${data.lastname}`,
      }));
      setNameOption(options);
    }
  }, [reportData]);
  const attendanceTypeOption = [
    {
      id: "present",
      name: "Present",
    },
    {
      id: 'absent',
      name: "Absent",
    },
    {
      id: 'half_day',
      name: "Half Day",
    },
  ];
  const renderData = () => {
    return [
      { image: useremployee, title: "Total employee", number: `${titleData.totalEmployeeCount || 0}` },
      { image: LeaveEmployee, title: "Present", number: `${titleData.TotalEmployeePresent || 0}` },
      { image: EmployeeOnboarding, title: "Absent", number: `${titleData.TotalEmployeeAbsent || 0}` },
      { image: ProbationEmployee, title: "Leave", number: `${titleData.totalEmployeeLeaveToday || 0}` },
    ].map((item, index) => <EmpRawData image={item.image} title={item.title} number={item.number} key={index} />);
  };

  const resetFilters = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().subtract(1, 'month').format("MMMM");
    setFilterData({
      year: currentYear,
      month: currentMonth,
    });
  };
  useEffect(() => {
    updateStartDate(filterData)
  }, [])

  return (
    <div>
      <div className="container-fluid">
        <div className="AttendanceReporttop">
          <SubHeader location={useLocation()} mode={viewMode === "monthly" ? "monthly" : "weekly"} />
          <Button label="Update Attendance" onClick={() => setOpen(true)} />
        </div>
        <div className="row topcard-row attendancerepoart">
          {renderData()}
        </div>
        <div className="card" style={{maxHeight:"700px"}}>
          <div className="card-top align-items-center">
            <div className="card-head-left"><div className="card-title">Attendance Report</div></div>

            <div className="card-head-right">
              <SearchFilter
                data={searchString}
                setSearchString={setSearchString}
                placeholder={"Search Employee By Name"}
              />
              {viewMode === "monthly" ?
                <>
                  <div className="filter-select-btn">
                    <Dropdown
                      value={filterData.month}
                      onChange={handleMonthChange}
                      options={getMonthOptions()}
                      placeholder="Month"
                    />
                    <Dropdown
                      value={filterData.year}
                      onChange={handleYearChange}
                      options={getYearOptions()}
                      placeholder="Year"
                    />
                    <Button label={"Reset"} onClick={resetFilters} />
                  </div>
               
                </>
                : <></>}
                   <div className="responsm">
                    <Button
                      className="button"
                      label={viewMode === "monthly" ? "Weekly View" : "Monthly View"}
                      onClick={handleViewModeToggle}
                    />
                  </div>
            </div>
          </div>
          <div className="Attendance-table responsive-table">
            {viewMode === "monthly" ? (
              <MainContentReport reportData={reportData}  startDate={startDate} message={message} />
            ) : (
              <WeeklyAttendance reportData={showWeeklyMonthlyData} startDate={startDate} message={message} />
            )}

          </div>



          <div className="text-center mt-4">
            <div className="d-flex justify-content-start gap-3 mt-2">
              <div className="d-flex align-items-center">
                <span className="ms-2  text-success">P - Present</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-2  text-danger">A - Absent</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-2  text-primary">L - Leave</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-2  text-warning">HD - Half Day</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-2 " style={{ color: "#00cc99" }}>H - Holiday Day</span>
              </div>
              {/* Add more legend items here as needed */}
            </div>
          </div>
        </div>

      </div>


      <CommonModal open={open} onClose={() => setOpen(false)} size="sm" title={"Update Attendance"}>
        <div className=" row-lg-12 p-5 ">
          <div className="col-lg-12 ">
            <Label label="Employee Name" labelclasses={"mt-2"}/>
            <SreachDropDown
              value={nameOptionValue}
              options={nameOption}
              onChange={(e) => setNameOptionValue(e.target.value)}
            />
          </div>
          <div className="col-lg-12">
            <Label label="Date" />
            <Input
              type="date"
              onChange={(e) => setOptionDate(e.target.value)}
              value={optionDate}
              max={maxDate}
              min={minDate}
            />
          </div>
          <div className="col-lg-12">
            <Label label="Status" />
            <Dropdown
              type="text"
              options={attendanceTypeOption}
              onChange={(e) => setAttendanceOptionValue(e.target.value)}
              value={attendanceOptionValue}
            />
          </div>
        </div>
        <div className="py-3 d-flex justify-content-end pe-5 gap-2">
          <Button label="Reject" onClick={() => setOpen(false)} />
          <Button label="Update" onClick={() => updateEmployeeAttendance()} />
        </div>
      </CommonModal>
      <CoreLoader show={loading} />
    </div>

  );
}

export default AttendanceReport;
