import React, { useEffect } from "react";
import EmployeeOnboarding from "../Pages/HRtools/EmployeeOnboarding";
import UserDetail from "../Pages/HRtools/EmployeeOnboarding/UserDetail";
import AttendanceTracking from "../Pages/HRtools/AttendanceTracking";
import AttendanceWeekReport from "../Pages/HRtools/AttendanceTracking/AttendanceWeekReport";
import {
  AttendanceMarkUp,
  AttendanceReport,
  HolidayCalendar,
} from "../Pages/HRtools/AttendanceTracking/Attendance";

import PasswordForgotModal from "../Pages/HRtools/AttendanceTracking/PasswordForgotModal";
import LoginPage from "../Pages/Auth/LoginPage";
import { UnauthorizedPage } from "Pages/Auth/LoginTransit";
import Privaterout from "../Pages/Auth/Privaterout";
import Hrtools from "../Pages/HRtools";
import Taskmanager from "../Pages/TaskManager/index";
import LendingDashboard from "../Pages/LendingDashboard";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import LeaveRequest from "Pages/HRtools/AttendanceTracking/LeaveRequest";
import EmployeeProfile from "Pages/HRtools/EmployeeProfile/index";
import AssetManagement from "Pages/HRtools/AssetManagement";
import Inventory from "Pages/HRtools/AssetManagement/Inventory";
import PendingAssetVerification from "Pages/HRtools/AssetManagement/PendingAssetVerification";
import AssetAssignment from "Pages/HRtools/AssetManagement/AssetAssignment";
import AddAssets from "Pages/HRtools/AssetManagement/AddAssets";
import UpdateAssets from "Pages/HRtools/AssetManagement/UpdateAssets";
import AddHolidayform from "Components/AddHolidaypop/AddHolidayform";
import { AddHolidayListCalendar } from "Components/AddHolidaypop/AddHolidayListCalander";
import ManageFund from "Pages/HRtools/ManageFund";
import GenerateDocuments from "Pages/HRtools/GenerateDocuments";
import CreateDocument from "Components/GenerateDocuments/CreateDocument";
import GenerateLetter from "Components/GenerateDocuments/GenerateLetter";
import ManageFundPayroll from "Pages/HRtools/ManageFund/Payroll/ManageFundPayroll";
import AdvanceSalaryManageFund from "Pages/HRtools/ManageFund/Advance Salary/AdvanceSalaryManageFund";
import Resignation from "Pages/HRtools/E-ExitOffboarding/Resignation";
import FullnFinalProcess from "Pages/HRtools/E-ExitOffboarding/FullnFinalProcess";
import EmployeeDashboard from "Pages/HRtools/EmployeeDashboard/EmployeeDashboard";
import EditSalary from "Components/ManageFund/EditSalary";
import EditSalaryForm from "Components/ManageFund/EditSalaryForm";
import NotFound from "Pages/404Page/NotFound";
import isResourceAvailable from "utils/permit_util";
import EmployeeLeaveRequest from "Pages/EmployeeSection/EmployeeLeaveRequest";
import EmployeeHome from "Pages/EmployeeSection/EmployeeHome/EmployeeHome";
import ProfileDrawer from "Components/EmployeeProfile/ProfileDrawer";
import EmployeeCreateResignation from "Pages/EmployeeSection/Employee-E-ExitOffboarding/EmployeeCreateResignation";
import EmployeeAttendance from "Pages/EmployeeSection/AttendanceTracking";
import EmployeeProfiles from "Pages/EmployeeSection/EmployeeProfile/EmployeeProfiles";
import AdvancedSalary from "Pages/EmployeeSection/PayRoll/AdvancedSalary";
import Payroll from "Pages/EmployeeSection/PayRoll/Payroll";
import PayrollForm from "Pages/EmployeeSection/PayRoll/PayrollForm";
import Profile from "Pages/EmployeeSection/Profile";
const rolePermission = isResourceAvailable("role");

// import { Completed, Inprocess } from "Components/HRtools/EmployeeOnboarding";
export const publicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/forgotpassword" element={<LoginPage />} />
      <Route path="/otpverification" element={<LoginPage />} />
      <Route path="/newpassword" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

//private route
export const privateRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route exact element={<Privaterout />}>
        {/* <Route path="/home" element={<LendingDashboard />} /> */}
        <Route path="/taskManager" element={<Taskmanager />} />
        <Route path="/hrtools" element={<Hrtools />} />
        <Route path="/employee/profile" element={<ProfileDrawer />} />
        <Route path="/employee/profiles" element={<EmployeeProfiles />} />


        <Route path="/employee" element={<EmployeeDashboard />} />
        <Route path="/employee/dashboard" element={<EmployeeHome />} />
        <Route path="/profile" element={<Profile />} />
          
        <Route
          path="/hrtools/attendance-dashboard"
          element={<AttendanceTracking />}
        />
          <Route
          path="/hrtools/employee/attendance-dashboard"
          element={<EmployeeAttendance />}
        />
        {/* <Route path="/attendance-mark" element={<AttendanceMark />} /> */}
        <Route
          path="/attendance-week-report"
          element={<AttendanceWeekReport />}
        />
        {/* <Route path="/attendance-month-report" element={<AttendanceMonthReport />} /> */}
        <Route
          path="/attendance-password-forgot"
          element={<PasswordForgotModal />}
        />
        <Route
          path="/hrtools/employee-onboarding"
          element={<EmployeeOnboarding />}
        />
        <Route
          path="/hrtools/employee-onboarding/:activePage"
          element={<UserDetail />}
        />
        <Route
          path="/hrtools/employee-onboarding/:activePage/:empid"
          element={<UserDetail />}
        />
        <Route path="/hrtools/employee-profile" element={<EmployeeProfile />} />
        <Route
          path="/hrtools/attendance/attendance-report"
          element={<AttendanceReport />}
          
        />
        <Route
          path="/hrtools/attendance/attendance-markup"
          element={<AttendanceMarkUp />}
        />
        <Route
          path="/hrtools/attendance/holiday-calendar"
          element={<HolidayCalendar />}
        />
        <Route
          path="/hrtools/attendance/holidayform"
          element={<AddHolidayform />}
        />
        <Route
          path="/hrtools/attendance/holiday-list-calendar/:id"
          element={<AddHolidayListCalendar />}
        />
        <Route
          path="/hrtools/attendance-tracking/leave-request"
          element={<LeaveRequest />}
        />
        <Route
          path="/employee/attendance-tracking/leave-request"
          element={<EmployeeLeaveRequest />}
        />
        <Route
          path="/hrtools/assets-management"
          element={<AssetManagement />}
        />
        <Route
          path="/hrtools/assets-management/inventory"
          element={<Inventory />}
        />
        <Route
          path="/hrtools/assets-management/pending-asset-verification"
          element={<PendingAssetVerification />}
        />
        <Route
          path="/hrtools/assets-management/asset-assignment"
          element={<AssetAssignment />}
        />
        <Route
          path="/hrtools/assets-management/add-asset"
          element={<AddAssets />}
        />
        <Route
          path="/hrtools/assets-management/update-asset/:id"
          element={<UpdateAssets />}
        />
        <Route path="/hrtools/manage-fund" element={<ManageFund />} />
        <Route path="/Employee/payroll" element={<Payroll/>} />
        <Route path="/Employee/advancedsalary" element={<AdvancedSalary/>} />


        <Route
          path="/hrtools/manage-fund/payroll"
          element={<ManageFundPayroll />}
        />
        <Route
          path="/hrtools/manage-fund/edit-salary"
          element={<EditSalary />}
        />
        <Route
          path="/hrtools/manage-fund/edit-salary/form"
          element={<EditSalaryForm />}
        />
        <Route
          path="/hrtools/manage-fund/advance-salary"
          element={<AdvanceSalaryManageFund />}
        />
        <Route
          path="/hrtools/generate-documents"
          element={<GenerateDocuments />}
        />
        <Route
          path="/hrtools/generate-documents/employee-details"
          element={<CreateDocument />}
        />
        <Route
          path="/hrtools/generate-documents/employee-letter"
          element={<GenerateLetter />}
        />
        <Route path="/hrtools/e-exit/Offboarding" element={<Resignation />} />
        <Route path="/hrtools/EmployeeResignation/e-exit/Offboarding" element={<EmployeeCreateResignation/>} />

        {/* <Route path="/hrtools/e-exit/f&f-process" element={<FullnFinalProcess />} /> */}
      </Route>
    </Routes>
  );
};
function AppRoutes() {
  let isLoggedIn = useSelector((state) => state?.data?.token);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Routes>
      {/* {publicRoutes()}
      {privateRoutes()} */}
    </Routes>
  );
}

export default AppRoutes;
