import React, { useId } from "react";
import Select from "react-select"; // Import react-select
import styles from "./css/input.module.css";
import Label from "./Label";
function SreachDropDown(props) {
  let {
    label,
    parentclasses,
    id,
    name,
    value,
    valueKey,
    onChange,
    options,
    touched,
    error,
    placeholder,
    selectclasses,
    onFocus,
  } = props;
  let uniqueId = useId();
  const placeholderText = placeholder || "Select";
  // Format options for react-select
  const formattedOptions = options?.map((option) => ({
    value: valueKey ? option[valueKey] : option?.value || option.id,
    label: option.value || option.name || option,
  }));
  return (
    <>
      <label htmlFor={id ? id : uniqueId}>{label}</label>
      <Select
        id={id ? id : uniqueId}
        name={name}
        value={formattedOptions.find((opt) => opt.value === value)} // match value
        onChange={(selectedOption) => onChange({ target: { name, value: selectedOption.value } })}
        options={formattedOptions}
        placeholder={placeholderText}
        className={"select form-control"}
        onFocus={onFocus}
        isSearchable
        styles={{
          control: (base) => ({
            ...base,
            border: "none", // Remove the border
            boxShadow: "none", // Remove any shadow (optional)
          }),
        }}
      />
      {touched && error && <span className={styles.error}>{error}</span>}
    </>
  );
}
export default SreachDropDown;











