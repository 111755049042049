import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "common/components/ConfirmationModal";

function  LeaveView({ Data, showButton, fetchapi, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancele_id, setCancel_id] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dateHtml = (data, field) => {
    let options = { day: "2-digit", month: "2-digit", year: "numeric", weekday: "short" };
    let formattedDate = new Date(data[field]).toLocaleDateString("en-GB", options).replaceAll("/", "-");
    return <>{formattedDate}</>;
  };

  const leaveLabelMapping = {
    type: "Leave Type",
    start_date: "Start Date",
    end_date: "End Date",
    session: "Half-day",
    reason: "Reason",
    document: "Attachment",
  };

  const resignationLabelMapping = {
    resignation_type: "Resignation Type",
    notice_date: "Notice Date",
    resignation_date: "Resignation Date",
    reason: "Reason",
  };

  const labelMapping = Data.resignation_type ? resignationLabelMapping : leaveLabelMapping;

  const openWithdrawModal = () => {
    setIsOpen(true);
    setCancel_id(Data._id);
  };

  const cancelstatus = () => {
    let dataPayload = {
      leaveid: cancele_id,
      status: "cancelled",
    };
    setLoading(true);

    if (Data.status) {
      let dataPayload = {
        leaveid: cancele_id,
        status: "cancelled",
      };
      apiManager
        .leaveStatus(dataPayload)
        .then((res) => {
          setLoading(false);
          fetchapi && fetchapi();
          setmsgType("success");
          setShowMSG(res?.data?.message || "Details updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.response?.data?.message || "Failed to update");
        });
    }

    if (Data.offboarding_status) {
      let dataPayload = {
        id: cancele_id,
        status: "cancelled",
      };
      apiManager
        .OffboardingStatus(dataPayload)
        .then((res) => {
          setLoading(false);
          fetchapi && fetchapi();
          setmsgType("success");
          setShowMSG(res?.data?.message || "Details updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.data?.message || "Failed to update");
        });
    }
  };

  useEffect(() => {
    if (confirm) {
      cancelstatus();
      setConfirm(false);
    }
  }, [confirm]);

  const ApprovedOrRejected =
    Data && Data.status
      ? ["approved"].includes(Data.status.toLowerCase())
      : ["approved"].includes(Data.offboarding_status.toLowerCase());

  const arr = Object.entries(Data)
    .filter(([originalLabel]) => labelMapping[originalLabel])
    .map(([originalLabel, value]) => ({
      label: labelMapping[originalLabel],
      value: originalLabel.includes("date") ? dateHtml(Data, originalLabel) : value,
    }));

  const downloadAttachment = async () => {
    let BASE_URL = process.env.REACT_APP_BASE_URL;
    const attachmentData = `${BASE_URL}/${Data?.document}`;

    if (!attachmentData) {
      console.error("No attachment data found.");
      return;
    }

    try {
      const response = await fetch(attachmentData);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", Data?.document);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching or triggering download:", error);
    }
  };

  return (
    <>
      <div className="p-3">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

        {arr &&
          arr.map((content, index) => (
            <div className="row mb-2" key={index}>
              <div className="col-md-6"><b className="fw-600">{content.label}</b></div>
              <div className="col-md-6">
                {content.label === "Attachment" ? (
                  Data.document && <Button label="Download" onClick={downloadAttachment} />
                ) : (
                  <div className="text-content">{content.value}</div>
                )}
              </div>
            </div>
          ))}

        {ApprovedOrRejected && (
          <div className="row">

            <div className="col-12 p-3 pb-0 text-end">
              {showButton && (
                <>
                  <Button label="Withdraw" onClick={() => openWithdrawModal()} />
                </>
              )}
            </div>

            {isOpen && (
              <CommonModal open={isOpen} onClose={() => setIsOpen(false)} title="Confirmation">
                <ConfirmationModal setOpen={setIsOpen} setConfirm={setConfirm} />
              </CommonModal>
            )}
            <CoreLoader show={loading} disableLines />
          </div>
        )}
      </div>

      {/* Additional CSS for long text handling */}
      <style jsx>{`
        .text-content {
          white-space: pre-wrap;
          word-break: break-word;
        }
      `}</style>
    </>
  );
}

export default LeaveView;
