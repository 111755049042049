import React, { useEffect, useState } from "react";
import "../../../../assets/style/candidate-preview.css";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AlertMessage, Button, CommonModal, CoreLoader, DownloadIcon, Dropdown, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import "../../../../assets/style/Responsive.css";
import userDefaultImage from "../../../../assets/images/UserDefultImage.png";
import isResourceAvailable from "utils/permit_util";
import { convertDateFormat, convertDateFormatDash, currentDate } from "utils/dateFunctions";
import { LuPencil } from "react-icons/lu";
import { IoDownloadOutline } from "react-icons/io5";

let BASE_URL = process.env.REACT_APP_BASE_URL;

const CandidatePreview = () => {
  const [userData, setUserData] = useState({});
  let bankDetails = userData?.bankdetails || {};
  let ctcDetails = userData?.ctcDetails || {};

  let params = useParams();
  let empid = params?.empid;
  const showApprovRejectButton = isResourceAvailable("role");
  console.log(userData, 'userData');
  useEffect(() => {
    if (empid) {
      getAllDataCandidate();
    }
  }, [empid]);

  const getAllDataCandidate = () => {
    apiManager
      .candidateAllData(empid)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err, "api Error allData");
      });
  };

  const navigate = useNavigate();
  let documents = userData?.documents || [{}];
  documents = documents[0];

  let status = userData?.status;

  const [open, setOpen] = useState();
  const [formData, setFormData] = useState({});
  const [department, setDepartment] = useState([])
  const [roles, setRoles] = useState([]);
  const [getDesignation, setDesignation] = useState([]);
  const [getBankName, setGetBankName] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [selectCity, setSelectCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const designation = formData?.department === "" ? formData.department : formData?.department?.toLowerCase();

  useEffect(() => {
    setLoading(true);
    apiManager
      .country()
      .then((country) => {
        setLoading(false);
        setSelectCountry(country?.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (formData?.country) {
      setLoading(true);

      apiManager
        .state(formData?.country)
        .then((state) => {
          setLoading(false);
          setSelectState(state?.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [formData?.country]);

  useEffect(() => {
    if (formData?.state) {
      setLoading(true);
      apiManager
        .city(formData?.state)
        .then((city) => {
          setLoading(false);
          setSelectCity(city?.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [formData?.state]);
  const handleCheckLocation = (e) => {
    const status = e.target.checked
    if (status) {
      setSelectCountry(["India"]);
      setSelectState(["rajasthan"]);
      setSelectCity(["Jiapur"]);
      setFormData({ ...formData, country: "101", state: "4014", city: "132201", zip: 302021 })
    } else {
      setSelectCountry([""]);
      setSelectState([""]);
      setSelectCity([""]);
      setFormData({ ...formData, country: "", state: "", city: "", zip: '' })
    }
  }
  const handleChange = (event, key, val) => {
    let value = event?.target?.value || val;
    formData[key] = value;
    errors[key] = "";
    setFormData({ ...formData });
  };


  const handleSaveData = () => {
    let allErrors = {};

    if (!formData?.company_email) {
      allErrors.company_email = "Company email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.company_email)) {
      allErrors.company_email = "Invalid company email address";
    } else if (formData.company_email.length > 50) {
      allErrors.company_email = "Company email must be 50 characters or less";
    } else {
      const emailDomain = formData.company_email.split("@")[1];

      if (!emailDomain) {
        allErrors.email = "Invalid email address"; // Invalid if there's no domain part
      } else {
        // Split the domain part by dots
        const domainParts = emailDomain.split(".");

        // Check that there are exactly two domain levels
        if (domainParts.length !== 2) {
          allErrors.email = "Invalid company email domain"; // Invalid if the domain doesn't have exactly two levels
        }
      }
    }

    if (!formData?.department) {
      allErrors.department = "Department is required";
    }
    if (!formData?.role) {
      allErrors.role = "Role is required";
    }
    if (!formData?.designation) {
      allErrors.designation = "Designation is required";
    }

    if (!formData?.country) {
      allErrors.country = "Country is required";
    }

    if (!formData?.state) {
      allErrors.state = "State is required";
    }

    if (!formData?.city) {
      allErrors.city = "City is required";
    }

    if (!formData?.zip) {
      allErrors.zip = "Zip is required";
    } else if (!/^\d{1,6}$/.test(formData.zip)) {
      allErrors.zip = "Zip must be a valid number with up to 6 digits";
    }

    setErrors({ ...allErrors });

    if (Object.keys(allErrors).length) {
      return;
    }


    let payload = {
      empid: empid,
      company_email: formData?.company_email?.toLowerCase(),
      department: formData?.department,
      role: formData?.role,
      designation: formData?.designation,
      country: "101",
      state: formData?.state,
      city: formData?.city,
      zip: formData?.zip,
    };
    setLoading(true);
    apiManager
      .appEmployeeDepartment(payload)
      .then((response) => {
        setLoading(false);
        setOpen(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        setFormData({});
        getAllDataCandidate();
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "failed to update");
        setOpen(false);
        setLoading(false);
      });
  };

  const handleSaveBankDetailsData = () => {
    let errors = {};

    if (formData?.accountNo !== formData?.confirmAccount) {
      errors.confirmAccount = "Account numbers do not match";
    }

    const accountNo = formData?.accountNo;

    if (!accountNo) {
      errors.accountNo = "Account No is Required";
    } else {
      if (!/^\d{10,}$/.test(accountNo)) {
        errors.accountNo = "Account number must be more than 9 digits and contain only numbers";
      }
      if (/[\s\D]|[-+*/=]/.test(accountNo)) {
        errors.accountNo =
          "Account number must not contain spaces, letters, special characters, or mathematical symbols";
      }
    }
    if (!formData?.bankName) {
      errors.bankName = "Bank Name is Required";
    }

    if (!formData?.ifsccode) {
      errors.ifsccode = "IFSC Code is Required";
    } else {
      // Alphanumeric pattern for IFSC Code validation
      const ifscCodePattern = /^[A-Z]{4}0[A-Z0-9a-z]{6}$/;
      if (!ifscCodePattern.test(formData.ifsccode)) {
        errors.ifsccode = "Invalid IFSC Code";
      }
    }

    setErrors({ ...errors });
    if (Object.keys(errors).length) {
      return;
    }

    let payload = {
      empid: empid,
      bank_name: formData.bankName,
      account_no: formData?.accountNo,
      ifsc_code: formData?.ifsccode,
    };

    setLoading(true);
    apiManager
      .appEmployeeAccountDetails(payload)
      .then((response) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        setOpen(false);
        setFormData({});
        getAllDataCandidate();
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "failed to update");
        console.log(err);
      });
  };

  const handleSaveCTCDetails = () => {
    let errors = {};
    if (!formData?.totalctc) {
      errors.totalctc = "Total CTC is required";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    let payload = {
      empid: empid,
      totalctc: formData?.totalctc,
      monthlycompensation: formData?.monthlycompensation,
      // hra: formData?.hra,
      // pf: formData?.pf,
      // insurance: formData?.insurance,
      // tax: formData?.tax,
    };

    setLoading(true);

    apiManager
      .appEmployeeCTCDetails(payload)
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "Details updated successfully");
        setTimeout(() => {
          navigate("/hrtools/employee-onboarding#completed");
        }, 500);

        setFormData({});
        setOpen(false);
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRolesList = () => {
    setLoading(true);

    apiManager
      .getRoleTypes("rolelist")
      .then((response) => {
        setLoading(false);
        setRoles(response?.data?.role);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getDepartmentList = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("department")
      .then((response) => {
        setLoading(false);
        setDepartment(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getDesignationList = () => {
    setLoading(true);

    apiManager
      .getDesignation(designation)
      .then((response) => {
        setLoading(false);
        setDesignation(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getBankNameList = () => {
    setLoading(true);

    apiManager
      .getDropdownTypes("bankname")
      .then((response) => {
        setLoading(false);
        setGetBankName(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (designation) {
      getDesignationList();
    }
    getDepartmentList()
    getBankNameList();
  }, [designation]);
  useEffect(() => {
    getRolesList();
  }, [])
  useEffect(() => {
    setFormData({ ...formData, company_email: userData.company_email })   
  }, [userData])
  const renderAddressForm = () => {
    return (
      <div className="normalBold">
        <h5 className="mb-3">work Location</h5>
        <div className="formgroup-check">
          <Input
            className="form-check-input "
            type="checkbox"
            name="sameAddress"
            // checked={values.sameAddress}
            onChange={(e) => handleCheckLocation(e)}
          // onBlur={handleBlur}
          // error={errors.sameAddress}
          // disabled={isCheckboxDisabled}
          />
          {/* <Label label="" /> */}
          <label className="lables" for="input:r19:">Jaipur, Rajasthan India, 302021</label>

        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <Label label="Country" required />
              <Dropdown
                valueKey="id"
                type="text"
                name={"country"}
                value={formData["country"]}
                options={selectCountry}
                onChange={(e) => {
                  handleChange(e, "country");
                }}
                touched={true}
                error={errors["country"]}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <Label label="State" required />
              <Dropdown
                valueKey="id"
                type="text"
                name={"state"}
                value={formData["state"]}
                options={selectState}
                onChange={(e) => {
                  handleChange(e, "state");
                }}
                touched={true}
                error={errors["state"]}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <Label label="City" required />
              <Dropdown
                valueKey="id"
                type="text"
                name={"city"}
                value={formData["city"]}
                options={selectCity}
                onChange={(e) => {
                  handleChange(e, "city");
                }}
                touched={true}
                error={errors["city"]}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <Label label="Zip Code" required />
              <Input
                type="text"
                name="zip"
                value={formData["zip"]}
                onChange={(e) => {
                  handleChange(e, "zip");
                }}
                touched={true}
                error={errors["zip"]}
                maxlength={6}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderApproveModal = () => {
    return (
      <CommonModal title="Extra Detalis" size="md" open={open} onClose={() => setOpen(false)}>
        <div className="p-4">
          <div className="form-group">
            <Label label="Company Email" required labelclasses="pb-1" />
            <Input
              type="Email"
              value={formData?.company_email?.toLowerCase() || ""}
              parentclasses="pb-2"
              onChange={(e) => handleChange(e, "company_email")}
              placeholder="Company Email"
              touched={true}
              error={errors?.company_email}
              inputClass="w-50"
              maxLength={45}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Department" required labelclasses="pb-1" />
                <Dropdown
                  valueKey="id"
                  type="text"
                  name={"department"}
                  value={formData["department"]}
                  options={department}
                  onChange={(e) => {
                    handleChange(e, "department");
                  }}
                  touched={true}
                  error={errors["department"]}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Label label="Role" required labelclasses="pb-1" />
                <Dropdown
                  valueKey="id"
                  type="text"
                  name={"role"}
                  value={formData["role"]}
                  options={roles}
                  onChange={(e) => {
                    handleChange(e, "role");
                  }}
                  touched={true}
                  error={errors["role"]}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <Label label="Designation" required labelclasses="pb-1" />
            <Dropdown
              valueKey="id"
              type="text"
              name={"designation"}
              value={formData["designation"]}
              options={getDesignation}
              onChange={(e) => {
                handleChange(e, "designation");
              }}
              touched={true}
              error={errors["designation"]}
            />
          </div>
          {renderAddressForm()}
          <div className="pt-3 text-end">
            <Button onClick={handleSaveData} label="Submit" />
          </div>
        </div>
      </CommonModal>
    );
  };

  const bankDetailsModal = () => {
    return (
      <CommonModal title="Bank Account Detail" size="sm" open={open} onClose={() => setOpen(false)}>
        <div className="p-4">
          <div className="form-group">
            <Label label="Bank Name" required />
            <Dropdown
              value={formData?.bankName || ""}
              options={getBankName}
              onChange={(e) => handleChange(e, "bankName")}
              placeholder="Bank Name"
              touched={true}
              error={errors?.bankName}
              inputClass="w-50"
            />
          </div>
          <div className="form-group">
            <Label label="Bank Account No" required />
            <Input
              value={formData?.accountNo || ""}
              onChange={(e) => handleChange(e, "accountNo")}
              label="Bank Account No"
              placeholder="******************"
              maxLength={17}
              minLength={10}
              touched={true}
              error={errors?.accountNo}
              inputClass="w-50"
              sidelabel
            />
          </div>
          <div className="form-group">
            <Label label="Confirm Account No" required />
            <Input
              value={formData?.confirmAccount || ""}
              onChange={(e) => handleChange(e, "confirmAccount")}
              placeholder="******************"
              maxLength={17}
              minLength={11}
              touched={true}
              error={errors?.confirmAccount}
              inputClass="w-50"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              sidelabel
            />
          </div>
          <div className="form-group">
            <Label label="IFSC Code" required />
            <Input
              value={formData?.ifsccode?.toUpperCase() || ""}
              onChange={(e) => handleChange(e, "ifsccode")}
              placeholder="IFSC Code"
              touched={true}
              error={errors?.ifsccode}
              inputClass="w-50"
              maxLength={11}
              sidelabel
            />
          </div>

          <div className="d-flex justify-content-end pt-2">
            <Button onClick={handleSaveBankDetailsData} label="Submit" />
          </div>
        </div>
      </CommonModal>
    );
  };
  const formatNumber = (num) => {
    const str = num.toString().split(".");
    const intPart = str[0];
    const decPart = str.length > 1 ? "." + str[1] : "";
    const lastThree = intPart.substring(intPart.length - 3);
    const otherNumbers = intPart.substring(0, intPart.length - 3);
    const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    return formattedNumber + decPart;
  };

  const ctcDetailsModal = () => {
    return (
      <CommonModal title="Add Salary Detail" size="md" open={open} onClose={() => setOpen(false)}>

        <div className="p-4">
          <div className="form-group">

            <Label label="Total CTC" required />
            <div className="col-12 position-relative">
              <span className="currency-symbol">₹</span>
              <Input
                value={formData?.totalctc || ""}
                className="form-control ps-4"
                onChange={(e) => {
                  let val = e.target.value.replace(/,/g, ""); // Remove existing commas

                  if (val === "") {
                    handleChange({ target: { name: "totalctc", value: "" } }, "totalctc");
                    formData.monthlycompensation = "";
                    setFormData({ ...formData });
                  } else if (!isNaN(Number(val))) {
                    if (Number(val) >= 1000) {
                      handleChange({ target: { name: "totalctc", value: formatNumber(val) } }, "totalctc");
                    } else {
                      handleChange({ target: { name: "totalctc", value: val } }, "totalctc");
                    }

                    let monthly = (Number(val) / 12).toFixed(1);
                    formData.monthlycompensation = Number(val) >= 1000 ? formatNumber(monthly) : monthly;
                    setFormData({ ...formData });
                  }
                }}
                placeholder="Total CTC"
                maxLength={15}
                touched={true}
                error={errors.totalctc}
                inputClass="w-50"
              />
            </div>

          </div>
          <div className="form-group">

            <Label label="Monthly Compensation" />
            <div className="col-12 position-relative">
              <span className="currency-symbol">₹</span>
              <Input
                className="form-control ps-4"
                value={formData.monthlycompensation || ""}
                onChange={(e) => { }}
                placeholder="Monthly Compensation"
                touched={true}
                error={errors?.monthlycompensation}
                inputClass="w-50"
                disabled
              />
            </div>
          </div>
          <div className="form-group">

            <Label label="HRA" />
            <Input
              disabled
              value={formData?.hra || ""}
              onChange={(e) => handleChange(e, "hra")}
              placeholder="HRA"
              touched={true}
              error={errors?.hra}
              inputClass="w-50"
            />
          </div>
          <div className="form-group">
            <Label label="PF" />
            <Input
              disabled
              value={formData?.pf || ""}
              onChange={(e) => handleChange(e, "pf")}
              placeholder="PF"
              touched={true}
              error={errors?.pf}
              inputClass="w-50"
            />
          </div>
          <div className="form-group">
            <Label label="Insurance" />
            <Input
              disabled
              value={formData?.insurance || ""}
              onChange={(e) => handleChange(e, "insurance")}
              placeholder="Insurance"
              touched={true}
              error={errors?.insurance}
              inputClass="w-50"
            />
          </div>
          <div className="form-group">
            <Label label="Tax" />
            <Input
              disabled
              value={formData?.tax || ""}
              onChange={(e) => handleChange(e, "tax")}
              placeholder="Tax"
              touched={true}
              error={errors?.tax}
              inputClass="w-50"
            />
          </div>


          <div className=" d-flex justify-content-end mt-2 ">
            <Button onClick={handleSaveCTCDetails} label="Submit" />
          </div>
        </div>
      </CommonModal>
    );
  };

  const handleApprove = () => {
    if (status === "pending") {
      setOpen("department");
    } else if (!userData?.bankdetails?.bank_name) {
      setOpen("bankdetails");
    } else {
      setOpen("ctcModal");
    }
  };

  const renderModals = () => {
    if (!open) {
      return <></>;
    }
    let modals = {
      department: renderApproveModal,
      bankdetails: bankDetailsModal,
      ctcModal: ctcDetailsModal,
    };

    if (modals[open]) {
      return modals[open]();
    }
    return renderApproveModal();
  };

  let btnTitle = () => {
    if (status === "pending") {
      return "Approve";
    } else if (!userData?.bankdetails) {
      return "Update Bank Details";
    } else {
      return "Add Salary Details";
    }
  };

  let imageToShow = userData?.image ? BASE_URL + "/" + userData?.image : userDefaultImage;

  const downloadAttachment = async (data) => {
    let BASE_URL = process.env.REACT_APP_BASE_URL;
    const attachmentData = `${BASE_URL}/${data}`;

    if (!attachmentData) {
      console.error("No attachment data found.");
      return;
    }

    try {
      const response = await fetch(attachmentData);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching or triggering download:", error);
    }
  };

  return (
    <>
      <div className="container-fluid ">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

        <div className="card mt-3">
          <div className="card-top"><div className="card-title">Candidate Preview</div></div>

          <div className="div2Sections">
            <div className="userImageBlock">
              <img className="userImage" src={imageToShow} alt="userImage" />
            </div>

            <div className="candidatepre-right">
              <ul className="candidatepreviewform">
                <li>
                  <strong>Employee Id</strong>
                  <span className="text-secondary">
                    {userData?.employeeID}
                  </span>
                </li>
                <li>
                  <strong>Name</strong>
                  <span className="text-secondary">
                    {userData?.firstname} {userData?.middlename} {userData?.lastname}
                  </span>
                </li>
                {userData?.email ? (
                  <li>
                    <strong>Company Email </strong>
                    <span className="text-secondary">{userData?.company_email}</span>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <strong>Mobile No</strong>
                  <span className="text-secondary">{userData?.mobile_number}</span>
                </li>
                <li>
                  <strong>Documental DOB</strong>
                  <span className="text-secondary">{currentDate(userData?.documentDob)}</span>
                </li>
                <li>
                  <strong>Original DOB</strong>
                  {userData?.originalDob ? `${currentDate(moment(userData?.originalDob).format("YYYY-MM-DD "))} ${moment(userData?.originalDob).format("hh:mm a")}` : "N/A"}
                </li>
                <li>
                  <strong>Joining Date</strong>
                  <span className="text-secondary">{currentDate(userData?.joining_date)}</span>
                </li>
                <li>
                  <strong>Gender</strong>
                  <span className="text-secondary">{userData?.gender}</span>
                </li>
                <li>
                  <strong>Marital Status</strong>
                  <span className="text-secondary">{userData?.marital_status}</span>
                </li>
                <li>
                  <strong>Company</strong>
                  <span className="text-secondary">{userData?.company_name?.charAt(0).toUpperCase() + userData?.company_name?.slice(1).toLowerCase()}</span>
                </li>
              </ul>
            </div>
          </div>

          {userData?.designation || userData?.department ? (
            <div className="current-Box">
              <div className="editsapse">
                <div className="row py-2">
                  <div className="col-6">
                    <div className="row align-items-center">
                      <h6 className="col-md-6 m-0">Department</h6>
                      <div className="col-md-6 text-secondary">{userData?.department}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row align-items-center">
                      <h6 className="col-md-6 m-0">Designation</h6>
                      <div className="col-md-6 text-secondary">{userData?.designation}</div>
                    </div>
                  </div>
                </div>

                {userData?.designation ?
                  <div className="editbox">
                    <button className="button undefined " onClick={() => {
                      setOpen("department"); setFormData({
                        department: userData?.department || "",
                        designation: userData?.designation || "",
                        role: userData?.role || "",
                        // country: userData?.currentAddress?.countryname || "",
                        // state: userData?.currentAddress?.statename || "",
                        // city: userData?.currentAddress?.cityname || "",
                        company_email: userData?.company_email || ""
                      })
                    }}>
                      <LuPencil />
                    </button>
                  </div>
                  : <></>}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="current-Box">
            <div className="editsapse">

              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Aadhaar Card Number</h6>
                    <div className="col-md-6 text-secondary">{userData?.aadharcard_no}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">PAN Card Number</h6>
                    <div className="col-md-6 text-secondary">{userData?.pancard_no}</div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">PF Number</h6>
                    <div className="col-md-6 text-secondary">{userData?.pf_number || "NA"}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">UAN Number</h6>
                    <div className="col-md-6 text-secondary">{userData?.uan_number || "NA"}</div>
                  </div>
                </div>
              </div>
              {/* <div class="editbox d-none">
                <button class="button undefined ">
                  <LuPencil />
                </button></div> */}
            </div>
          </div>

          {/* family */}
          <div className="current-Box">
            <div className="editsapse">
              <h6>Family Details</h6>
              <div className="row py-2">
                <hr class="mx-0" />
                <div className="col-6 mt-1">

                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Name</h6>
                    <div className="col-md-6 text-secondary">{userData?.family_member_first_name} {userData?.family_member_last_name && userData?.family_member_last_name}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Relationships</h6>
                    <div className="col-md-6 text-secondary">{userData?.relationship || "N/A"}</div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Email</h6>
                    <div className="col-md-6 text-secondary">{userData?.family_member_email || "NA"}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Mobile No.</h6>
                    <div className="col-md-6 text-secondary">{userData?.family_member_phone || "NA"}</div>
                  </div>
                </div>

              </div>
              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">DOB</h6>
                    <div className="col-md-6 text-secondary">{currentDate(userData?.family_member_dob) || "NA"}</div>
                  </div>
                </div>
              </div>
              {/* <div class="editbox d-none">
                <button class="button undefined ">
                  <LuPencil />
                </button></div> */}
            </div>
          </div>
          <div className="current-Box">
            <div className="editsapse">
              <div className="row py-2">
                <div className="col-md-3">
                  <h6 className="m-0">Current Address</h6>
                </div>
                <div className="col-md-9 text-secondary">
                  <p className="m-0" style={{ wordBreak: "break-word" }}>
                    {userData?.currentAddress
                      ? [
                        userData?.currentAddress?.line1,
                        userData?.currentAddress?.line2,
                        userData?.currentAddress?.line3,
                      ]
                        .filter(Boolean)
                        .join(", ")
                      : "NA"}
                  </p>
                </div>
              </div>
              <hr className="mx-0 my-2" />

              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">State</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.currentAddress?.statename || "NA"}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">Zip</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.currentAddress?.zip || "NA"}</div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">City</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.currentAddress?.cityname || "NA"}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">Country</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.currentAddress?.countryname || "NA"}</div>
                  </div>
                </div>
              </div>




              {/* <div className="editbox">
                <button className="button undefined ">
                  <LuPencil//>
                </button>
                </div> */}
            </div>
          </div>
          <div className="current-Box">
            <div className="editsapse">
              <div className="row py-2">
                <div className="col-md-3">
                  <h6 className="m-0">Permanent Address</h6>
                </div>
                <div className="col-md-9 text-secondary">
                  <p className="m-0" style={{ wordBreak: "break-word" }}>
                    {userData?.ParmanentAddress
                      ? [
                        userData?.ParmanentAddress?.line1,
                        userData?.ParmanentAddress?.line2,
                        userData?.ParmanentAddress?.line3,
                      ]
                        .filter(Boolean) // Filters out undefined or empty lines
                        .join(", ")
                      : "NA"}
                  </p>
                </div>
              </div>

              <hr className="mx-0 my-2" />

              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">State</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.ParmanentAddress?.statename || "NA"}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">Zip</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.ParmanentAddress?.zip || "NA"}</div>
                  </div>
                </div>

              </div>


              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">City</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.ParmanentAddress?.cityname || "NA"}</div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <h6 className="m-0">Country</h6>
                    </div>
                    <div className="col-md-6 text-secondary">{userData?.ParmanentAddress?.countryname || "NA"}</div>
                  </div>
                </div>
              </div>



              {/* <div className="editbox">
                <button className="button undefined ">
                  <LuPencil />
                </button>
                </div> */}
            </div>
          </div>
          <div className="current-Box">
            <div className="editsapse">
              <div className="row py-2">
                <h6 className="col-md-12 m-0">Education Detail</h6>
              </div>
              <hr className="mx-0 my-2" />
              <div className="row py-2">
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Secondary Passing</h6>
                    <div className="col-md-6">
                      <div className="downloadAttachment-btn">

                        {documents?.secondary_passing ? `${documents?.secondary_passing} %` : "NA"}

                        {documents?.secondary_doc && (
                          <button
                            onClick={() => downloadAttachment(documents?.secondary_doc)} className="button" >
                            <IoDownloadOutline />
                          </button>

                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Senior Secondary Passing</h6>
                    <div className="col-md-6">
                      <div className="downloadAttachment-btn">
                        {documents?.senior_passing ? `${documents?.senior_passing} % ` : "NA"}

                        {documents?.senior_doc && (
                          <button onClick={() => downloadAttachment(documents?.senior_doc)} className="button" type="button">
                            <IoDownloadOutline />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 py-1">
                  <div className="row align-items-center">
                    <h6 className="col-md-6 m-0">Bachelor's Passing</h6>
                    <div className="col-md-6">
                      <div className="downloadAttachment-btn">
                        {documents?.bachelor_passing ? `${documents?.bachelor_passing} %` : "NA"}
                        {documents?.bachelor_doc && (
                          <button
                            onClick={() => downloadAttachment(documents?.bachelor_doc)}
                            type="button"
                            className="button"
                          >
                            <IoDownloadOutline />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {documents?.extra &&
                  documents?.extra.map((extraDetail, index) => {
                    return (
                      <>
                        <div className="col-6 py-1">
                          <div className="row align-items-center">

                            <h6 className="col-md-6 m-0">{extraDetail?.degreeField}</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                {extraDetail?.degreepercent ? `${extraDetail?.degreepercent} %` : "NA"}
                                <button
                                  onClick={() => downloadAttachment(extraDetail?.degreefile)}
                                  label="Download"
                                  className="button"
                                >
                                  <IoDownloadOutline />
                                </button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </>
                    );
                  })}


              </div>


              {/* <div className="editbox">
                <button className="button undefined ">
                  <LuPencil />
                </button>
              </div> */}
            </div>
          </div>



          {documents?.experienceData || documents?.companyname ? (


            <div className="current-Box">
              <div className="editsapse">
                <div className="row py-2">
                  <h6 className="col-md-12 m-0">Experience Detail</h6>
                </div>
                <div className="row py-2">
                  <h6 className="col-md-3 m-0">Company Name</h6>
                  <div className="col-md-9 text-secondary">{documents?.experienceData?.[0]?.companyname}</div>
                </div>

                <hr className="mx-0 my-2" />

                <div className="row">
                  {documents?.experienceData?.[0]?.start_date && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">Start Date</h6>
                        <div className="col-md-6 text-secondary">
                          {moment(documents?.experienceData?.start_date || documents?.start_date).format("LL")}
                        </div>
                      </div>
                    </div>
                  )}

                  {documents?.experienceData?.[0]?.end_date && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">End Date</h6>
                        <div className="col-md-6 text-secondary">
                          {moment(documents?.experienceData?.end_date || documents?.end_date).format("LL")}
                        </div>
                      </div>
                    </div>
                  )}
                  {documents?.experienceData?.[0]?.experienceletter && documents?.experienceData?.[0]?.experienceletter !== "undefined" && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">Experience Letter</h6>
                        <div className="col-md-6">
                          <div className="downloadAttachment-btn">
                            <button
                              onClick={() => downloadAttachment(documents?.experienceData?.[0]?.experienceletter)}
                              className="button"
                              type="button"
                            >
                              <IoDownloadOutline />
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>
                  )}

                  {documents?.experienceData?.[0]?.offerletter && documents?.experienceData?.[0]?.offerletter !== "undefined" && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">Offer Letter</h6>
                        <div className="col-md-6">
                          <div className="downloadAttachment-btn">
                            <button
                              onClick={() => downloadAttachment(documents?.experienceData?.[0]?.offerletter)}
                              className="button"
                              type="button"
                            >
                              <IoDownloadOutline />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}


                  {documents?.experienceData?.[0]?.payslip && documents?.experienceData?.[0]?.payslip !== "undefined" && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">Pay slip</h6>
                        <div className="col-md-6">
                          <div className="downloadAttachment-btn">
                            <button
                              onClick={() => downloadAttachment(documents?.experienceData?.[0].payslip)}
                              className="button"
                              type="button"
                            >
                              <IoDownloadOutline />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {documents?.experienceData?.[0]?.relievingletter && documents?.experienceData?.[0]?.relievingletter !== "undefined" && (
                    <div className="col-6 py-1">
                      <div className="row align-items-center">
                        <h6 className="col-md-6 m-0">Relieving Letter</h6>
                        <div className="col-md-6">
                          <div className="downloadAttachment-btn">
                            <button
                              onClick={() => downloadAttachment(documents?.experienceData?.[0]?.relievingletter)}
                              className="button"
                              type="button"
                            >
                              <IoDownloadOutline />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </div>

                {/* <div className="editbox">
                <button className="button undefined ">
                  <LuPencil />
                </button>
              </div> */}

              </div>
            </div>
          ) : (
            ""
          )}
          {documents?.extraExperienceData &&
            documents?.extraExperienceData.map((extraExperienceData, index) => {
              return (
                <>
                  <div className="current-Box">
                    <div className="editsapse">
                      <div className="row py-2">
                        <h6 className="col-md-12 m-0">Others Experience Detail</h6>
                      </div>
                      <div className="row py-2">
                        <h6 className="col-md-3 m-0">Company Name</h6>
                        <div className="col-md-9 text-secondary">{extraExperienceData.companyname}</div>
                      </div>

                      <hr className="mx-0 my-2" />

                      <div className="row">
                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Start Date</h6>
                            <div className="col-md-6 text-secondary">{moment(extraExperienceData?.start_date).format("LL")}</div>
                          </div>
                        </div>


                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">End Date</h6>
                            <div className="col-md-6 text-secondary">{moment(extraExperienceData?.end_date).format("LL")}</div>
                          </div>
                        </div>


                        {/* <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Compensation</h6>
                            <div className="col-md-6">
                            <div className="downloadAttachment-btn">
                              <button
                                onClick={() => downloadAttachment(extraExperienceData?.compensation)}
                                className="button"
                                type="button"
                              >
                                <IoDownloadOutline />
                              </button>

                              </div>

                            </div>

                          </div>
                        </div> */}

                        {extraExperienceData?.experienceletter && extraExperienceData?.experienceletter !== "undefined" ? <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Experience Letter</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                <button
                                  onClick={() => downloadAttachment(extraExperienceData?.experienceletter)}
                                  className="button"
                                  type="button"
                                >
                                  <IoDownloadOutline />
                                </button>

                              </div>

                            </div>
                          </div>
                        </div> : <></>}


                        {extraExperienceData?.offerletter && extraExperienceData?.offerletter !== "undefined" ? <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Offer Letter</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                <button
                                  onClick={() => downloadAttachment(extraExperienceData?.offerletter)}
                                  className="button"
                                  type="button"
                                >
                                  <IoDownloadOutline />
                                </button>

                              </div>

                            </div>
                          </div>

                        </div> : <></>}




                        {extraExperienceData?.payslip && extraExperienceData?.payslip !== "undefined" ? <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Payslip</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                <button
                                  onClick={() => downloadAttachment(extraExperienceData?.payslip)}
                                  className="button"
                                  type="button"
                                >
                                  <IoDownloadOutline />
                                </button>

                              </div>

                            </div>
                          </div>
                        </div> : <></>}


                        {extraExperienceData?.relievingletter && extraExperienceData?.relievingletter !== "undefined" ? <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Relieving Letter</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                <button
                                  onClick={() => downloadAttachment(extraExperienceData?.relievingletter)}
                                  className="button"
                                  type="button"
                                >
                                  <IoDownloadOutline />
                                </button>


                              </div>

                            </div>
                          </div>
                        </div> : <></>}


                        {extraExperienceData?.resignationletter && extraExperienceData?.resignationletter !== "undefined" ? <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Resignation Letter</h6>
                            <div className="col-md-6">
                              <div className="downloadAttachment-btn">
                                <button
                                  onClick={() => downloadAttachment(extraExperienceData?.resignationletter)}
                                  className="button"
                                  type="button"
                                >
                                  <IoDownloadOutline />
                                </button>

                              </div>

                            </div>
                          </div>
                        </div> : <></>}

                      </div>
                      {/* <div className="editbox">
                <button className="button undefined ">
                  <LuPencil />
                </button>
              </div> */}
                    </div>




                  </div>
                </>
              );
            })}

          {bankDetails.bank_name ? (
            <div className="current-Box">
              <div className="editsapse">
                <div className="row py-2">
                  <div className="col-6">
                    <div className="row align-items-center">
                      <h6 className="col-md-6 m-0">Bank Name </h6>
                      <div className="col-md-6 text-secondary">{bankDetails.bank_name}</div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row align-items-center">
                      <h6 className="col-md-6 m-0">IFSC Code</h6>
                      <div className="col-md-6 text-secondary">{bankDetails.ifsc_code}</div>
                    </div>
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col-6">
                    <div className="row align-items-center">
                      <h6 className="col-md-6 m-0">Bank Account No. </h6>
                      <div className="col-md-6 text-secondary">{bankDetails.account_no}</div>
                    </div>
                  </div>

                </div>
                {bankDetails?.account_no ?
                  <div className="editbox">
                    <button className="button undefined " onClick={() => {
                      setOpen("bankdetails"); setFormData({
                        accountNo: userData.bankdetails.account_no,
                        bankName: userData.bankdetails.bank_name,
                        ifsccode: userData.bankdetails.ifsc_code
                      })
                    }}>
                      <LuPencil />
                    </button>

                  </div> : <></>}
              </div>



            </div>
          ) : (
            ""
          )}
          {ctcDetails.totalctc ? (
            <div className="current-Box">


              <div className="editsapse">
                <div className="row py-2">
                  <h6 className="col-md-6 m-0">CTC </h6>
                  <div className="col-md-6 text-secondary">
                    <div>&#8377; {ctcDetails.totalctc}/-</div>
                  </div>

                </div>
                {ctcDetails?.totalctc ?
                  <div className="editbox">
                    <button className="button undefined " onClick={() => { setOpen("ctcModal"); setFormData(userData.ctcDetails) }}>
                      <LuPencil />
                    </button>

                  </div> : <></>}
              </div>
            </div>
          ) : (
            ""
          )}

          {status !== "completed" ? (
            <div className="d-flex gap-3 justify-content-end py-2 ">

              {status === "pending" && documents?.experienceData?.[0]?.companyname ? (
                <Button
                  classes=""
                  onClick={() => {
                    navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`);
                  }}
                  type="button"
                  label="Back"
                />
              ) : (
                <Button
                  classes=""
                  onClick={() => {
                    navigate(`/hrtools/employee-onboarding/educationdetails/${empid}`);
                  }}
                  type="button"
                  label="Back"
                />
              )}
              {(showApprovRejectButton === "ADMIN" || showApprovRejectButton === "RECRUITMENT") &&
                status !== "completed" ? (
                <Button
                  classes="buttonApprove"
                  onClick={handleApprove}
                  variant="primary"
                  type="button"
                  label={btnTitle()}
                />
              ) : (
                ""
              )}

              {/* {showApprovRejectButton === "ADMIN" || showApprovRejectButton === "RECRUITMENT" ? (
                <Button classes="" type="button" label="Reject" />
              ) : (
                ""
              )} */}
            </div>
          ) : (
            ""
          )}

        </div>
        {renderModals()}
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
};

export default CandidatePreview;
