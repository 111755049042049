import React from "react";

function Button(props) {
  const {  label, sizeclass, classes } = props;
  return (
    <button className={`button ${sizeclass} ${classes}`} {...props}>
      {label || "LOGIN"}
    </button>
  );
}

export default Button;
