import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { EducationFormSchema } from "../../../../utils/formValidationUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AlertMessage, Button, CommonModal, CoreLoader, DownloadIcon, GroupInput, Input, Label } from "common/components";
import { AddEducationModal } from ".";
import apiManager from "networking/baseAPIManager";
import "../../../../assets/style/Responsive.css";
import { IoCheckbox } from "react-icons/io5";
import ConfirmationModal from "common/components/ConfirmationModal";


const EducationDetail = () => {
  let params = useParams();
  let empid = params?.empid;

  const [modalShow, setModalShow] = useState(false);
  const [extraEducationData, setExtraEducationData] = useState([]);
  // const [extraEducationData, setExtraEducationData] = useState(documents?.extra);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [documents, setDocuments] = useState({});
  const [showNext, setShowNext] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isfreshers, setFreshers] = useState(false)
  const [issave, setIsSave] = useState(false)


  const getcandidateDocuments = () => {
    setLoading(true);

    try {
      apiManager
        .candidateDocuments(empid)
        .then((res) => {
          setLoading(false);
          setDocuments(structuredClone(res?.data?.data));
          setExtraEducationData([...res?.data?.data?.extra]);
          setShowNext(res?.data?.nextPage);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {

    }

  };

  const handleContinue = () => {
    navigate(`/hrtools/employee-onboarding/candidatePreview/${empid}`)
  }
  // navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`)

  useEffect(() => {
    if (confirm) {
      navigate(`/hrtools/employee-onboarding/candidatePreview/${empid}`)
    }
  }, [confirm])
  useEffect(() => {
    if (isfreshers) {
      navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`)
    }
  }, [isfreshers])
  useEffect(() => {
    empid && getcandidateDocuments();
  }, [empid]);

  const forminitialValue = {
    secondaryPer: documents?.secondary_passing || "",
    seniorPer: documents?.senior_passing || "",
    bachelorsPer: documents?.bachelor_passing || "",
    secondaryResult: documents?.secondary_doc || "",
    seniorResult: documents?.senior_doc || "",
    bachelorsResult: documents?.bachelor_doc || "",
    // bachelorsDegree: null,
    // mastersPer: "",
    // mastersResult: null,
    // mastersDegree: null,
  };
  const navigate = useNavigate();
  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: EducationFormSchema,
    onSubmit: (values, action) => {
      handleSave(values);
    },
    enableReinitialize: true,

  });

  const handleSave = (data) => {
    const formData = new FormData();
    formData.append("empid", empid);

    extraEducationData &&
      extraEducationData.map((item, index) => {
        formData.append(item.degreeField, item.degreefile);
      });

    formData.append("secondary_passing", data.secondaryPer);
    formData.append("secondary_doc", data.secondaryResult || "");
    formData.append("senior_passing", data.seniorPer);
    formData.append("senior_doc", data.seniorResult || "");
    formData.append("bachelor_passing", data.bachelorsPer);
    formData.append("bachelor_doc", data.bachelorsResult || "");
    // formData.append("undergraduate_passing", data.secondaryPer);
    // formData.append("undergraduate_doc", data.secondaryPer);
    if (extraEducationData) {
      formData.append("extraData", JSON.stringify(extraEducationData));
    }

    setLoading(true);
    apiManager
      .addEmployeeDocuments(formData)
      .then((response) => {
        setLoading(false);
        setIsSave(true)
        setShowMSG("Details updated");
        setmsgType("success");
        // navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`);
      })
      .catch((error) => {
        setLoading(false);
        setShowMSG("failed to update Details");
        setmsgType("error");
        console.log(error);
      });
  };


  useEffect(() => {
    if (issave) {
      setOpen(true);
    }
  }, [issave])
  const decimalFormat = (value) => {
    const parsedValue = parseFloat(value);

    if (parsedValue > 100) {
      const valueString = parsedValue.toString();
      // Add a decimal point after the first two digits
      return valueString.slice(0, 2) + "." + valueString.slice(2, 4);
    }

    return value;
  };
  const renderFileInput = (name) => {
    if (values[name] && typeof values[name] == "string") {
      const downloadAttachment = async () => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        const attachmentData = `${BASE_URL}/${values[name]}`;

        if (!attachmentData) {
          console.error("No attachment data found.");
          return;
        }

        try {
          const response = await fetch(attachmentData);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values[name]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error("Error fetching or triggering download:", error);
        }
      };

      return (
        <div className="form-control">
          <div className="downloadAttachmentform">
            <Button onClick={() => downloadAttachment()} label="Download" type="button" />
            {documents.status == "pending" || documents.status == "completed" ? (
              <span
                className="material-symbols-outlined pointer button"
                onClick={() => {
                  setFieldValue([name], undefined);
                }}
              >
                edit
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="form-outline">
          {/* <Label label="Upload Result" required />  */}
          <Input

            // parentclasses="col-xl-8 col-lg-8 col-md-5 col-sm-12"
            type="file"
            label="Upload Result"
            name={name}
            required
            onChange={(e) => {
              setFieldValue(name, e.target.files[0]);
            }}
            onBlur={handleBlur}
            isValid={touched[name] && !errors[name]}
            touched={touched[name]}
            error={errors[name]}
          />
        </div>
      </>
    );
  };

  const renderFileInputloop = (index) => {
    let item = extraEducationData[index];
    let image = item.degreefile;
    const downloadAttachment = async () => {
      let BASE_URL = process.env.REACT_APP_BASE_URL;
      const attachmentData = `${BASE_URL}/${image}`;

      if (!attachmentData) {
        console.error("No attachment data found.");
        return;
      }

      try {
        const response = await fetch(attachmentData);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", image);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Error fetching or triggering download:", error);
      }
    };
    if (image) {
      return (
        <div className="form-control">
          <div className="downloadAttachmentform">
            <Button onClick={() => downloadAttachment()} label="Download" type="button" />
            {documents?.status == "pending" || documents?.status == "completed" ? (
              <span
                className="material-symbols-outlined pointer button"
                onClick={() => {
                  extraEducationData[index].degreefile = undefined;
                  setExtraEducationData([...extraEducationData]);
                }}
              >
                edit
              </span>
            ) : (
              ""
            )}
          </div>


        </div>
      );
    }

    return (
      <>
        <Input
          type="file"
          name={extraEducationData[index]}
          required
          onChange={(e) => {
            extraEducationData[index].degreefile = e.target.files[0];
            setExtraEducationData([...extraEducationData]);
          }}
          onBlur={handleBlur}
          isValid={touched[extraEducationData[index]] && !errors[extraEducationData[index]]}
          touched={touched[extraEducationData[index]]}
          error={errors[extraEducationData[index]]}
        />
      </>
    );
  };

  const rendermultiFields = () => {
    if (!extraEducationData || !Array.isArray(extraEducationData)) {
      return;
    }
    return extraEducationData.map((item, index) => {
      return (
        <div key={index} className="card mt-3">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group m-0">
                <Label label={`${item.degreeField}`} />
                <GroupInput

                  label={item.degreeField}
                  placeholder="60"
                  value={item.degreepercent || ""}
                  group="%"
                  disabled
                  onChange={(e) => handleChange(e, index, "degreepercent")}
                  onBlur={() => handleBlur(index, "degreepercent")}
                  isValid={touched[index]?.degreepercent && !errors[index]?.degreepercent}
                  touched={touched[index]?.degreepercent}
                  error={errors[index]?.degreepercent}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label class="lables" required> Documents </label>
                <div className="position-relative pr4">
                  {renderFileInputloop(index)}

                  <span
                    className="material-symbols-outlined pointer closeDocuments"
                    onClick={() => {
                      extraEducationData.splice(index, 1);
                      setExtraEducationData([...extraEducationData]);
                    }}
                  >
                    close
                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="card">
        <div className="Onboarding-title">Candidate Onboarding</div>
        <div className="Onboarding-info-row">
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Candidate Details</div>
          </div>
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Current Address</div>
          </div>
          <div className="Onboarding-check active">
            <IoCheckbox />
            <div className="Onboarding-check-title">Education Details</div>
          </div>
          <div className="Onboarding-check">
            <IoCheckbox />
            <div className="Onboarding-check-title">Experience Details</div>
          </div>
        </div>
      </div>



      <form onSubmit={handleSubmit}>
        <div className="card mt-3">
          <div class="card-top"><div class="card-title">Education Details</div></div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Label label="Secondary Passing" parentclasses="col-xl-2 col-lg-3 col-md-3 col-sm-6  " required />
                <GroupInput
                  parentclasses="downbtn"
                  maxLength={5}
                  type="text"
                  name="secondaryPer"
                  placeholder="Secondary Passing"
                  value={decimalFormat(values.secondaryPer || "")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.secondaryPer && !errors.secondaryPer}
                  touched={touched.secondaryPer}
                  error={errors.secondaryPer}
                  group="%"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
              <Label className="lables" required label= "Documents" />
                {renderFileInput("secondaryResult")}
                <span className="filesize">Maximum file size allowed : 2MB.</span>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Label label="Senior Secondary Passing" required />
                <GroupInput
                  parentclasses="downbtn"
                  maxLength={5}
                  type="text"
                  name="seniorPer"
                  placeholder="Senior Secondary Passing"
                  value={decimalFormat(values.seniorPer || "")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.seniorPer && !errors.seniorPer}
                  touched={touched.seniorPer}
                  error={errors.seniorPer}
                  group="%"
                />

              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <Label className="lables" required label= "Documents" />
                {renderFileInput("seniorResult")}
                <span className="filesize">Maximum file size allowed : 2MB.</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <Label label="Bachelor's Passing" required />
                <GroupInput
                  type="text"
                  maxLength={5}
                  parentclasses="downbtn"
                  name="bachelorsPer"
                  placeholder="Bachelor's Passing"
                  value={decimalFormat(values.bachelorsPer || "")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.bachelorsPer && !errors.bachelorsPer}
                  touched={touched.bachelorsPer}
                  error={errors.bachelorsPer}
                  group="%"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
              <Label className="lables" required label= "Documents" />
                {renderFileInput("bachelorsResult")}
                <span className="filesize">Maximum file size allowed : 2MB.</span>
              </div>
            </div>
          </div>



        </div>
        <div className="mt-1">{rendermultiFields()}</div>
        <div className="d-flex gap-3 justify-content-end mt-4">
          <Button
            type="button"
            className='button'
            label="Add Education"
            onClick={() => {
              setModalShow(!modalShow);
            }}
          />
        </div>

        {modalShow && (
          <AddEducationModal
            show={modalShow}
            setShow={setModalShow}
            data={extraEducationData}
            setData={setExtraEducationData}
            decimalFormat={decimalFormat}
          />
        )}

        <div className=" button-container d-flex gap-3 justify-content-end mt-4">
          <Button
            classes="btn-Continue"
            type="button"
            label="Back"
            onClick={() => navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`)}
          />

          <Button
            type="submit"
            label="Save & Continue"
            classes="responsiver-button"
          // onClick={handleConfirmation}
          />
          {showNext ? (
            <Button
              classes="btn-Continue"
              type="button"
              label="Next"
              onClick={() => setOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      <CommonModal
        open={open}
        onClose={() => { setOpen(false); setIsSave(false) }}
        size="sm"
        title={"Confirmation"}
      >
        <ConfirmationModal
          setConfirm={setConfirm}
          setFreshers={setFreshers}
          setOpen={setOpen}
          cancel="yes"
          Message="Are you fresher ?"
        />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default EducationDetail;
