import React, { useEffect, useState } from "react";
import "../../../../assets/style/attendanceMark.css";
import { RxCrossCircled } from "react-icons/rx";
import { TbCheckbox } from "react-icons/tb";
import axios from "axios";
import { AlertMessage, Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import { useLocation } from "react-router-dom";
import { SubHeader } from "UI_Components";
import { CiSearch } from "react-icons/ci";
import { currentDate } from "utils/dateFunctions";
import { SearchFilter } from "Components/AttendaceReport";


const userInfo = {
  userId: 1,
  name: "John",
  department: "IT",
  project: "Codesk",
};
const AttendanceMarkUp = () => {
  const [employeesList, setEmployeesList] = useState([]);
  const [allEmployeesList, setAllEmployeesList] = useState([]); // Store original data
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [searchString, setSearchString] = useState("");

  const changePayload = (payload) => {
    payload.map((item) => {
      if (!item.present) {
        item.present = true;
      }
    });
    return payload;
  };

  useEffect(() => {
    setLoading(true);
    apiManager
      .activeEmployeeList("?status=active")
      .then((response) => {
        setLoading(false);
        let tmpData = changePayload(response.data);
        setEmployeesList(tmpData);
        setAllEmployeesList(tmpData); // Store the original employee list
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const toggleAttendance = (empId) => {
    let newArrData = [];
    let totalPresentCount = 0;

    [...employeesList].map((employee) => {
      if (employee._id == empId) {
        employee.present = !employee.present;
        employee = employee;
      }
      if (employee.present) {
        totalPresentCount += 1;
      }

      newArrData.push(employee);
    });
    setAbsentCount(newArrData.length - totalPresentCount);
    setPresentCount(totalPresentCount);
    setEmployeesList([...newArrData]);
  };

  const genratePayload = () => {
    let newData = [];
    employeesList.map((item) => {
      let arr = {
        status: item.present,
        date: item.date || new Date(),
        empid: item._id,
      };
      newData.push(arr);
    });

    return newData;
  };

  const prepareDataToSend = () => {
    let attendance = genratePayload();
    const dataToSend = {};
    dataToSend.department = userInfo.department;
    dataToSend.project = userInfo.project;
    dataToSend.submitter = userInfo.userId;
    dataToSend.attendance = attendance;
    sendDataToApi(dataToSend);
  };

  const sendDataToApi = async (payloadData) => {
    setLoading(true);
    apiManager
      .attendanceMark(payloadData)
      .then((res) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
        console.log(err);
      });
  };

  const currentDate = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    return formattedDate
  }
   useEffect(() => {
      if (searchString.length >0) {
        const result = allEmployeesList.filter((rowObj) => {
          return (
            rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
            (`${rowObj.firstname} ${rowObj.lastname}`)
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
            rowObj.firstname.toLowerCase().includes(searchString.toLowerCase()) || rowObj.lastname.toLowerCase().includes(searchString.toLowerCase())
          );
        });
        setEmployeesList(result);
      } else {
        setEmployeesList(allEmployeesList); // Restore original list
      }
    }, [searchString ,allEmployeesList]);
  return (
    <>
      <div className="container-fluid">
        <SubHeader location={useLocation()} />
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div class="card mt-3">
          <div class="card-top">
            <div className="card-head-left">
              <div class="card-title">Today Mark Attendance</div>
              <div class="sub-title">{currentDate()} </div>
            </div>
            <div className="card-head-right">
              <div className="position-relative search-filter-wrapper">
                <CiSearch className="search-icon" />
                <SearchFilter
                  data={searchString}
                  setSearchString={setSearchString}
                  placeholder={"Search Employee By Name"}
                />
              </div>
              {/* <div class="Shortbytbtn">
        <span>Shortby :</span> 
          <select className="form-select" name="">
            <option value="">IT</option>
            <option value="saab">Frontend</option>
            <option value="mercedes">UI & UX</option>
            </select>
          </div> */}
            </div>
          </div>

          <table className="main_table">
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Employee ID</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Mark Attendance</th>
              </tr>
            </thead>
            <tbody>
              {employeesList &&
                employeesList.map((emp, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{emp.employeeID}</td>
                    <td>
                      {emp.firstname} {emp.middlename && emp.middlename + " "} {emp.lastname}
                    </td>
                    <td>
                      {emp.designation} 
                    </td>
                    <td className="px-5 py-1">
                      <span onClick={() => toggleAttendance(emp._id)}>
                        {emp.present ? (
                          <TbCheckbox fontSize="1.5rem" color="green" />
                        ) : (
                          <RxCrossCircled fontSize="1.5rem" color="red" />
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>


        </div>

        <div className="markUptable">
          <div className="d-flex gap-2 fs-6">
            <div className="p-2 Present">
              Present : <small className="fw-bold ">{presentCount ? presentCount : employeesList.length}</small>
            </div>
            <div className="p-2 absent">
              Absent : <small className="fw-bold ">{absentCount}</small>
            </div>
          </div>

          <Button label="Send" className="button" onClick={prepareDataToSend} />
        </div>
        <CoreLoader show={loading} disableLines />
      </div>


    </>
  );
};

export default AttendanceMarkUp;
