import React, { useEffect, useState } from "react";
import { Table } from "UI_Components";
import { useNavigate } from "react-router-dom";
import { Button } from "common/components";
import { Pagination } from "rsuite";
import { setGetLocalData } from "utils/localStorageHelper";
import isResourceAvailable from "utils/permit_util";

function Pending(props) {
  const { data } = props;
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;
  const id = setGetLocalData("id");
  const rolePermission = isResourceAvailable("role");
  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = data.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, data]);

  const customHtml = (data) => {
    return (
      <Button
        onClick={() => handleClick(data)}
        label="Edit Details"
        // disabled={data._id === id || rolePermission === "ADMIN" || rolePermission === "RECRUITMENT" ? false : true}
      />
    );
  };

  const navigate = useNavigate();
  const columns = [
    { header: "S.No", field: "" },
    { header: "ID", field: "employeeID" },
    {
      header: "Name",
      field: "firstname",
      customHtml: (data) => {
        const fullName = `${data.firstname} ${data.middlename ? data.middlename : ""} ${data.lastname}`;
        return <span>{fullName}</span>;
      },
    },
    { header: "Mobile No.", field: "mobile_number" },
    { header: "Company Email", field: "company_email" },
    // { header: "Onboarding Start", field: "onbordingstart" },
    // { header: "Owner ID", field: "ownerDepartment" },
    { header: "Action", field: "actions", customHtml: customHtml },
  ];

  const handleClick = (data) => {
    navigate(`/hrtools/employee-onboarding/candidatedetails/${data._id}`);
  };

  return (
    <>
     <div className="card Tablecard">
     
          {/* <OnboardingTable  filteredData={data} setSelectType={setSelectType} /> */}
          <div className="responsive-table">
          <Table columns={columns} Data={displayedData} onClick={handleClick} />
          </div>
        <div className="d-flex justify-content-end">
          <Pagination
            prev
            last
            next
            first
            size="sm"
            total={data.length}
            limit={itemsPerPage}
            activePage={activePage}
            onChangePage={setActivePage}
          />
        </div>
      </div>
    </>
  );
}

export default Pending;
