import { closeIcon } from "assets/images";
import { Button, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function AddExperienceModal(props) {
  let paramsData = useParams();
  let empid = paramsData.empid;
  const { setShow, data, setData } = props;
  const [fieldValues, setFieldValues] = useState({});
  const [errors, setErrors] = useState({});

  let tmpData = Array.isArray(data) ? structuredClone(data) : [];

  const handleChange = async (e, field, isFile = false) => {
    const value = isFile ? e.target.files[0] : e.target.value;

    if (isFile && value) {
      if (value.type.startsWith("image/")) {
        // Call API for image upload
        uploadImage(field, value);
      } else {
        // Set non-image files as string (e.g., PDF)
        setFieldValues((prev) => ({ ...prev, [field]: value }));
      }
    } else {
      setFieldValues((prev) => ({ ...prev, [field]: value }));
    }
  };

  const uploadImage = async (field, file) => {
    const formData = new FormData();
    formData.append("empid", empid);
    formData.append("image", file);
    try {
      const response = await apiManager.addImage(formData);

      if (response?.data?.data?.image?.image) {

        setFieldValues((prev) => ({ ...prev, [field]: response?.data?.data?.image.image }));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

console.log(fieldValues,'mmmmmmm',tmpData);
  const handleSave = () => {
    let errors = {};
    const today = new Date();
    const companyNameRegex = /^[^\p{Emoji}]+$/u;

    // Company name validation
    if (!fieldValues.companyname) {
      errors.companyname = "This field is required";
    } else if (!companyNameRegex.test(fieldValues.companyname)) {
      errors.companyname = "Company name must not contain emojis";
    }

    // Start date validation
    if (!fieldValues.start_date) {
      errors.start_date = "This field is required";
    } else {
      const startDate = new Date(fieldValues.start_date);
      if (startDate > today) {
        errors.start_date = "Start date cannot be in the future";
      }
    }

    // End date validation
    if (!fieldValues.end_date) {
      errors.end_date = "This field is required";
    } else {
      const startDate = new Date(fieldValues.start_date);
      const endDate = new Date(fieldValues.end_date);
      if (endDate > today) {
        errors.end_date = "End date cannot be in the future";
      } else if (endDate < startDate) {
        errors.end_date = "End date cannot be before start date";
      }
    }

    // File fields validation
    const fileFields = [
      "payslip",
      "offerletter",
      "relievingletter",
      "resignationletter",
      "compensation",
      "experienceletter",
    ];
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    const maxSize = 2 * 1024 * 1024;

    fileFields.forEach((field) => {
      const file = fieldValues[field];

      if (!file) {
        // No file uploaded (optional validation)
        return;
      }

      if (typeof file === "string") {
        // If file is a string (already uploaded), skip validation
        return;
      }

      if (!allowedTypes.includes(file.type)) {
        errors[field] = "File must be a JPG, PNG, or PDF";
      }

      if (file.size > maxSize) {
        errors[field] = "File size must be less than 2 MB";
      }
    });


    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    tmpData.push(fieldValues);

    setData(tmpData);

    // setData(tmpData);
    setShow(false);
  };

 
  const fileFields = [
    "payslip",
    "offerletter",
    "relievingletter",
    // "resignationletter",
    // "compensation",
    "experienceletter",
  ];

  return (
    <div className="d-flex align-items-center justify-content-center mb-4">
      <div className="relative pt-4 bordermodal">
        <div className="closeIcon" onClick={() => setShow(false)}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="px-4 pt-4">
          <Label labelclasses="pb-1" label="Company Name" />
          <Input
            required
            type="text"
            value={fieldValues.companyname || ""}
            maxLength={70}
            onChange={(e) => handleChange(e, "companyname")}
            touched={true}
            error={errors.companyname}
          />
          <div className="twoboxes mt-4">
            <div>
              <Label labelclasses="pb-1" label="Start Date" />
              <Input
                required
                type="date"
                value={fieldValues.start_date || ""}
                onChange={(e) => handleChange(e, "start_date")}
                touched={true}
                error={errors.start_date}
                min="2000-01-01"
                max="2999-12-31"
              />
            </div>
            <div>
              <Label labelclasses="pb-1" label="End Date" />
              <Input
                required
                type="date"
                value={fieldValues.end_date || ""}
                onChange={(e) => handleChange(e, "end_date")}
                touched={true}
                error={errors.end_date}
                min="2000-01-01"
                max="2999-12-31"
              />
            </div>
            {fileFields.map((field, index) => (
              <div key={index}>
                <Label labelclasses="pb-1" label={field.replace(/([A-Z])/g, " $1").trim()} />
                <Input
                  accept="image/*, .pdf"
                  type="file"
                  required
                  onChange={(e) => handleChange(e, field, true)}
                  touched={true}
                  error={errors[field]}
                />
                <p className="" style={{ fontSize: "12px" }}>Maximum file size allowed : 2MB.</p>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end pt-4">
            <Button onClick={handleSave} type="button" label="Save" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddExperienceModal;
