import { decryptData, encryptData } from "./crypto";

export const setGetLocalData = (key, data) => {
  if (data) {
    sessionStorage.setItem(key, encryptData(data)); // Encrypt before storing
  } else {
    const storedData = sessionStorage.getItem(key);
    return storedData ? decryptData(storedData) : null;
  }
};


export const clearLocalData = (key) => {
  if (key) {
    sessionStorage.removeItem(key);
  } else {
    sessionStorage.clear();
  }
};
