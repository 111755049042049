import { SCard, SubHeader, Table } from "UI_Components";
import React, { useEffect, useState } from "react";
import candidates from "../../../assets/images/candidates.png";
import employeeId from "../../../assets/images/employeeId.png";
import joiningDate from "../../../assets/images/joiningDate.png";
import department from "../../../assets/images/department.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, CoreLoader, Dropdown, Input } from "common/components";
import CalendarApp from "common/components/CalendarApp";
import CircularBar from "common/components/CircularBar";
import apiManager from "networking/baseAPIManager";
import { BsArrowRightCircle } from "react-icons/bs";
import "../../../assets/style/Responsive.css";
import "../../../assets/style/employeecard.css";
import "../../../assets/style/MarkAttendance.css";

import moment from "moment";
import { Pagination } from "rsuite";


import ReactApexChart from "react-apexcharts";

export default function EmployeeDashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeSalarylistData, setEmployeeSalarylistData] = useState({});
  const [loading, setLoading] = useState(false);
  const [InformationData, setInformationData] = useState();
  const [CheackInOutData, setCheackInOutData] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState("checkedOut");
  const [timer, setTimer] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [breakTime, setBreakTime] = useState({ hours: 0, minutes: 0 });
  const [overtimeMinutes, setOvertimeMinutes] = useState({
    hours: 0,
    minutes: 0,
  });
  const [remainingTime, setRemainingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [view, setView] = useState("weekly");

  const navigate = useNavigate();

  const formatTime = ({ hours, minutes, seconds }, includeSeconds = true) => {
    if (includeSeconds) {
      return `${hours} Hrs: ${minutes} Min`;
    } else {
      return `${hours} Hrs: ${minutes} Min`;
    }
  };

  const formatISOTime = (isoString) => moment(isoString).format("LT");
  const getDayOfWeek = (isoString) => moment(isoString).format("dddd");
  const formatDate = (date) => moment(date).format("DD MMM YYYY");

  const tools = [
    {
      cardTitle: `${InformationData?.FullName || "N/A"}`,
      cardContent: `${InformationData?.Designation || "N/A"}`,
      image: candidates,
    },
    {
      cardTitle: "Employee ID",
      cardContent: `${InformationData?.EmployeeID || "N/A"}`,
      image: employeeId,
    },
    {
      cardTitle: "Joining Date",
      image: joiningDate,
      cardContent: `${formatDate(InformationData?.JoiningDate) || "N/A"}`,
    },
    {
      cardTitle: "Department",
      cardContent: `${InformationData?.Department || "N/A"}`,
      image: department,
    },
  ];

  const columns = [
    { header: "S.No", field: "sno" },
    {
      header: "Date",
      field: "date",
      customHtml: (data) => formatDate(data.date),
    },
    {
      header: "Day",
      field: "date",
      customHtml: (data) => getDayOfWeek(data.date),
    },
    {
      header: "CheckIn",
      field: "checkInTime",
      customHtml: (data) => formatISOTime(data.checkInTime),
    },
    {
      header: "CheckOut",
      field: "checkOutTime",
      customHtml: (data) =>
        data.checkOutTime ? formatISOTime(data.checkOutTime) : "--",
    },
    {
      header: "Working Hour",
      customHtml: (data) => {
        const { hours, minutes, seconds } = data;
        return `${hours}h : ${minutes}m : ${seconds}s`;
      },
    },
  ];
  const itemsPerPage = 10;
  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData =
      employeeData &&
      employeeData.slice(start, end).map((item, index) => ({
        ...item,
        sno: start + index + 1,
      }));
    setDisplayedData(paginatedData);
  }, [activePage, employeeData]);

  useEffect(() => {
    const savedStatus = localStorage.getItem("checkInStatus");
    const savedTimer = localStorage.getItem("checkInTimer");
    if (savedStatus) setCurrentStatus(savedStatus);
    if (savedTimer) setTimer(JSON.parse(savedTimer));
    PersonalInformationData();
    EmployeeTableData();
    EmployeeSalaryStatuslist();
  }, []);

  useEffect(() => {
    let interval;

    if (currentStatus === "checkedIn") {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const seconds = prevTimer.seconds + 1;
          if (seconds === 60) {
            const minutes = prevTimer.minutes + 1;
            if (minutes === 60) {
              const hours = prevTimer.hours + 1;
              return { hours, minutes: 0, seconds: 0 };
            }
            return { ...prevTimer, minutes, seconds: 0 };
          }
          return { ...prevTimer, seconds };
        });

        localStorage.setItem("checkInTimer", JSON.stringify(timer));
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [currentStatus, timer]);

  const handleCheckInOut = () => {
    setLoading(true);
    const currentTime = new Date().toISOString();
    const newStatus =
      currentStatus === "checkedOut" ? "checkedIn" : "checkedOut";

    const payload = {
      status: newStatus,
      time: currentTime,
    };

    apiManager
      .CheackInCheckOut(payload)
      .then((response) => {
        setLoading(false);
        setCheackInOutData(response.data);
        setCurrentStatus(newStatus);
        localStorage.setItem("checkInStatus", newStatus);
        if (newStatus === "checkedOut") {
          setTimer({ hours: 0, minutes: 0, seconds: 0 });
          localStorage.removeItem("checkInTimer");
        }
        setBreakTime(response.data.breakTime);
        setOvertimeMinutes(response.data.overtime);
        setRemainingTime(response.data.remainingTime);
        EmployeeTableData();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const PersonalInformationData = () => {
    setLoading(true);

    apiManager
      .PersonalInformation()
      .then((response) => {
        setLoading(false);
        setInformationData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const EmployeeTableData = () => {
    setLoading(true);

    apiManager
      .EmployeedataTable()
      .then((response) => {
        setLoading(false);
        const empdata = response.data?.empdata.map((entry, index) => {
          const checkOutTime = entry.checkOutTime ? entry.checkOutTime : "";
          return {
            ...entry,
            sno: index + 1,
            checkOutTime,
          };
        });
        setEmployeeData(empdata);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const EmployeeSalaryStatuslist = () => {
    setLoading(true);

    apiManager
      .EmployeeSalaryStatus()
      .then((response) => {
        setLoading(false);

        setEmployeeSalarylistData(response.data.salaryDetails);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // Dummy data for weekly view
  const weeklyData = {
    series: [
      { name: "Working Hour", data: [3, 4, 2, 5, 6, 3, 7] },
      { name: "Break Time", data: [1, 1, 1, 2, 1, 1, 2] },
      { name: "Remaining Time", data: [6, 5, 7, 4, 3, 6, 2] },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: { show: false }, // Hides the chart toolbar
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        title: { text: "Days of the Week", style: { fontWeight: 600 } },
      },
      yaxis: {
        title: { text: "Hours", style: { fontWeight: 600 } },
      },
      legend: {
        position: "top", // Position legend at the top
        horizontalAlign: "left", // Align to the right
        markers: { radius: 5 }, // Rounded markers
      },
      colors: ["#007bff", "#ffc107", "#28a745"], // Custom colors for each series
      dataLabels: { enabled: false }, // Disable data labels on bars
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%", // Adjust bar width
          borderRadius: 6, // Add rounded corners to bars
        },
      },
      tooltip: {
        theme: "light",
        y: { formatter: (value) => `${value} hours` },
      },
    },
  };

  const monthlyData = {
    series: [
      {
        name: "Working Hour",
        data: [16, 22, 18, 24, 20, 25, 27, 19, 23, 18, 20, 22],
      },
      { name: "Break Time", data: [6, 5, 7, 6, 5, 6, 5, 6, 5, 6, 7, 6] },
      {
        name: "Remaining Time",
        data: [80, 76, 82, 75, 80, 72, 70, 78, 75, 76, 72, 74],
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: { show: false },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        title: { text: "Months", style: { fontWeight: 600 } },
      },
      yaxis: {
        title: { text: "Hours", style: { fontWeight: 600 } },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        markers: { radius: 5 },
      },
      colors: ["#007bff", "#ffc107", "#28a745"],
      dataLabels: { enabled: false },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 6,
        },
      },
      tooltip: {
        theme: "light",
        y: { formatter: (value) => `${value} hours` },
      },
    },
  };

  // Select data based on the view
  const chartData = view === "weekly" ? weeklyData : monthlyData;
  return (
    <>
      <SubHeader location={useLocation()} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <SCard
              tools={tools}
              titleClass="text-light"
              contentClass="text-light"
            />
          </div>
        </div>

        <div className="row p-3">
          <div className="col-lg-6 ">
            <div className="card shadow-sm p-4 ">
              <h5 className="mb-5">Mark Attendance</h5>
              <div className="d-flex justify-content-between align-items-center mb-5 ">

                <div>
                  <h6>Work Time</h6>
                  <h2 className="text-danger fw-bold">{formatTime(timer)}</h2>
                </div>


                <div className="d-flex gap-2 ">
                  <button className="btn btn-outline-warning">
                    Break Time
                  </button>
                  <Button
                    label={
                      currentStatus === "checkedOut" ? "Check-In" : "Check-Out"
                    }
                    onClick={handleCheckInOut}
                    disabled={loading}
                  />
                </div>
              </div>


              <div className="d-flex text-start align-items-center justify-content-between mb-5">

                <div className="text-section ">
                  <h6 className="mb-3">Remaining</h6>
                  <p>{formatTime(remainingTime, false)}</p>
                </div>

                <div className="vertical-line"></div>


                <div className="text-section">
                  <h6 className="mb-3">Break Time</h6>
                  <p>{formatTime(overtimeMinutes, false)}</p>
                </div>


                <div className="vertical-line"></div>

                <div className="text-section">
                  <h6 className="mb-3">Over Time</h6>
                  <p>{formatTime(breakTime, false)}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card shadow-sm p-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5>Working Hours</h5>
                <Button
                  label={
                    view === "weekly" ? " Monthly" : " Weekly"
                  }
                  variant="primary"
                  onClick={() =>
                    setView((prev) =>
                      prev === "weekly" ? "monthly" : "weekly"
                    )
                  }
                >
                  {view === "weekly" ? "Switch to Monthly" : "Switch to Weekly"}
                </Button>
              </div>

              {/* ApexCharts */}
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="bar"
              />
            </div>
            {/* <WorkingHours/> */}
          </div>
        </div>
        <div className="row Dashmiddle-row align-items-stretch">
          {/* Calendar Section */}
          <div className="col-4 d-flex">
            <div className="card flex-fill">
              <CalendarApp />
            </div>
          </div>

          {/* Leave Details Section */}
          <div className="col-4 d-flex">
            <div className="card flex-fill p-4 shadow-sm">
              {/* Header */}
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h5 className="mb-0">Leave Details</h5>
                <button className="btn btn-outline-primary">2024</button>
              </div>
              {/* Leave Stats */}
              <div className="row">
                {/* Left Column */}
                <div className="col-md-6">
                  <div className="leave-section mb-4 text-center">
                    <div className="leave-title mb-4 ">
                      <strong>Total Leave</strong>
                    </div>
                    <div className="leave-count">
                      <span>10</span>
                    </div>
                  </div>
                  <div className="leave-section mb-4 text-center">
                    <div className="leave-title mb-4">
                      <strong>Rejected Leave</strong>
                    </div>
                    <div className="leave-count">
                      <span>2</span>
                    </div>
                  </div>
                </div>
                {/* Right Column */}
                <div className="col-md-6">
                  <div className="leave-section mb-4 text-center">
                    <div className="leave-title mb-4">
                      <strong>Pending Leave</strong>
                    </div>
                    <div className="leave-count">
                      <span>2</span>
                    </div>
                  </div>
                  <div className="leave-section mb-4 text-center">
                    <div className="leave-title mb-4">
                      <strong>Paid Leave</strong>
                    </div>
                    <div className="leave-count ">
                      <span>5</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer Button */}
              <div className="text-end mt-5">
                <button className="btn btn-orange">Apply Leave</button>
              </div>
            </div>
          </div>


          {/* Projects Section */}
          <div className="col-4 d-flex">
            <div className="card flex-fill p-3 shadow-sm">
              <h5 className="mb-3">Projects</h5>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="card project-card p-3">
                    <h6>Office Management</h6>
                    <p>
                      <strong>Leader:</strong> Anthony Lewis
                    </p>
                    <p>
                      <strong>Deadline:</strong> 14 Jan 2024
                    </p>
                    <p>
                      <strong>Tasks:</strong> 6 / 10
                    </p>
                    <p>
                      <strong>Time Spent:</strong> 65 / 120 Hrs
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card project-card p-3">
                    <h6>Office Management</h6>
                    <p>
                      <strong>Leader:</strong> Anthony Lewis
                    </p>
                    <p>
                      <strong>Deadline:</strong> 14 Jan 2024
                    </p>
                    <p>
                      <strong>Tasks:</strong> 6 / 10
                    </p>
                    <p>
                      <strong>Time Spent:</strong> 65 / 120 Hrs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CoreLoader show={loading} />
      </div>
    </>
  );
}
