import React, { useEffect, useState } from "react";
import { SubHeader } from "UI_Components";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import userImage from "../../../assets/images/UserDefultImage.png";
import { IoMailOutline, IoDownloadOutline } from "react-icons/io5";
import { PiBagSimple } from "react-icons/pi";
import apiManager from "networking/baseAPIManager";
import { Button } from "common/components";
import userimage from "../../../assets/images/userImage.jpg";

const EmployeeProfiles = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [candidateData, setCandidateData] = useState({});
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  let params = useParams();
  let empid = params?.empid;

  useEffect(() => {
    fetchPersonalInfo();
  }, []);
  useEffect(() => {
    if (empid) {
      getAllDataCandidate();
    }
  }, [empid]);

  const getAllDataCandidate = () => {
    apiManager
      .candidateAllData(empid)
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => {
        console.log(err, "api Error allData");
      });
  };
  const fetchPersonalInfo = () => {
    setLoader(true);
    apiManager
      .PersonalInformation()
      .then((res) => {
        setCandidateData(res?.data || {});
      })
      .catch((err) => {
        console.error("Error fetching personal information:", err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const downloadAttachment = async (data) => {
    let BASE_URL = process.env.REACT_APP_BASE_URL;
    const attachmentData = `${BASE_URL}/${data}`;

    if (!attachmentData) {
      console.error("No attachment data found.");
      return;
    }

    try {
      const response = await fetch(attachmentData);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching or triggering download:", error);
    }
  };

  console.log(candidateData, "candidateDatakvbn");
  return (
    <div className="container-fluid">
      <SubHeader location={location} />
      {/* <div className="mt-4 mb-4">
        <div className="card-title">Employee Details</div>
      </div> */}

      <div className="row Emp-Det-Row">
        {/* Left Column */}
        <div className="col-sm-4">
          <div className="Emp-Det-left">
            <div className="card">
              <div className="Emp-top-info clearfix">
                <div className="Emp-profile-img">
                  <img
                    src={
                      candidateData?.info?.image
                        ? `${BASE_URL}/${candidateData?.info?.image}`
                        : userImage
                    }
                    alt="Employee"
                  />
                </div>
                <div className="Emp-profile-text">
                  <h5>{candidateData.FullName || "N/A"}</h5>
                  <p>
                    <PiBagSimple /> {candidateData?.info?.designation || "N/A"}
                  </p>
                  <p className="m-0">
                    <IoMailOutline /> {candidateData?.info?.email || "N/A"}
                  </p>
                </div>
              </div>
            </div>

            {/* Personal Details */}
            <div className="card mt-3">
              <div className="card-title">Personal Details</div>
              <div className="Emp-Det-List">
                <ul>
                  <li>
                    <span>Documental DOB</span>
                    <p>
                      {candidateData?.info?.documentDob
                        ? moment(candidateData?.info?.documentDob).format(
                          "DD MMM YYYY"
                        )
                        : "N/A"}
                    </p>
                  </li>
                  <li>
                    <span>Personal Email</span>
                    <p>{candidateData?.info?.email || "N/A"}</p>
                  </li>
                  <li>
                    <span>Gender</span>
                    <p>{candidateData?.info?.gender || "N/A"}</p>
                  </li>
                  <li>
                    <span>Mobile No.</span>
                    <p>{candidateData?.info?.mobile_number || "N/A"}</p>
                  </li>
                  <li>
                    <span>Emergency Contact No.</span>
                    <p>{candidateData?.info?.emergency_number || "N/A"}</p>
                  </li>
                </ul>
              </div>
            </div>

            {/* Professional Details */}
            <div className="card mt-3">
              <div className="card-title">Professional Details</div>
              <div className="Emp-Det-List">
                <ul>
                  <li>
                    <span>Employee ID</span>
                    <p>{candidateData?.info?.employeeID || "N/A"}</p>
                  </li>
                  <li>
                    <span>Company Email</span>
                    <p>{candidateData?.info?.company_email || "N/A"}</p>
                  </li>
                  <li>
                    <span>Department</span>
                    <p>{candidateData?.info?.department || "N/A"}</p>
                  </li>
                  {/* <li>
                    <span>Designation</span>
                    <p>{candidateData?.info?.designation || "N/A"}</p>
                  </li> */}
                  <li>
                    <span>Joining Date</span>
                    <p>
                      {candidateData?.info?.joining_date
                        ? moment(candidateData?.info?.joining_date).format(
                          "DD MMM YYYY"
                        )
                        : "N/A"}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-title">Family Details</div>
              <div className="Emp-Det-List">
                <ul>
                  <li>
                    <span>Name</span>
                    <p>{`${candidateData?.info?.family_member_first_name} ${candidateData?.info?.family_member_last_name && candidateData?.info?.family_member_last_name}` || "N/A"}</p>
                  </li>
                  <li>
                    <span>relationships</span>
                    <p>{`${candidateData?.info?.relationship}` || "N/A"}</p>
                  </li>
                  <li>
                    <span>Email</span>
                    <p>{candidateData?.info?.family_member_email || "N/A"}</p>
                  </li>
                  <li>
                    <span>Mobile No.</span>
                    <p>{candidateData?.info?.family_member_phone || "N/A"}</p>
                  </li>
                  {/* <li>
                    <span>Designation</span>
                    <p>{candidateData?.info?.designation || "N/A"}</p>
                  </li> */}
                  <li>
                    <span>DOB</span>
                    <p>
                      {candidateData?.info?.joining_date
                        ? moment(candidateData?.info?.family_member_dob).format(
                          "DD MMM YYYY"
                        )
                        : "N/A"}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="col-sm-8 mt-1">
          <div className="Emp-Det-left">
            <div className="accordion" id="EmployeesAccordionRobots">
              {/* Education Accordion */}
              <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingFirst">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseFirst"
                  >
                    Education
                  </button>
                </div>
                <div
                  id="regularCollapseFirst"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="lables">Secondary Passing </label>
                          <div className="Emp-form-info">
                            <p> {candidateData?.empDocument?.secondary_passing ? `${candidateData?.empDocument?.secondary_passing} %` : "NA"}</p>
                            {candidateData?.empDocument?.secondary_doc && (
                              <button className="button" onClick={() => downloadAttachment(candidateData?.empDocument?.secondary_doc)}> <IoDownloadOutline /></button>

                            )}
                          </div>

                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="lables">Senior Secondary Passing</label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.empDocument?.senior_passing ? `${candidateData?.empDocument?.senior_passing} % ` : "NA"}</p>
                            {candidateData?.empDocument?.senior_doc && (
                              <button onClick={() => downloadAttachment(candidateData?.empDocument?.senior_doc)} className="button" type="button"> <IoDownloadOutline /></button>
                            )}
                          </div>

                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="lables">Bachelor's Passing</label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.empDocument?.bachelor_passing ? `${candidateData?.empDocument?.bachelor_passing} %` : "NA"}</p>
                            {candidateData?.empDocument?.bachelor_doc && (
                              <button className="button" onClick={() => downloadAttachment(candidateData?.empDocument?.bachelor_doc)}> <IoDownloadOutline /></button>
                            )}
                          </div>


                        </div>
                      </div>

                      {candidateData?.empDocument?.extra &&
                        candidateData?.empDocument?.extra.map((extraDetail, index) => {
                          return (
                            <>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="lables">{extraDetail?.degreeField}</label>
                                  <div className="Emp-form-info">
                                    <p>{extraDetail?.degreepercent ? `${extraDetail?.degreepercent} %` : "NA"}</p>
                                    <button className="button" onClick={() => downloadAttachment(extraDetail?.degreefile)}> <IoDownloadOutline /></button>
                                  </div>

                                </div>
                              </div>
                            </>
                          );
                        })}



                    </div>
                  </div>
                </div>
              </div>

              {/* Experience Accordion */}
              <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingSecond">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseSecond"
                    aria-expanded="false"
                    aria-controls="regularCollapseSecond"
                  >
                    Experience
                  </button>
                </div>
                <div
                  id="regularCollapseSecond"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingSecond"
                  data-bs-parent="#EmployeesAccordionRobots"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="current-Box">
                        <div className="row py-2">

                          <h6 className="col-md-3 m-0">Company Name</h6>
                          <div className="col-md-9 text-secondary">{candidateData?.empDocument?.experienceData?.[0]?.companyname}</div>


                        </div>
                        <hr className="mx-0 my-2" />
                        <div className="row">


                          {candidateData?.empDocument?.experienceData?.[0]?.start_date && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">Start Date</h6>
                                <div className="col-md-6 text-secondary">
                                  {moment(candidateData?.empDocument?.experienceData?.start_date || candidateData?.empDocument?.start_date).format("LL")}
                                </div>
                              </div>
                            </div>
                          )}

                          {candidateData?.empDocument?.experienceData?.[0]?.end_date && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">End Date</h6>
                                <div className="col-md-6  text-secondary">
                                  {moment(candidateData?.empDocument?.experienceData?.end_date || candidateData?.empDocument?.end_date).format("LL")}
                                </div>
                              </div>
                            </div>
                          )}



                          {candidateData?.empDocument?.experienceData?.[0]?.experienceletter && candidateData?.empDocument?.experienceData?.[0]?.experienceletter !== "undefined" && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">Experience Letter</h6>
                                <div className="col-md-6">
                                  <div className="downloadAttachment-btn">
                                    <button onClick={() => downloadAttachment(candidateData?.empDocument?.experienceData?.[0]?.experienceletter)}
                                      type="button" className="button">
                                      <IoDownloadOutline />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}


                          {candidateData?.empDocument?.experienceData?.[0]?.offerletter && candidateData?.empDocument?.experienceData?.[0]?.offerletter !== "undefined" && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">Offer Letter</h6>
                                <div className="col-md-6">
                                  <div className="downloadAttachment-btn">
                                    <button
                                      onClick={() => downloadAttachment(candidateData?.empDocument?.experienceData?.[0]?.offerletter)}
                                      type="button" className="button">
                                      <IoDownloadOutline />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}




                          {candidateData?.empDocument?.experienceData?.[0]?.payslip && candidateData?.empDocument?.experienceData?.[0]?.payslip !== "undefined" && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">Pay slip</h6>
                                <div className="col-md-6">
                                  <div className="downloadAttachment-btn">
                                    <button
                                      onClick={() => downloadAttachment(candidateData?.empDocument?.experienceData?.[0].payslip)}
                                      type="button" className="button">
                                      <IoDownloadOutline />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {candidateData?.empDocument?.experienceData?.[0]?.relievingletter && candidateData?.empDocument?.experienceData?.[0]?.relievingletter !== "undefined" && (
                            <div className="col-6 py-1">
                              <div className="row align-items-center">
                                <h6 className="col-md-6 m-0">Relieving Letter</h6>
                                <div className="col-md-6">
                                  <div className="downloadAttachment-btn">
                                    <button
                                      onClick={() => downloadAttachment(candidateData?.empDocument?.experienceData?.[0]?.relievingletter)}
                                      type="button" className="button">
                                      <IoDownloadOutline />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        </div>
                      </div>
                      {candidateData?.empDocument?.extraExperienceData &&
                        candidateData?.empDocument?.extraExperienceData.map((extraExperienceData, index) => {
                          return (
                            <>
                              <div className="current-Box">
                                <div className="editsapse">
                                  <div className="row py-2">
                                    <h6 className="col-md-12 m-0">Others Experience Detail</h6>
                                  </div>
                                  <div className="row py-2">
                                    <h6 className="col-md-3 m-0">Company Name</h6>
                                    <div className="col-md-9 text-secondary">{extraExperienceData.companyname}</div>
                                  </div>

                                  <hr className="mx-0 my-2" />

                                  <div className="row">
                                    <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Start Date</h6>
                                        <div className="col-md-6 text-secondary">{moment(extraExperienceData?.start_date).format("LL")}</div>
                                      </div>
                                    </div>


                                    <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">End Date</h6>
                                        <div className="col-md-6 text-secondary">{moment(extraExperienceData?.end_date).format("LL")}</div>
                                      </div>
                                    </div>


                                    {/* <div className="col-6 py-1">
                                                <div className="row align-items-center">
                                                  <h6 className="col-md-6 m-0">Compensation</h6>
                                                  <div className="col-md-6">
                                                  <div className="downloadAttachment-btn">
                                                    <button
                                                      onClick={() => downloadAttachment(extraExperienceData?.compensation)}
                                                      className="button"
                                                      type="button"
                                                    >
                                                      <IoDownloadOutline />
                                                    </button>
                      
                                                    </div>
                      
                                                  </div>
                      
                                                </div>
                                              </div> */}

                                    {extraExperienceData?.experienceletter ? <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Experience Letter</h6>
                                        <div className="col-md-6">
                                          <div className="downloadAttachment-btn">
                                            <button
                                              onClick={() => downloadAttachment(extraExperienceData?.experienceletter)}
                                              className="button"
                                              type="button"
                                            >
                                              <IoDownloadOutline />
                                            </button>

                                          </div>

                                        </div>
                                      </div>
                                    </div> : <></>}


                                    {extraExperienceData?.offerletter ? <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Offer Letter</h6>
                                        <div className="col-md-6">
                                          <div className="downloadAttachment-btn">
                                            <button
                                              onClick={() => downloadAttachment(extraExperienceData?.offerletter)}
                                              className="button"
                                              type="button"
                                            >
                                              <IoDownloadOutline />
                                            </button>

                                          </div>

                                        </div>
                                      </div>

                                    </div> : <></>}




                                    {extraExperienceData?.payslip ? <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Payslip</h6>
                                        <div className="col-md-6">
                                          <div className="downloadAttachment-btn">
                                            <button
                                              onClick={() => downloadAttachment(extraExperienceData?.payslip)}
                                              className="button"
                                              type="button"
                                            >
                                              <IoDownloadOutline />
                                            </button>

                                          </div>

                                        </div>
                                      </div>
                                    </div> : <></>}


                                    {extraExperienceData?.relievingletter ? <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Relieving Letter</h6>
                                        <div className="col-md-6">
                                          <div className="downloadAttachment-btn">
                                            <button
                                              onClick={() => downloadAttachment(extraExperienceData?.relievingletter)}
                                              className="button"
                                              type="button"
                                            >
                                              <IoDownloadOutline />
                                            </button>


                                          </div>

                                        </div>
                                      </div>
                                    </div> : <></>}


                                    {extraExperienceData?.resignationletter ? <div className="col-6 py-1">
                                      <div className="row align-items-center">
                                        <h6 className="col-md-6 m-0">Resignation Letter</h6>
                                        <div className="col-md-6">
                                          <div className="downloadAttachment-btn">
                                            <button
                                              onClick={() => downloadAttachment(extraExperienceData?.resignationletter)}
                                              className="button"
                                              type="button"
                                            >
                                              <IoDownloadOutline />
                                            </button>

                                          </div>

                                        </div>
                                      </div>
                                    </div> : <></>}

                                  </div>
                                  {/* <div className="editbox">
                                      <button className="button undefined ">
                                        <LuPencil />
                                      </button>
                                    </div> */}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/* addresss */}
              <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingThird">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseThrid"
                    aria-expanded="false"
                    aria-controls="regularCollapseSecond"
                  >
                    Address
                  </button>
                </div>
                <div
                  id="regularCollapseThrid"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingThird"
                  data-bs-parent="#EmployeesAccordionRobots"
                >
                  <div className="accordion-body">


                    <div className="current-Box">

                      <div className="row py-2">
                        <div className="col-md-3">
                          <h6 className="m-0">Current Address</h6>
                        </div>
                        <div className="col-md-9 text-secondary">
                          <p className="m-0" style={{ wordBreak: "break-word" }}>
                            {candidateData?.info?.currentAddress
                              ? [
                                candidateData?.info?.currentAddress?.line1,
                                candidateData?.info?.currentAddress?.line2,
                                candidateData?.info?.currentAddress?.line3,
                              ]
                                .filter(Boolean)
                                .join(", ")
                              : "NA"}
                          </p>
                        </div>
                      </div>

                      <hr className="mx-0 my-2" />

                      <div className="row">
                        <div className="col-6 py-1">
                          <div class="row align-items-center">
                            <div className="col-md-6">
                              <h6 className="m-0">State</h6>
                            </div>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.currentAddress?.statename || "NA"}</div>
                          </div>
                        </div>

                        <div className="col-6 py-1">
                          <div class="row align-items-center">
                            <div className="col-md-6 ">
                              <h6 className="m-0">Zip</h6>
                            </div>
                            <div className="col-md-6  text-secondary">{candidateData?.info?.currentAddress?.zip || "NA"}</div>
                          </div>

                        </div>

                        <div className="col-6 py-1">
                          <div class="row align-items-center">
                            <div className="col-md-6 ">
                              <h6 className="m-0">City</h6>
                            </div>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.currentAddress?.cityname || "NA"}</div>
                          </div>

                        </div>

                        <div className="col-6 py-1">
                          <div class="row align-items-center">
                            <div className="col-md-6">
                              <h6 className="m-0">Country</h6>
                            </div>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.currentAddress?.countryname || "NA"}</div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="current-Box">
                      <div className="row py-2">
                        <div className="col-md-3">
                          <h6 className="m-0">Permanent Address</h6>
                        </div>
                        <div className="col-md-9 text-secondary">
                          <p className="m-0" style={{ wordBreak: "break-word" }}>
                            {candidateData?.info?.ParmanentAddress
                              ? [
                                candidateData?.info?.ParmanentAddress?.line1,
                                candidateData?.info?.ParmanentAddress?.line2,
                                candidateData?.info?.ParmanentAddress?.line3,
                              ]
                                .filter(Boolean) // Filters out undefined or empty lines
                                .join(", ")
                              : "NA"}
                          </p>
                        </div>
                      </div>

                      <hr className="mx-0 my-2" />

                      <div className="row">


                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">State</h6>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.ParmanentAddress?.statename || "NA"}</div>
                          </div>
                        </div>

                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Zip</h6>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.ParmanentAddress?.zip || "NA"}</div>
                          </div>
                        </div>

                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">City</h6>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.ParmanentAddress?.cityname || "NA"}</div>
                          </div>
                        </div>

                        <div className="col-6 py-1">
                          <div className="row align-items-center">
                            <h6 className="col-md-6 m-0">Country</h6>
                            <div className="col-md-6 text-secondary">{candidateData?.info?.ParmanentAddress?.countryname || "NA"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingFourth">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseFourth" aria-expanded="false" aria-controls="regularCollapseThrid">
                    Bank Details
                  </button>
                </div>
                <div id="regularCollapseFourth" className="accordion-collapse collapse" aria-labelledby="regularHeadingFourth" data-bs-parent="#EmployeesAccordionRobots">
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label class="lables">Bank Name </label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.bank_name || "N/A"}</p>

                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label class="lables">Bank Account No. </label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.account_no || "N/A"}</p>


                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label class="lables">IFSC Code</label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.info?.bankdetails?.ifsc_code || "N/A"}</p>


                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label class="lables">PAN No. </label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.info?.pancard_no || "N/A"}</p>


                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label class="lables">Aadhaar No. </label>
                          <div className="Emp-form-info">
                            <p>{candidateData?.info?.aadharcard_no || "N/A"}</p>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*      Assets */}
                {/* <div className="accordion-item">
                <div className="accordion-header" id="regularHeadingFivth">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#regularCollapseFivth"
                    aria-expanded="false"
                    aria-controls="regularCollapseFourth"
                  >
                    Assets Detail
                  </button>
                </div>
                <div
                  id="regularCollapseFivth"
                  className="accordion-collapse collapse"
                  aria-labelledby="regularHeadingFivth"
                  data-bs-parent="#EmployeesAccordionRobots"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-card d-flex flex-column flex-md-row align-items-center p-3 text-center text-md-start">
                       
                          <img
                            src={userimage}
                            alt="Profile"
                            className="mb-3 mb-md-0 me-md-3"
                            style={{
                              borderRadius: "50px",
                              width: "85px",
                              height: "85px",
                            }}
                          />

                       
                          <div className="profile-info flex-grow-1">
                            <p className="text-xl font-semibold align-items-center ">
                              Dell Laptop - #343556656
                            </p>
                            <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                              Assigned on 22 Nov, 2022 10:32AM
                            </p>
                          </div>

                          <div className="mt-3 mt-md-0">
                            <p className="text-xl font-semibold align-items-center ">
                              Assigned by
                            </p>
                            <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                              HR Mam
                            </p>
                          </div>
                          <hr></hr>
                        </div>
                        <hr style={{ border: "1px solid #b6b7b8", width: "100%", margin: "10px 0" }} />

                        <div className="profile-card d-flex flex-column flex-md-row align-items-center p-3 text-center text-md-start">
                       
                       <img
                         src={userimage}
                         alt="Profile"
                         className="mb-3 mb-md-0 me-md-3"
                         style={{
                           borderRadius: "50px",
                           width: "85px",
                           height: "85px",
                         }}
                       />

                    
                       <div className="profile-info flex-grow-1">
                         <p className="text-xl font-semibold align-items-center ">
                           Dell Laptop - #343556656
                         </p>
                         <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                           Assigned on 22 Nov, 2022 10:32AM
                         </p>
                       </div>

                       <div className="mt-3 mt-md-0">
                         <p className="text-xl font-semibold align-items-center ">
                           Assigned by
                         </p>
                         <p className="text-gray-500 d-flex align-items-center justify-content-center justify-content-md-start">
                           HR Mam
                         </p>
                       </div>
              
                     </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EmployeeProfiles;
