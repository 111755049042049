import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import "../../assets/style/TabsComponent.css"
const TabsComponent = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  return (
    <>
    <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
      <Nav variant="tabs" className="mb-3">
        {tabs.map((tab) => (
          <Nav.Item key={tab.key}>
            <Nav.Link eventKey={tab.key} className="d-flex align-items-center gap-2">
              {tab.icon && <span className="fs-5">{tab.icon}</span>} {tab.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabs.map((tab) => (
          <Tab.Pane key={tab.key} eventKey={tab.key}>
            {tab.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    
    </Tab.Container>

      </>
  );
};

export default TabsComponent;
