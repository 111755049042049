import { SearchFilter } from "Components/AttendaceReport";
import ActionLeave from "Components/LeaveRequest/ActionLeave";
import { Table } from "UI_Components";
import { Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import React, { useEffect, useState } from "react";
import AdvanceSalaryView from "./AdvanceSalaryView";
import apiManager from "networking/baseAPIManager";
import { Pagination } from "rsuite";

export default function AdvanceSalaryApproval({ approvalData, fetchapi }) {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(true);
  const [deleteModal, setDeleteModal] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [dataPayload, setdataPayload] = useState({});
  const [searchString, setSearchString] = useState("");
  const [filteredApprovalData, setFilteredApprovalData] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = approvalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, approvalData]);

  const handlePayload = (flag, rowData) => {
    if (flag === "approve") {
      setdataPayload({
        id: rowData._id,
        status: "approved",
      });
    } else {
      setdataPayload({
        id: rowData._id,
        status: "rejected",
      });
    }

    setOpen(true);
    setView(false);
    setDeleteModal(true);
  };
  const handleChangeStatus = () => {
    apiManager
      .advanceSalaryStatus(dataPayload)
      .then((res) => {
        setLoading(true);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    confirm === true && handleChangeStatus();
    setConfirm(false);
  }, [confirm === true]);
  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const customHtml1 = (data) => {
    return <ActionLeave rowData={data} handleApproveReject={(flag, data) => handlePayload(flag, data)} />;
  };
  const customHtml2 = (data) => {
    return (
      <div className="d-flex gap-3">
        {customHtml(data)}
        {customHtml1(data)}
      </div>
    );
  };
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "employee_name" },
    { header: "Salary Type", field: "advance_salary_type" },
    { header: "Amount", field: "amount" },
    { header: "Duration ", field: "instalment" },
    { header: "Applied Date", field: "createdAt", customHtml: (data) => dateHtml(data.createdAt) },
    { header: "", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setView(true);
    setOpen(true);
    setDeleteModal(false);
  };
  return (
    <div>
   
     
          <div className="col-lg-4 mt-3">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Search Employee By Name/ID"}
            />
          </div>
   
        <div className="card mt-3">
          <div className="responsive-table">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>


          <div className="d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={approvalData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
    
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "md" : "xs"} title="Employee Info">
        {view ? <AdvanceSalaryView Data={modalContent} setOpen={setOpen} /> : null}
        {deleteModal ? <ConfirmationModal setConfirm={setConfirm} setOpen={setOpen} /> : null}
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </div>
  );
}
