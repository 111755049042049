import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown, Input } from "common/components";
import AssetAssignmentInfo from "Components/AssetManagement/AssetAssignmentInfo";
import { SearchFilter } from "Components/AttendaceReport";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SubHeader, Table } from "UI_Components";
import moment from "moment";
import { Pagination } from "rsuite";

const AssetAssignment = () => {
  const [assignData, setAssignData] = useState([]);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAssignAsset, setSelectedAssignAsset] = useState();
  const [selectedEmployeeStatus, setSelectedEmployeeStatus] = useState();
  const [searchString, setSearchString] = useState();
  const [filteredApprovalData, setFilteredApprovalData] = useState(assignData);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);
  const employeeStatus = [
    { key: "all", value: "All" },
    { key: "completed", value: "Completed" },
    { key: "in probation", value: "In Probation" },
    { key: "in notice period", value: "In Notice Period" },
    { key: "relieved", value: "Relieved" },
  ];

  const dateFormat = (date) => moment(date).format("DD MMM YYYY");

  const customHtml = (data) => <Button onClick={() => handleRowClick(data)} label="View" />;

  const columns = [
    { header: "S.No", field: "" },
    {
      header: "Employee Name",
      field: "assign_to_fullname",
      customHtml: (data) => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        return (
          <>
            <span className="pt-3">
              <img src={`${BASE_URL}/${data.image}`} width="30" height="30" className="rounded-circle" alt="Employee" />
            </span>
            <span className="ps-2 text-black pt-3">{data.assign_to_fullname}</span>
          </>
        );
      },
    },
    {
      header: "Employee Status",
      field: "employee_status",
      customHtml: (data) => {
        let statusColor;
        let statusIndicator;
        let status;

        const employeeStatusLower = data.employee_status?.toLowerCase();

        if (employeeStatusLower === "completed") {
          statusColor = "green";
          statusIndicator = "●";
          status = "Active";
        } else if (employeeStatusLower === "in probation") {
          statusColor = "blue";
          statusIndicator = "●";
          status = "In Probation";
        } else if (employeeStatusLower === "in notice period") {
          statusColor = "orange";
          statusIndicator = "●";
          status = "In Notice Period";
        } else {
          statusColor = "red";
          statusIndicator = "●";
          status = "Relieved";
        }

        return (
          <>
            <span style={{ color: statusColor }}>{statusIndicator}</span>
            <span className="ps-2 text-black">{status}</span>
          </>
        );
      },
    },
    { header: "Asset Serial Number", field: "serial_number" },
    {
      header: "Purchase Date",
      field: "purchase_date",
      customHtml: (data) => dateFormat(data.purchase_date),
    },
    {
      header: "Warranty",
      field: "warranty",
      customHtml: (data) => dateFormat(data.warranty),
    },
    { header: "Assignee", field: "assignBy_fullname" },
    { header: "Action", field: "action", btnType: "primary", customHtml },
  ];

  const assetAssignmentList = () => {
    setLoading(true);
    apiManager
      .assetAssignList()
      .then((response) => {
        setAssignData(response?.data?.Data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    assetAssignmentList();
  }, [isOpen]);

  const handleRowClick = (asset) => {
    setIsOpen(true);
    setSelectedAssignAsset(asset);
  };

  useEffect(() => {
    let result = assignData;
    if (searchString) {
      result = result.filter((rowObj) => {
        return (
          rowObj.assign_to_fullname?.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.serial_number?.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }

    if (typeof selectedEmployeeStatus === "string" && selectedEmployeeStatus.trim() !== "All") {
      result = result.filter(
        (rowObj) => rowObj.employee_status?.trim().toLowerCase() === selectedEmployeeStatus.trim().toLowerCase()
      );
    }

    setFilteredApprovalData(result);
  }, [assignData, searchString, selectedEmployeeStatus]);

  return (
    <div>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
     
      <div className="container-fluid">
      <SubHeader location={useLocation()} />
        <div className="row pt-3">
          <div className="col-4">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Employee name & Asset serial Number"}
            />
          </div>
          <div className="col-8 AttendanceFilters">
            <Dropdown
              options={employeeStatus}
              onChange={(e) => setSelectedEmployeeStatus(e.target.value)}
              placeholder="Select Status"
            />
          </div>
        </div>
        <div className="card mt-3">
          <div className="responsive-table">
            <Table columns={columns} Data={displayedData} className="pointer" />
          </div>
          <div className="d-flex justify-content-end">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={filteredApprovalData.length}
          limit={itemsPerPage}
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>
        </div>

   




      <div className="col-lg-12 d-flex justify-content-end mt-4">
        <Button className="button" variant="primary" type="submit" label="Revoke" />
      </div>
      </div>
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="lg" title="Asset Info">
        <AssetAssignmentInfo
          Data={selectedAssignAsset}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setShowMSG={setShowMSG}
          setmsgType={setmsgType}
        />
      </CommonModal>

      <CoreLoader show={loading} />
    </div>
  );
};

export default AssetAssignment;
