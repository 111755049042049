import React, { useId } from "react";
import styles from "./css/input.module.css";
import Label from "./Label";

function Dropdown(props) {
  let {
    label,
    parentclasses,
    id,
    name,
    value,
    valueKey,
    onChange,
    options,
    touched,
    error,
    placeholder,
    selectclasses,
    onFocus,
  } = props;
  let uniqueId = useId();
  const placeholderText = placeholder || "Select";
  return (
    <>
      <select
        className={`form-control form-select`}
        id={id ? id : uniqueId}
        name={name}
        onFocus={onFocus}
        label={label}
        value={value}
        placeholder={placeholderText}
        onChange={(e) =>
          onChange({
            target: {
              name: name,
              value: e.target.value,
            },
          })
        }
      >
        <option value="" selected disabled>
          {placeholderText}
        </option>
        {options &&
          options.map((option) => {
            return (
              <option key={option.key || option.id} value={valueKey ? option[valueKey] : option.id || option.value}>
                {option.value || option?.company_name?.charAt(0)?.toUpperCase() + option?.company_name?.slice(1).toLowerCase() || option.name || option && option?.charAt(0).toUpperCase() + option?.slice(1).toLowerCase()}
              </option>
            );
          })}
      </select>
      {touched && error && <span className={styles.error}>{error}</span>}
    </>
  );
}

export default Dropdown;
