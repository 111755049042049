import { SearchFilter } from "Components/AttendaceReport";
import { Table } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import ManageFundView from "./ManageFundView";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Pagination } from "rsuite";

export default function EmployeePayRoll() {
  const [searchString, setSearchString] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewdata, setViewdata] = useState();
  const [PayrollDatalist, setPayrollDatalist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewButtonClicked, setViewButtonClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;
  const selectedID = selectedRows.map((data) => data._id);
  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  };

  const getMonthOptions = () => {
    return moment.months().map((month) => ({
      value: month,
      label: month,
    }));
  };

  //prev months and yaers
  useEffect(() => {
    const data = moment().format("M")
    const previousMonth = moment().subtract(1, 'month').format("MMMM");
    let previousYear = moment().format("YYYY");
    if (data == "12") {
      previousYear = moment().subtract(1, 'year').format("YYYY");
    }
    setSelectedMonth(previousMonth);
    setSelectedYear(previousYear);
  }, []);

  useEffect(() => {
    payrollApprovedlist();
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    updateDisplayedData();
  }, [PayrollDatalist, activePage, searchString]);

  const updateDisplayedData = () => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = PayrollDatalist.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));

    let filteredData = paginatedData.filter(
      (item) =>
        item.employee_name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(searchString.toLowerCase())
    );

    setDisplayedData(filteredData);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const navigate = useNavigate();
  const payrollApprovedlist = () => {
    setLoading(true);
    const monthNumber = moment().month(selectedMonth).format("M");
    apiManager
      .salaryList({ month: monthNumber, year: selectedYear })
      .then((response) => {
        setLoading(false);
        setPayrollDatalist(response?.data?.SalaryList);
        setSelectAll(false);
        setSelectedRows([]);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleCheckboxChange = (event, data) => {
    const { checked } = event.target;
    if (checked && data.salary_status === "approved") {
      setSelectedRows((prev) => [...prev, data]);
    } else {
      setSelectedRows((prev) => prev.filter((row) => row.employeeID !== data.employeeID));
    }
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      const approvedRows = PayrollDatalist.filter((row) => row.salary_status === "approved");
      setSelectedRows(approvedRows);
    } else {
      setSelectedRows([]);
    }
  };

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };

  const columns = [
    {
      header: <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />,
      field: "checkbox",
      customHtml: (data) => (
        <input
          type="checkbox"
          checked={selectedRows.some((row) => row.employeeID === data.employeeID)}
          onChange={(e) => handleCheckboxChange(e, data)}
          disabled={data.salary_status !== "approved"}
        />
      ),
    },
    { header: "S.No", field: "" },
    { header: "Employee Name", field: "empname" },
    { header: "Employee ID", field: "employeeID" },
    { header: "Designation", field: "designation" },
    { header: "Present Days", field: "presentDay" },
    {
      header: "Net Pays",
      field: "netSalary",
      customHtml: (data) => {
        const netPay = data.netSalary ? data.netSalary.toFixed(2) : "0.00";
        return <span>{netPay}</span>;
      },
    },
    { header: "Advance Amount", field: "advanceSalary" },
    {
      header: "Status",
      field: "salary_status",
      customHtml: (data) => {
        const statusColor = data.salary_status === "pending" ? "red" : data.salary_status === "hold" ? "blue" : "green";
        return <span style={{ color: statusColor }}>{data.salary_status ? data?.salary_status?.charAt(0)?.toUpperCase() + data?.salary_status?.slice(1)?.toLowerCase() : ""}</span>;
      },
    },
    { header: "Action", field: "actions", btnType: "primary", customHtml },
  ];

  const handleItemClick = (data) => {
    setIsOpen(true);
    setViewdata(data);
    setViewButtonClicked(true);
  };

  const handlesendToApprovepay = () => {
    setLoading(true);
    const id = { id: selectedID };
    apiManager
      .paySalary(id)
      .then((response) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        payrollApprovedlist();
      })
      .catch((error) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "failed to update");
        console.error(error);
      });
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Search Employee By Name/ID"}
            />
          </div>

          <div className="col-lg-8 AttendanceFilters">

            <Dropdown
              parentclasses=""
              value={selectedMonth}
              onChange={handleMonthChange}
              options={getMonthOptions()}
              placeholder="Month"
              sidelabel
            />


            <Dropdown
              parentclasses=""
              value={selectedYear}
              onChange={handleYearChange}
              options={getYearOptions()}
              placeholder="Year"
              sidelabel
            />

            <Button label="Approved Salary" onClick={() => navigate("/hrtools/manage-fund/edit-salary")} />
          </div>
        </div>
        <div className="card mt-3">
          <div className="responsive-table">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>

          <div className="d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={PayrollDatalist.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>

        </div>
        <div className="d-flex gap-3 justify-content-end mt-3">
          <Button label="Send To Pay" onClick={handlesendToApprovepay} />
        </div>
      </div>
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="lg" title="Employee Salary">
        <ManageFundView data={viewdata} isOpen={isOpen} setIsOpen={setIsOpen} showPayButton={viewButtonClicked} />
      </CommonModal>

      <CoreLoader show={loading} disableLines />
    </>
  );
}
