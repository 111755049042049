import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import { toWords } from "number-to-words";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { convertDateFormat, monthLong } from "utils/dateFunctions";
import companyLogo from "../../assets/images/paysliplogo.png"
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function ManageFundView({
  data,
  setIsOpen,
  showPayButton,
  showPayHoldButton,
  showPaySlipButton,
  isOpen,
  setmsgType,
  setShowMSG,
}) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [advansSalaryData, setAdvansSalaryData] = useState([]);
  const [isPDFGenerated, setIsPDFGenerated] = useState(false);
  const [isbasestring, setIsBaseString] = useState("")
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getTransiontionList = () => {
    setLoading(true);
    apiManager
      .transiontionList(data.empid)
      .then((response) => {
        setAdvansSalaryData(response?.data?.transionhistorie[0]);
      })
      .catch((error) => {
        console.log("Error fetching payroll data:", error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const { toPDF, targetRef } = usePDF({ filename: "salary-slip.pdf" });
  // const handlePDFGeneration = () => {
  //   toPDF().then(() => {
  //     setIsPDFGenerated(true); // Hide the button after PDF is generated
  //   });
  // };
  useEffect(() => {
    getTransiontionList();
  }, [data.empid]);

  const convertToWords = (amount) => {
    return toWords(amount) + " only";
  };
  const handlePDFGeneration = () => {
    document.getElementById("pdfId").remove()
    if (!isbasestring) {
      console.warn("Base64 image not set, fetching...");
      getBase64(companyLogo).then((base64) => {
        setIsBaseString(base64);
        setTimeout(() => { generatePDF(base64); setIsOpen(!isOpen) }, 1000); // Ensure image loads
      }).catch((err) => {
        console.error("Error loading image:", err);
        alert("Failed to load image. Please try again.");
      });
    } else {
      generatePDF(isbasestring);

    }
  };

  const generatePDF = async (logoBase64) => {
    if (!targetRef.current) {
      console.error("Target element not found!");
      alert("Error generating PDF: Target element missing.");
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for styles to apply

    html2canvas(targetRef.current, {
      scale: 2, // Increase scale for better quality
      useCORS: true,
      allowTaint: true,
      logging: false,
      letterRendering: true,
      backgroundColor: "#ffffff", // Ensure background is white
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.9); // High-quality image
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        // compress: true,
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pdfWidth - 10; // Keep 5mm margin on both sides
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

      pdf.addImage(imgData, "JPEG", 5, 5, 200, 200); // Dynamic size
      pdf.save("Payslip.pdf");
      setIsOpen(false);
    }).catch((err) => {
      console.error("PDF Generation Error:", err);
      alert("Error generating PDF. Check console logs.");
    });
  };


  const getBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open("GET", url, true); // Ensure it's an async request
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*"); // Handle CORS issues
      xhr.responseType = "blob";
      xhr.send();
    });
  };


  useEffect(() => {
    getBase64(companyLogo).then((base64) => {
      console.log("Fetched Base64 logo:", base64);
      setIsBaseString(base64);
    }).catch((err) => console.error("Error converting image:", err));
  }, []);
  return (
    //poppins css style={{ fontFamily:"Poppins, Arial, sans-serif", letterSpacing: "1px", wordSpacing: "1.5px" }}
    <div ref={targetRef} >
      <div className="m-4 p-4 border rounded card">
        <header className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
          <div className="paySlipLogo" id="">

            <img src={isbasestring} alt="logo" />
          </div>

          <div className="Payslip-top-text">
            Payslip For the Month <strong>{monthLong(data.date)}</strong>
          </div>
        </header>
        <section className="mb-3">
          <h6 className="fw-bold text-dark mb-3">EMPLOYEE SUMMARY</h6>
          <div className="row">
            <div className="col-md-7 PayslipEmployeeSummary">
              <p><span>Employee Name</span>:<strong className="ms-2  fw-semibold text-dark">{data.empname || "NA"}</strong> </p>
              <p><span>Designation</span>:<strong className="ms-2  fw-semibold text-dark">{data?.designation || "NA"}</strong> </p>
              <p><span>Employee ID</span>:<strong className="ms-2  fw-semibold text-dark">{data.employeeID || "NA"}</strong> </p>
              <p><span>Date of Joining</span>: <strong className="ms-2  fw-semibold text-dark"> {data.date_of_joining || "NA"}</strong></p>
              <p><span>Pan No</span>: <strong className="ms-2  fw-semibold text-dark">{data.pancard_No}</strong> </p>
              <p><span>Pay Date</span>: <strong className="ms-2  fw-semibold text-dark"> {convertDateFormat(data.pay_date) || "NA"}</strong></p>
            </div>
            <div className="col-md-5">
              <section className="salary-top-box">

                <div className="salary-bg-color">
                  <h4 className="text-success">₹{data.netSalary}</h4>
                  <p>Employee Net Pay</p>
                </div>
                <div>
                  <div className="px-4 mt-2 PaidDays">
                    <p className="mb-1">
                      <span>Paid Days</span>   : <strong className="ms-2  fw-semibold text-dark">{data.paydays || 0}</strong>
                    </p>
                    <p className="m-1">
                      <span>LOP Days</span>    : <strong className="ms-2  fw-semibold text-dark">{data.absent_day || 0}</strong>
                    </p>
                  </div>
                </div>
              </section>
              <div className="mt-1 border  border-1 rounded-1 px-4 py-2 PayslipUn" >
                <p className="mb-1"><span>PF No</span> : <strong className="ms-2 fw-semibold text-dark">{data.pf_number || 0}</strong> </p>
                <p className="m-0"><span>UAN No</span> : <strong className="ms-2  fw-semibold text-dark">{data.uan_number || 0}</strong> </p>
              </div>
            </div>

          </div>
        </section>
        <section className="my-3">
          <h6 className="fw-bold text-dark">EARNINGS</h6>
          <table className="main_table Salarytable">
            <thead>
              <tr>
                <th><span className=" fw-bold ">Type</span></th>
                <th><span className=" fw-bold ">Amount</span></th>
                <th><span className=" fw-bold ">YTD</span></th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Basic</td><td className=" fw-semibold text-dark">₹{data?.basicSalary}</td><td className=" fw-semibold text-dark">₹{data?.basicSalary}</td></tr>
              <tr><td>Travel Allowance</td><td className=" fw-semibold text-dark">₹{data?.ta}</td><td className=" fw-semibold text-dark">₹{data?.ta}</td></tr>
              <tr><td>Dearness Allowance (DA)</td><td className=" fw-semibold text-dark">₹{data?.da}</td><td className=" fw-semibold text-dark">₹{data?.da}</td></tr>
              <tr><td>House Rent Allowance</td><td className=" fw-semibold text-dark">₹{data?.hra}</td><td className=" fw-semibold text-dark">₹{data?.hra}</td></tr>
              <tr className="table-light fw-semibold"><td className=" fw-semibold text-dark">Gross Earnings</td><td className="fw-bold  text-dark">₹{data?.totalGrossSalary}</td><td></td></tr>
            </tbody>
          </table>
        </section>
        <section className="my-3">
          <h6 className="fw-bold text-dark">DEDUCTIONS</h6>
          <table className="main_table Salarytable">
            <thead>
              <tr>
                <th><span className=" fw-bold ">Type</span></th>
                <th> <span className=" fw-bold ">Amount</span></th>
                <th><span className=" fw-bold ">YTD</span></th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Leave Deduction</td><td className=" fw-semibold text-dark">₹{data.leaveDeduction || 0}</td><td className=" fw-semibold text-dark">₹{data.leaveDeduction || 0}</td></tr>
              <tr><td>EPF Contribution</td><td className=" fw-semibold text-dark">₹{data.pf || 0}</td><td className=" fw-semibold text-dark">₹{data.pf || 0}</td></tr>
              <tr><td>ESI Contribution</td><td className=" fw-semibold text-dark">₹{data.esi || 0}</td><td className=" fw-semibold text-dark">₹{data.esi || 0}</td></tr>
              <tr className="table-light fw-semibold"><td>Total Deductions</td><td className="fw-bold text-dark">₹{data.totaldeduction || 0}</td><td></td></tr>
            </tbody>
          </table>
        </section>
        <section className="bg-light p-3 rounded border">
          <div className="row align-flex-end">
            <div className="col-8">
              <h5 className="fw-bold text-dark">TOTAL NET PAYABLE</h5>
              <p className="m-0"><strong >Amount In Words: </strong>{convertToWords(data.totalnetsalary)}</p>
            </div>
            <div className="col-4">
              <h4 className="totalnetsalary text-success">₹{data.totalnetsalary}</h4>
            </div>
          </div>
        </section>
        <p className="font-light  mt-4 ms-3">--This document has been automatically generated by SinghTek Payroll; a signature is not required.--</p>
        {data?.payment_status &&
          <button
            onClick={handlePDFGeneration}
            className="button undefined "
            id="pdfId"
            onMouseOver={(e) => (e.target.style.opacity = 0.8)}
            onMouseOut={(e) => (e.target.style.opacity = 1)}
          >
            Download Salary
          </button>}

      </div>
    </div>
  );
}
