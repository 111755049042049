import React, { useEffect, useState } from "react";
import { AlertMessage, Button, CoreLoader, Input, Label } from "common/components";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddressFormSchema, OnboardingFormSchema } from "utils/formValidationUtils";
import apiManager from "networking/baseAPIManager";
import { Dropdown } from "common/components";
import "../../../../assets/style/Responsive.css";
import { IoCheckbox } from "react-icons/io5";

function AddressDetails() {
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectState, setSelectState] = useState([]);
  const [selectCity, setSelectCity] = useState([]);
  const [selectStatePermanent, setSelectStatePermanent] = useState([]);
  const [selectCityPermanent, setSelectCityPermanent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const paramsData = useParams();
  let empid = paramsData?.empid;

  const [EmpData, setEmpdata] = useState({});
  const [nextPage, setnextPage] = useState(false);

  const getCandidateDetails = () => {
    setLoading(true);
    apiManager
      .candidateAddressDetails(empid)
      .then((candidate) => {
        setLoading(false);
        setEmpdata(candidate?.data?.data);
        setnextPage(!!candidate?.data?.nextPage);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (empid) getCandidateDetails();
  }, [empid]);

  const forminitialValue = {
    c_aLine1: EmpData.currentAddress?.line1 || "",
    c_aLine2: EmpData.currentAddress?.line2 || "",
    c_aLandmark: EmpData?.currentAddress?.line3 || "",
    c_aState: EmpData.currentAddress?.state || "",
    c_aCity: EmpData.currentAddress?.city || "",
    c_aZip: EmpData.currentAddress?.zip || "",
    c_country: EmpData.currentAddress?.country || "",
    sameAddress: EmpData?.sameAddress || false,
    p_aLine1: EmpData.ParmanentAddress?.line1 || "",
    p_aLine2: EmpData.ParmanentAddress?.line2 || "",
    p_aLandmark: EmpData.ParmanentAddress?.line3 || "",
    p_aState: EmpData.ParmanentAddress?.state || "",
    p_aCity: EmpData.ParmanentAddress?.city || "",
    p_aZip: EmpData.ParmanentAddress?.zip || "",
    p_country: EmpData.ParmanentAddress?.country || "",
  };

  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: AddressFormSchema,
    onSubmit: (values) => {
      handleSaveData(values);
    },
    enableReinitialize: true,
  });

  const handleSaveData = (data) => {
    let payload = {
      empid: empid,
      currentAddress: {
        line1: data?.c_aLine1,
        line2: data?.c_aLine2,
        line3: data?.c_aLandmark,
        city: data?.c_aCity,
        state: data?.c_aState,
        zip: data?.c_aZip,
        country: data?.c_country,
      },
      ParmanentAddress: {
        line1: data?.p_aLine1,
        line2: data?.p_aLine2,
        line3: data?.p_aLandmark,
        city: data?.p_aCity,
        state: data?.p_aState,
        zip: data?.p_aZip,
        country: data?.p_country,
      },
      sameAddress: data.sameAddress,
    };
    setLoading(true);
    apiManager
      .addEmployeeAddress(payload)
      .then((response) => {
        setShowMSG("Details updated");
        setmsgType("success");
        setLoading(false);
        navigate(`/hrtools/employee-onboarding/educationdetails/${empid}`, {
          state: { ...EmpData, ...response?.data?.data },
        });
      })
      .catch((error) => {
        setShowMSG("Failed to update details");
        setmsgType("error");
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    apiManager
      .country()
      .then((country) => {
        setLoading(false);
        setSelectCountry(country.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (values.c_country) {
      setLoading(true);
      apiManager
        .state(values.c_country)
        .then((state) => {
          setLoading(false);
          setSelectState(state.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [values.c_country]);

  useEffect(() => {
    if (values.p_country) {
      setLoading(true);
      apiManager
        .state(values.p_country)
        .then((state) => {
          setLoading(false);
          setSelectStatePermanent(state.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [values.p_country]);

  useEffect(() => {
    if (values.c_aState) {
      setLoading(true);
      apiManager
        .city(values.c_aState)
        .then((city) => {
          setLoading(false);
          setSelectCity(city.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [values.c_aState]);

  useEffect(() => {
    if (values.p_aState) {
      setLoading(true);
      apiManager
        .city(values.p_aState)
        .then((city) => {
          setLoading(false);
          setSelectCityPermanent(city.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [values.p_aState]);

  useEffect(() => {
    if (values.c_aLine1 && values.c_country && values.c_aState && values.c_aCity) {
      setIsCheckboxDisabled(false);
    } else {
      setIsCheckboxDisabled(true);
    }
  }, [values.c_aLine1, values.c_country, values.c_aState, values.c_aCity]);
  useEffect(() => {
    handleSameAddressChange(values?.sameAddress);
  }, [!!values?.sameAddress]);

  const handleSameAddressChange = (isChecked) => {
    if (isChecked) {
      setFieldValue("p_aLine1", values.c_aLine1);
      setFieldValue("p_aLine2", values.c_aLine2);
      setFieldValue("p_aLandmark", values.c_aLandmark);
      setFieldValue("p_aState", values.c_aState);
      setFieldValue("p_aCity", values.c_aCity);
      setFieldValue("p_aZip", values.c_aZip);
      setFieldValue("p_country", values.c_country);
    }

    setFieldValue("sameAddress", isChecked);
  };

  const renderAddressForm = (title, keys, permanentAddress) => {
    let stateData = selectState;
    let cityData = selectCity;
    if (permanentAddress) {
      stateData = selectStatePermanent;
      cityData = selectCityPermanent;
    }

    // console.log(title, "title");
    // console.log(keys, "keys");
    return (
      <>
    
          <div className="row mt-1">
            <div className="col-sm-4">
              <div className="form-group">
                <label className="lables"> Locality / Area / Street</label>
                <Input
                  type="text"
                  name={keys["line1"]}
                  placeholder="Flat, House No. , Building, Apartment *"
                  value={values[keys.line1]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched[keys.line1] && !errors[keys.line1]}
                  touched={touched[keys.line1]}
                  error={errors[keys.line1]}
                  maxLength={80}
                />
              </div>
            </div>
            <div className="col-sm-4">
            <div className="form-group">
            <label className="lables"> Flat number / Building Name</label>
            <Input
              type="text"
              placeholder="Area, Street, Sector, Village *"
              name={keys["line2"]}
              value={values[keys.line2]}
              onChange={handleChange}
              maxLength={80}
              onBlur={handleBlur}
              isValid={touched[keys.line2] && !errors[keys.line2]}
              touched={touched[keys.line2]}
              error={errors[keys.line2]}
            />
          </div>
            </div>
            <div className="col-sm-4">
            <div className="form-group">
            <label className="lables"> Landmark</label>
            <Input
              type="text"
              name={keys["line3"]}
              placeholder="Landmark (E.g. near apollo hospital)"
              value={values[keys["line3"]]}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched[keys.line3] && !errors[keys.line3]}
              touched={touched[keys.line3]}
              error={errors[keys.line3]}
              maxLength={80}
            />
          </div>
              </div>
              <div className="col-sm-4">
              <div className="form-group">
            <Label label="Country"  required />
            <Dropdown
              valueKey="id"
              required
              type="text"
              name={keys["country"]}
              value={values[keys["country"]]}
              options={selectCountry}
              onChange={(e) => {
                setFieldValue(keys["country"], e.target.value);
              }}
              onBlur={handleBlur}
              isValid={touched[keys["country"]] && !errors[keys["country"]]}
              touched={touched[keys["country"]]}
              error={errors[keys["country"]]}
            />
          </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
            <Label label="State"  required />
            <Dropdown
              valueKey="id"
              type="text"
              required
              name={keys["state"]}
              value={values[keys["state"]]}
              options={stateData}
              onChange={(e) => {
                setFieldValue(keys["state"], e.target.value);
              }}
              onBlur={handleBlur}
              isValid={touched[keys["state"]] && !errors[keys["state"]]}
              touched={touched[keys["state"]]}
              error={errors[keys["state"]]}
            />
          </div>
              </div>
              <div className="col-sm-4">
              <div className="form-group">
            <Label label="City" required />

            <Dropdown
              valueKey="id"
              required
              type="text"
              name={keys["city"]}
              value={values[keys["city"]]}
              options={cityData}
              onChange={(e) => {
                setFieldValue(keys["city"], e.target.value);
              }}
              onBlur={handleBlur}
              isValid={touched[keys["city"]] && !errors[keys["city"]]}
              touched={touched[keys["city"]]}
              error={errors[keys["city"]]}
            />
          </div>
              </div>
              <div className="col-sm-4">
              <div className="form-group">
            <Label label="Zip Code" required />
            <Input
              type="text"
              name={keys["zip"]}
              value={values[keys["zip"]]}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched[keys["zip"]] && !errors[keys["zip"]]}
              touched={touched[keys["zip"]]}
              error={errors[keys["zip"]]}
              maxLength={6}
            />
          </div>
              </div>
          </div>
        


      </>
    );
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

      <form onSubmit={handleSubmit}>
      <div className="card">
      {/* <FaArrowLeftLong /> */}
          <div className="Onboarding-title">Candidate Onboarding</div>
          <div className="Onboarding-info-row">
            <div className="Onboarding-check active">
              <IoCheckbox />
              <div className="Onboarding-check-title">Candidate Details</div>
            </div>
            <div className="Onboarding-check active">
              <IoCheckbox />
              <div className="Onboarding-check-title">Current Address</div>
            </div>
            <div className="Onboarding-check">
              <IoCheckbox />
              <div className="Onboarding-check-title">Education Details</div>
            </div>
            <div className="Onboarding-check">
              <IoCheckbox />
              <div className="Onboarding-check-title">Experience Details</div>
            </div>
          </div>
        </div>

        <div className="card mt-4">
        <div class="card-top"><div class="card-title">Current Address</div></div>
          {renderAddressForm("Current Address", {
            line1: "c_aLine1",
            line2: "c_aLine2",
            line3: "c_aLandmark",
            state: "c_aState",
            city: "c_aCity",
            zip: "c_aZip",
            country: "c_country",
          })}
          <p className="mt-2 mb-0 card-title">Permanent Address</p>
          <div className="formgroup-check">
            <Input
              className="form-check-input "
              type="checkbox"
              name="sameAddress"
              checked={values.sameAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.sameAddress}
              disabled={isCheckboxDisabled}
            />
            {/* <Label label="" /> */}
            <label className="lables" for="input:r19:">Same as Current Address</label>
            
          </div>
          {!values.sameAddress
            ? renderAddressForm(
              "Permanent Address",
              {
                line1: "p_aLine1",
                line2: "p_aLine2",
                line3: "p_aLandmark",
                state: "p_aState",
                city: "p_aCity",
                zip: "p_aZip",
                country: "p_country",
              },
              true
            )
            : ""}
        </div>

        <div className="d-flex gap-3 justify-content-end mt-4">
          <Button
            classes="btn-Continue"
            type="button"
            label="Back"
            onClick={() => navigate(`/hrtools/employee-onboarding/candidatedetails/${empid}`)}
          />
          <Button
            type="submit"
            label="Save & Continue"
             classes="responsiver-button"
            // classes="btn-Continue"
          />
          {nextPage && empid ? (
            <Button
               classes="btn-Continue"
              type="button"
              label="Next"
              onClick={() => navigate(`/hrtools/employee-onboarding/educationdetails/${empid}`)}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      <CoreLoader show={loading} disableLines />
    </>
  );
}

export default AddressDetails;
