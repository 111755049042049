import { closeIcon } from "assets/images";
import { Button, Dropdown, GroupInput, Input, Label } from "common/components";
import React, { useState } from "react";

function AddEducationModal(props) {
  const { setShow, show, data, setData, decimalFormat } = props;
  const degrees = [
    { id: "B.Tech", name: "B.Tech" },
    { id: "BCA", name: "BCA" },
    { id: "B.COM", name: "B.COM" },
    { id: "BA", name: "BA" },
    { id: "M.Tech", name: "M.Tech" },
    { id: "MCA", name: "MCA" },
    { id: "MBA", name: "MBA" },
    { id: "M.COM", name: "M.COM" },
    { id: "MSc", name: "MSc" },
    { id: "Phd", name: "Phd" },
    { id: "Diploma", name: "Diploma" },
    { id: "M.A", name: "M.A" },
    { id: "Certification", name: "Certification" },
    { id: "Others", name: "Others" },
  ];

  const [fieldValues, setFieldValue] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tmpData = data ? structuredClone(data) : [];

  const handleChange = (e, key, file) => {
    const value = file ? e.target.files[0] : e.target.value;
    setFieldValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    const newErrors = {};

    if (!fieldValues.degreeField) {
      newErrors.degreeField = "This field is required";
    }

    if (fieldValues.degreeField === "Certification") {
      // newErrors.degreepercent = "";
    } else if (!fieldValues.degreepercent) {
      newErrors.degreepercent = "This field is required"
    } else {
      const degreePercentValue = parseFloat(fieldValues.degreepercent);
      const specialCharPattern = /[!@#$%^&*(),+?":{}|<>]/g;
      if (isNaN(degreePercentValue)) {
        newErrors.degreepercent = "Degree percent must be a number";
      } else if (degreePercentValue < 32 || degreePercentValue > 100) {
        newErrors.degreepercent = "Degree percentage must be between 33 and 100";
      } else if (specialCharPattern.test(fieldValues.degreepercent)) {
        newErrors.degreepercent = "Degree percent must not contain special characters";
      }
    }

    if (!fieldValues.degreefile) {
      newErrors.degreefile = "This field is required";
    } else {
      const file = fieldValues.degreefile;
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        newErrors.degreefile = "File must be a JPG, PNG, or PDF";
      }
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        newErrors.degreefile = "File size must be less than 2 MB";
      }
    }
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    if (tmpData.length > 0) {
      tmpData.push(fieldValues);
    } else {
      tmpData.push(fieldValues);
    }
    setData(tmpData);
    setShow(false);
    setIsSubmitting(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center mb-4">
      <div className="relative pt-4 bordermodal">
        <div className="closeIcon" onClick={() => setShow(false)}>
          <img src={closeIcon} alt="Close" />
        </div>

        <Label label="Degree" required labelclasses="pb-1" />
        <Dropdown
          placeholder="Postgraduate"
          name="degreeField"
          value={fieldValues.degreeField}
          options={degrees}
          onChange={(e) => handleChange(e, "degreeField")}
          error={errors.degreeField}
          touched={true}
        />
        <div>
          <Label label="Passing Percentage" required labelclasses="pb-1" />
          <GroupInput
            type="text"
            placeholder="Passing Percentage"
            name="degreepercent"
            value={decimalFormat(fieldValues.degreepercent || "")}
            onChange={(e) => handleChange(e, "degreepercent")}
            group="%"
            error={errors.degreepercent}
            touched={true}
            maxLength={5}
          />

        </div>

        <Label label="Upload Documents" required labelclasses="pb-1" />
        <Input
          accept="image/jpeg, image/png, application/pdf"
          type="file"
          name="degreefile"
          onChange={(e) => handleChange(e, "degreefile", true)}
          error={errors.degreefile}
          touched={true}
        />
        <p className="mb-0">

          <span className="filesize">Maximum file size allowed : 2MB.</span>
        </p>
        <div className="d-flex justify-content-end pt-2">
          <Button onClick={handleSave} type="button" label="Save" disabled={isSubmitting} />
        </div>
      </div>
    </div>
  );
}

export default AddEducationModal;
