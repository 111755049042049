import { CardWrapper, CoreLoader } from "common/components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import ProfileDrawer from "./ProfileDrawer";
import userImage from "../../assets/images/UserDefultImage.png";
import { setGetLocalData } from "utils/localStorageHelper";
import isResourceAvailable from "utils/permit_util";

let BASE_URL = process.env.REACT_APP_BASE_URL;

const ProfileCard = ({ flagFromAttendance, filteredApprovalData, employeeOnleave }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const id = setGetLocalData("id");
  const drawerPermission = useSelector((state) => state?.data?.id);
  const hasPermission =
    id === data._id || drawerPermission === "ADMIN" || drawerPermission === "RECRUITMENT";

  // const handleCardClick = (detail) => {
  //   console.log(detail, 'details')
  //   setOpen(true);
  //   setData(detail);
  //   navigate(
  //     '/employee/profile',
  //     {
  //       state: {
  //         data: detail
  //       }
  //     }
  //   )
  //   // Navigate to profile page if the user has permission
  //   // if (
  //   //   id !== detail._id ||
  //   //   drawerPermission === "ADMIN" ||
  //   //   drawerPermission === "RECRUITMENT"
  //   // ) {
  //   //   navigate("/employee/profile");
  //   // }
  // };

  const handleCardClick = (empid) => {
    navigate(`/hrtools/employee-onboarding/candidatePreview/${empid}`)
  };
  return (
    <div className="row EmployeeCardRow">
      {flagFromAttendance === "leave"
        ? employeeOnleave.map((detail, index) => {
          const fullName = `${detail?.empid?.firstname || ""} ${detail?.empid?.lastname || ""}`;
          return (
            <CardWrapper
              onClick={() => handleCardClick(detail)}
              key={index}
            >
              <img
                className="EmployeeImageRound"
                onClick={() => handleCardClick(detail)}
                src={
                  detail?.empid?.image
                    ? `${BASE_URL}/${detail?.empid?.image}`
                    : userImage
                }
                alt={fullName}
              />
              <h5 className="EmployeeTitle">{fullName}</h5>
              <p className="EmployeeTitlesub">{detail?.empid?.department}</p>
            </CardWrapper>
          );
        })
        : filteredApprovalData.map((detail, index) => {
          const fullName = `${detail?.firstname || ""} ${detail?.middlename || ""} ${detail?.lastname || ""
            }`;
          return (
            <CardWrapper
              onClick={() => handleCardClick(detail?._id)}
              key={index}
            >
              <img
                className="EmployeeImageRound"
                onClick={() => handleCardClick(detail?._id)}
                src={detail?.image ? `${BASE_URL}/${detail?.image}` : userImage}
                alt={fullName}
              />
              <h5 className="EmployeeTitle">{fullName}</h5>
              <p className="EmployeeTitlesub">
                {detail?.designation ? detail?.designation : "NA"}
              </p>
            </CardWrapper>
          );
        })}
      <CoreLoader show={loading} />
    </div>
  );
};

export default ProfileCard;
