import moment from "moment";

export const getFirstMondayOfMonth = (year, month) => {
  const firstDayOfMonth = new Date(year, month, 26);

  const dayOfWeek = firstDayOfMonth.getDay();
  const daysUntilMonday = (7 - dayOfWeek + 1) % 7;
  const firstMonday = new Date(year, month - 1, 1 + daysUntilMonday);

  const lastDateOfMonth = new Date(year, month, 0).getDate();
  return { firstMonday, daysUntilMonday, lastDateOfMonth, firstDayOfMonth };
};

export const dynamicDate = (anyDate, dateNum) => {
  const date = new Date(anyDate);

  date.setDate(date.getDate() + dateNum);
  return date;
};

export const formateDate = (date) => {
  return date.toLocaleDateString(date);
};
export const getDayName = (finiteDate, day) => {
  const date = new Date(finiteDate);
  const dayNameIndex = date.getDay();
  return day[dayNameIndex];
};
export const convertDateFormat = (inputDate) => {
  // Parse the input date
  const parsedDate = new Date(inputDate);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // Invalid date, return the original input
    return inputDate;
  }

  // Get the components of the date
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");

  // Format the date as "mm/dd/yyyy"

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

//date formate in 02-11-2002
export const convertDateFormatDash = (inputDate) => {
  // Parse the input date
  const parsedDate = new Date(inputDate);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // Invalid date, return the original input
    return inputDate;
  }

  // Get the components of the date
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");

  // Format the date as "yyyy-mm-dd"

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateCharcter = (inputDate, formatType) => {
  const date = new Date(inputDate);
  if (isNaN(date)) {
    return "Invalid Date";
  }


  const day = String(date.getDate()).padStart(2, "0");
  const monthIndex = date.getMonth(); // 0-based index
  const year = date.getFullYear();

  // Month names for formatting
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (inputDate) {
    return `${day}-${monthNames[monthIndex]}`;
  }
}

//aniversary completed
export const employeeAniversary =(inputDate)=>{
  let DOJ = moment(inputDate).format("YYYY,MM,DD");
  let today = moment().format("YYYY,MM,DD");
  let totalYear = parseInt(moment(today).diff(moment(DOJ), 'year'));
  if(totalYear > 0){
    return totalYear
  }
}
//cureent moth date year
export const currentDate = (date) => {
  if (!date) {
    return "-"
  }
  const currentDate = new Date(date);
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  return formattedDate
}
// months long formate 
export const monthLong = (date) => {
  const currentDate = new Date(date);
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',

  });
  return formattedDate
}

//amount validations

export const formattedAmount = (amount) => {

  const Amount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0, // Ensures two decimal places
  }).format(amount);
  return Amount
}

