import React, { useEffect, useState } from "react";
import { Button } from "common/components";
import { Table } from "UI_Components";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import isResourceAvailable from "utils/permit_util";
import { setGetLocalData } from "utils/localStorageHelper";
import { IoEyeOutline } from "react-icons/io5";
import { currentDate } from "utils/dateFunctions";

const Completed = (props) => {
  const { data } = props;
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const id = setGetLocalData("id");
  const rolePermission = isResourceAvailable("role");
  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = data.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, data]);
  const customHtml = (data) => {
    return (
      <Button
        label="Edit Details"
        onClick={() => {
          navigate(`/hrtools/employee-onboarding/candidatedetails/${data._id}`);
        }}
        disabled={
          (rolePermission === "ADMIN" ? false : "") || // Always disabled for ADMIN
          // data._id === id || // Disabled if data._id matches id
          (rolePermission === "EMPLOYEE" && data?.status == "completed") // Disabled for EMPLOYEE unless status is "completed"
        }

      />
    );
  };


  //view
  const handleItemClick = (data) => {
    navigate(`/hrtools/employee-onboarding/candidatePreview/${data._id}`);
  };
  const customHtmlView = (data) => {
    return (
      <IoEyeOutline
        className={`Viewbtn ${rolePermission === "EMPLOYEE" ? "disabled-icon" : ""}`}
        onClick={() => {
          if (rolePermission !== "EMPLOYEE") {
            handleItemClick(data);
          }
        }}
      />
    );
  };
  
  const columns = [
    { header: "S.No", field: "" },
    { header: "ID", field: "employeeID" },
    {
      header: "Name",
      field: "firstname",
      customHtml: (data) => {
        const fullName = `${data.firstname} ${data.middlename ? data.middlename : ""} ${data.lastname}`;
        return <span>{fullName}</span>;
      },
    },
    { header: "Designation", field: "designation" },
    {
      header: "Date Of Joining", field: "joining_date",
      customHtml: (data) => {
        const joiningDate = currentDate(data.joining_date);
        return <span>{joiningDate}</span>;
      },
    },
    // { header: "Experience", field: "experience" },
    { header: "Mobile No.", field: "mobile_number" },
    // { header: "Salary", field: "monthlycompensation" },
    // { header: "Skill", field: "onbordingstart" },
    { header: "Action", field: "actions", customHtml: customHtml },
    { header: "View", field: "view", customHtml: customHtmlView },
  ];
  // const handleClick = (data) => {
  //   navigate("/hrtools/employee-onboarding/candidatedetails", { state: data });
  // };
  const handleClick = (data) => {
    navigate(`/hrtools/employee-onboarding/candidatedetails/${data._id}`);
  };
  return (
    <>
      <div className="card Tablecard">

        {/* <OnboardingTable filteredData={data} setSelectType={setSelectType} /> */}

        <div className="responsive-table">
          <Table columns={columns} Data={displayedData} onClick={handleClick} />
        </div>
        <div className="d-flex justify-content-end">
          <Pagination
            prev
            last
            next
            first
            size="sm"
            total={data.length}
            limit={itemsPerPage}
            activePage={activePage}
            onChangePage={setActivePage}
          />
        </div>
      </div>
    </>
  );
};

export default Completed;
