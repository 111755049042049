import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  dashboardSideMenu,
  employeeDashboardSideMenu,
} from "../../common/json/MenuContent";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import isResourceAvailable from "utils/permit_util";
import { MdOutlineDashboard, MdOutlineLogout } from "react-icons/md";
import { CommonModal } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { clearLocalData } from "utils/localStorageHelper";
import { setAuthToken } from "redux/slice";
import Cookies from "js-cookie";

const SidebarContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState([]);
  const [activePath, setActivePath] = useState(location.pathname);
  const [activeSublink, setActiveSublink] = useState(null);
  const [menuClose, setMenuClose] = useState(false);
  const rolePermission = isResourceAvailable("role");
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [activedashboard, setActiveDashBoard] = useState(true);
  const isLoggedIn = useSelector((state) => state?.data?.token);
  // Handle menu collapse on smaller screens
  useEffect(() => {
    const handleMediaChange = (e) => {
      setMenuClose(e.matches);
    };
    const mediaQuery = window.matchMedia("(max-width: 990px)");
    mediaQuery.addEventListener("change", handleMediaChange);
    setMenuClose(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, []);

  // Filter menu items based on rolePermission
  useEffect(() => {
    if (rolePermission === "ADMIN") {
      setMenuItems(dashboardSideMenu);
    } else if (
      rolePermission === "EMPLOYEE"
    ) {
      setMenuItems(employeeDashboardSideMenu);
    }
  }, [location.pathname, rolePermission]);

  // Handle item and subitem clicks
  const handleMenuItemClick = (item) => {
    setActivePath(item.path);
    setActiveDashBoard(false);
  };

  const handleSubItemClick = (subItem) => {
    setActiveSublink(subItem.link);
  };

  const handleChangeDashboard = () => {
    setActivePath("");
    if (
      rolePermission === "EMPLOYEE"
    ) {
      window.location.href = "/employee/dashboard";
    } else {
      if (rolePermission === "ADMIN") {

        window.location.href = "/hrtools";
      }

    }

    // navigate("/hrtools");
  };
  console.log(activePath);
  const handleLogout = () => {
    navigate("/login");
    clearLocalData();
    localStorage.removeItem("token")
    Cookies.remove("token")
    dispatch(setAuthToken(""));
  };
  useEffect(() => {
    if (activePath === "/hrtools") {
      setActivePath("");
    } else {
      // handleMenuItemClick()
    }
  }, [activePath]);

  useEffect(() => {
    confirm && handleLogout();
  }, [confirm]);
  return (
    <>
      <div className={`sideBarw ${menuClose ? "sideBar-close" : ""}`}>
        <div
          className="sideBarmenu-icon"
          onClick={() => setMenuClose(!menuClose)}
        >
          <FaRegArrowAltCircleLeft className="d-menu" />
          <FaRegArrowAltCircleRight className="m-menu" />
        </div>
        <div className="link-item-main">
          <ul className="Dashboardmenu">
            <li
              className={`link-item ${activedashboard ? "activenavlink" : ""}`}
            >
              <NavLink
                onClick={() => {
                  handleChangeDashboard();
                }}
              >
                <MdOutlineDashboard /> <span className="ps-2 dn">Dashboard</span>
              </NavLink>
            </li>
          </ul>
          <ul className="p-0 m-0">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className={`${activePath === item.path
                    ? "activenavlink link-item"
                    : " link-item "
                  }`}
              >
                <NavLink
                  to={item.path}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <span>{item.icon}</span>
                  {!menuClose && <span className="ps-2">{item?.title}</span>}
                  {activePath === item.path && !menuClose && item?.subLinks && (
                    <div className="list-subLinks">
                      {item.subLinks.map((subItem, i) => (
                        <NavLink
                          to={subItem.link}
                          key={i}
                          className={`${activeSublink === subItem.link
                              ? "activeSublink"
                              : ""
                            }`}
                          onClick={() => handleSubItemClick(subItem)}
                        >
                          <span>{subItem.title}</span>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        {isLoggedIn && (
          <div className="LogOut-menu">
            <a className="LogOut-link" onClick={() => setOpen(true)}>
              <MdOutlineLogout /> <span className="dn">Log Out</span>
            </a>
          </div>
        )}

        <CommonModal
          open={open}
          onClose={() => setOpen(false)}
          size="sm"
          title={"Log Out"}
        >
          <ConfirmationModal
            setConfirm={setConfirm}
            setOpen={setOpen}
            Message="Are you sure want to log out ?"
          />
        </CommonModal>
      </div>
    </>
  );
};

export default SidebarContent;
