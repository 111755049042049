import styles from "./css/input.module.css";

const Label = (props) => {
  const { labelclasses, label, required, uniqueId, htmlFor, For } = props;

  return (
    <>
      <label
        className={labelclasses ? labelclasses :`lables`}
        htmlFor={`${"input" + uniqueId} ${htmlFor}`}
        for={`${For}`}
      >
        {label} {required && <span className="astricRequired">*</span>}
      </label>
    </>
  );
};

export default Label;
