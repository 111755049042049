import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState, useCallback } from "react";
import { SearchFilter } from "./SearchFilter";
import { Button, Dropdown } from "common/components";
import { Table } from "UI_Components";
import moment from "moment";
import { Pagination } from "rsuite";

export default function AttendanceTrackingReport() {
  const [searchString, setSearchString] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredApprovalData, setFilteredApprovalData] = useState([]);
  const [attendanceListData, setAttendanceListData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);

  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "fullname" },
    { header: "Department", field: "Department" },
    { header: "Date", field: "date", customHtml: (data) => dateHtml(data.date) },
    { header: "Check-in", field: "checkInTime", customHtml: (data) => formatTime(data.checkInTime) },
    { header: "Check-out", field: "checkOutTime", customHtml: (data) => formatTime(data.checkOutTime) },
    {
      header: "Work Hours",
      field: "hours",
      customHtml: (data) => {
        const Hours = data.hourse;
        const Min = data.minutes;
        const Sec = data.seconds;
        const workinghours = `${Hours ? Hours : "0"}h ${Min ? Min : "0"}m ${Sec ? Sec : "0"}s`;
        return <span>{workinghours}</span>;
      },
    },
  ];

  const dateHtml = useCallback((date) => moment(date).format("DD MMM YYYY"), []);
  const formatTime = useCallback((isoString) => moment(isoString).format("LT"), []);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      fetchAttendanceList();
    }
  }, [selectedYear, selectedMonth]);

  const fetchAttendanceList = useCallback(() => {
    setLoading(true);
    const monthNumber = moment().month(selectedMonth).format("M");
    apiManager
      .OverallList({ year: selectedYear, month: monthNumber })
      .then((response) => {
        setLoading(false);
        const list = response.data?.List ?? [];
        setAttendanceListData(list);
        setFilteredApprovalData(list);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    let result = attendanceListData.filter(
      (rowObj) =>
        rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
        rowObj.fullname.toLowerCase().includes(searchString.toLowerCase())
    );

    if (selectedDay) {
      result = result.filter((rowObj) => moment(rowObj.date).date() === parseInt(selectedDay));
    }

    setFilteredApprovalData(result);
  }, [attendanceListData, searchString, selectedDay]);

  const getYearOptions = useCallback(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  }, []);

  const getMonthOptions = useCallback(() => {
    return moment.months().map((month) => ({
      value: month,
      label: month,
    }));
  }, []);

  const getDayOptions = useCallback(() => {
    const daysInMonth = moment(`${selectedYear}-${selectedMonth}`, "YYYY-MMMM").daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => ({
      value: day,
      label: day.toString(),
    }));
  }, [selectedYear, selectedMonth]);

  const handleResetFilters = () => {
    setSelectedYear("");
    setSelectedMonth("");
    setSelectedDay("");
    setFilteredApprovalData(attendanceListData);
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().format("MMMM");
    setSelectedYear(currentYear);
    setSelectedMonth(currentMonth);
  }, []);

  return (
    <div>
      <div className="card">
        <div className="row">
          <div className="col-4">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder="Search Employee By Name/ID"
            />
          </div>
          <div className="col-8 AttendanceFilters">
            <Dropdown
              parentclasses=""
              value={selectedDay}
              onChange={(event) => setSelectedDay(event.target.value)}
              options={getDayOptions()}
              placeholder="Day"
            />
            <Dropdown
              parentclasses=""
              value={selectedMonth}
              onChange={(event) => setSelectedMonth(event.target.value)}
              options={getMonthOptions()}
              placeholder="Month"
            />
            <Dropdown
              parentclasses=""
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
              options={getYearOptions()}
              placeholder="Year"
            />
            <Button onClick={handleResetFilters} label="Reset Filters" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 px-2 tableContainer">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
            )}
          </div>
          <div className="d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={filteredApprovalData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
