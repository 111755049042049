import NoDataFound from "common/components/NoDataFound";
import React from "react";

const Table = ({ Data, columns, LeaveTableHeight, className, onClickRow }) => {
  if (!Data || !Data.length) {
    return (
      <div className="text-center p-2 h6">
        <NoDataFound />
      </div>
    );
  }

  return (

      <table className={`main_table ${LeaveTableHeight}`}>
        <thead>
          <tr className="">
            {columns.map((head, index) => (
              <th scope="col" key={index}>
                {head.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Data.map((row, rowIndex) => {
            return (
              <>
                <tr key={rowIndex} onClick={() => onClickRow && onClickRow(row)} className={className}>
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="">
                      {col.field === "sno"
                        ? rowIndex + 1
                        : col.field === ""
                        ? row.sno
                        : col.customHtml
                        ? col.customHtml(row)
                        : row[col.field]}
                    </td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
  
  );
};

export default Table;
