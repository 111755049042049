import { SubHeader, Table } from "UI_Components";
import {
  Button,
  Label,
  Input,
  Dropdown,
  CoreLoader,
  AlertMessage,
} from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/style/Responsive.css";
const AddHolidayForm = ({ setOpen, data }) => {
  const [selectCountry, setSelectCountry] = useState([]);
  const [leftSelectState, setLeftSelectState] = useState([]);
  const [rightSelectState, setRightSelectState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setMsgType] = useState("info");

  const [leftSelectedCountry, setLeftSelectedCountry] = useState();
  const [leftSelectedState, setLeftSelectedState] = useState();

  const [rightSelectedCountry, setRightSelectedCountry] = useState();
  const [rightSelectedState, setRightSelectedState] = useState();
  const currentYear = new Date().getFullYear();
  const [leftSelectedYear, setLeftSelectedYear] = useState(currentYear);
  const [rightSelectedYear, setRightSelectedYear] = useState(currentYear);
  const [holiday, setHoliday] = useState({});

  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState("pending");
  const [holidayInputs, setHolidayInputs] = useState(
    Array.from({ length: 1 }, () => ({
      holiday_name: "",
      date: "",
      country: "",
      state: "",
    }))
  );

  console.log(holidayInputs, "holidayInputs");
  useEffect(() => {
    holidayInputs &&
      holidayInputs.map((list, index) => {
        setHoliday(list);
      });
  }, [holidayInputs]);

  const years = Array.from({ length: 3 }, (_, index) => currentYear + index);
  const navigate = useNavigate();

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };

  const customHtml1 = (data) => {
    return <Button onClick={() => handleEditModal(data)} label="Edit" />;
  };

  const customHtml2 = (data) => (
    <div className="Actions-data-select">
      {data.holiday_status === "pending" ? (
        <Button onClick={() => handleItemClick(data)} label="View" />
      ) : (
        <Button onClick={() => handleEditModal(data)} label="Edit" />
      )}
    </div>
  );
  const moment = require("moment");
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");
  const columns = [
    {
      header: "Date",
      field: "date",
      customHtml: (data) => formatDate(data?.date),
    },
    { header: "Country", field: "country" },
    {
      header: "State",
      field: "state",
    },
    {
      header: "Status",
      field: "holiday_status",
      customHtml: (data) => {
        const statusColor =
          data?.holiday_status?.toLowerCase() === "pending"
            ? "blue"
            : data?.holiday_status?.toLowerCase() === "rejected"
            ? "red"
            : "green";

        return (
          <span style={{ color: statusColor }}>{data.holiday_status}</span>
        );
      },
    },
    {
      header: "Actions",
      field: "actions",
      btnType: "primary",
      customHtml: customHtml2,
    },
  ];

  const handleItemClick = (data) => {
    navigate(`/hrtools/attendance/holiday-list-calendar/${data._id}`);
  };

  const handleEditModal = (data) => {
    navigate(`/hrtools/attendance/holiday-list-calendar/${data._id}`);
  };

  useEffect(() => {
    setLoading(true);
    apiManager
      .country()
      .then((country) => {
        setLoading(false);
        setSelectCountry(country.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (leftSelectedCountry) {
      apiManager
        .stateByName(leftSelectedCountry)
        .then((state) => {
          setLeftSelectState(state.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [leftSelectedCountry]);

  useEffect(() => {
    setLoading(true);
    if (rightSelectedCountry) {
      apiManager
        .stateByName(rightSelectedCountry)
        .then((state) => {
          setLoading(false);
          setRightSelectState(state.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [rightSelectedCountry]);

  const handleLeftSelectCountry = (value) => {
    setLeftSelectedCountry(value);
  };

  const handleLeftSelectState = (value) => {
    setLeftSelectedState(value);
  };

  const handleRightSelectCountry = (value) => {
    setRightSelectedCountry(value);
  };

  const handleRightSelectState = (value) => {
    setRightSelectedState(value);
  };

  const handleHolidayInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedInputs = [...holidayInputs];
    // if (name === "holiday_name") {
    //   const regex = /^[A-Za-z \/-]*$/;
    //   if (!regex.test(value)) {
    //     return;
    //   }
    // }
    if (name === "date") {
      const selectedDate = new Date(value);
      if (!isNaN(selectedDate.getTime())) {
        const year = leftSelectedYear;
        const month = selectedDate.getMonth() + 1;
        const day = selectedDate.getDate() + 1;
        const newDate = new Date(year, month - 1, day);
        if (!isNaN(newDate.getTime())) {
          updatedInputs[index][name] = newDate.toISOString().split("T")[0];
        } else {
          console.error("Invalid new date");
        }
      } else {
        console.error("Invalid selected date");
      }
    } else {
      updatedInputs[index][name] = value;
    }

    setHolidayInputs(updatedInputs);
  };

  const handleAddMoreHolidayInput = () => {
    if (holidayInputs.length >= 1) {
      setHolidayInputs([
        ...holidayInputs,
        { holiday_name: "", date: "", country: "", state: "" },
      ]);
    }
  };

  const handleRemoveHolidayInput = (index) => {
    if (holidayInputs.length > 1) {
      const updatedInputs = [...holidayInputs];
      updatedInputs.splice(index, 1);
      setHolidayInputs(updatedInputs);
    }
  };

  const handleLeftYearChange = (leftSelectedYear) => {
    setLeftSelectedYear(leftSelectedYear);
  };

  const handleRightYearChange = (rightSelectedYear) => {
    setRightSelectedYear(rightSelectedYear);
  };

  const handleSaveAndContinue = (event) => {
    event.preventDefault();
    setLoading(true);

    const filledHolidayInputs = holidayInputs.filter(
      (input) => input.holiday_name.trim() !== "" && input.date.trim() !== ""
    );
    const specificState = leftSelectState.find(
      (item) => item.id == leftSelectedState
    );

    const yearAsNumber = parseInt(leftSelectedYear);

    const requestData = {
      country: leftSelectedCountry,
      state: leftSelectedState,
      year: yearAsNumber,
      holiday_name: holiday.holiday_name,
      date: holiday.date,
    };

    apiManager
      .addbulkholiday(requestData)
      .then((res) => {
        setLoading(false);
        setMsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
        const newHolidays = filledHolidayInputs.map((input) => ({
          date: input.date,
          country: selectCountry[leftSelectedCountry]?.id,
          state: specificState?.id,
        }));

        setTableData([...tableData, ...newHolidays]);
        setStatus("pending");
        fetchData();
        setHolidayInputs(
          Array.from({ length: 1 }, () => ({
            holiday_name: "",
            date: "",
            country: "",
            state: "",
          }))
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setShowMSG(error?.response?.data?.message || "failed to update");
      });
  };

  const fetchData = () => {
    const pendingParams = {
      holiday_status: "pending",
      year: rightSelectedYear ? rightSelectedYear : currentYear,
      country: rightSelectedCountry,
      state: rightSelectedState,
    };

    const rejectedParams = {
      holiday_status: "rejected",
      year: rightSelectedYear ? rightSelectedYear : currentYear,
      country: rightSelectedCountry,
      state: rightSelectedState,
    };

    const pendingPromise = apiManager.holidaylist(pendingParams);
    const rejectedPromise = apiManager.holidaylist(rejectedParams);

    Promise.all([pendingPromise, rejectedPromise])
      .then((results) => {
        const pendingHolidays = results[0].data.list;
        const rejectedHolidays = results[1].data.list;
        const mergedHolidays = [...pendingHolidays, ...rejectedHolidays];

        setTableData(mergedHolidays);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching holiday list:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [rightSelectedCountry, rightSelectedState, rightSelectedYear]);

  let dropdownData = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  return (
    <>
      <div className="Container m-0 p-0">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="ps-3">
          <SubHeader location={useLocation()} />
        </div>
        <div className="row pt-2 m-3">
          <div className="card">
           
            <div className="row">
              <div className="col-6">
                <div
                  className="border rounded p-3"
                  style={{ maxHeight: "640px", overflow: "Auto" }}
                >
                  <div className="row">
                    <div className="col-4">
                      <Dropdown
                        valueKey="name"
                        parentclasses=""
                        required
                        placeholder="Select Country"
                        options={selectCountry}
                        onChange={(e) => {
                          handleLeftSelectCountry(e.target.value);
                        }}
                        value={leftSelectedCountry}
                      />
                    </div>
                    <div className="col-4">
                      <Dropdown
                        valueKey="name"
                        parentclasses=""
                        required
                        placeholder="Select State"
                        options={leftSelectState}
                        onChange={(e) => handleLeftSelectState(e.target.value)}
                        value={leftSelectedState}
                      />
                    </div>
                    <div className="col-4">
                      <SelectPickerInput
                        sidelabel="Select Year"
                        defaultselect={currentYear}
                        data={dropdownData}
                        value={leftSelectedYear}
                        valueKey={"value"}
                        onChange={handleLeftYearChange}
                      />
                    </div>
                  </div>
                  {holidayInputs.map((input, index) => (
                    <div className="w-100" key={index}>

                      <div className="border rounded mb-3 mt-3  p-3">
                        <div className="form-group">
                        <Label
                          label="Holiday Name"
                        />
                        <Input
                          type="text"
                          name="holiday_name"
                          inputclasses="form-controlcustom w-100"
                          value={input.holiday_name}
                          onChange={(e) => handleHolidayInputChange(index, e)}
                          maxLength={50}
                          minLength={3}
                        />
                        </div>
                        <div className="form-group">
                        <Label
                          label="Date"
      
                        />
                        <Input
                          type="date"
                          name="date"
                          inputclasses="form-controlcustom w-100"

                          value={input.date}
                          onChange={(e) => {
                            handleHolidayInputChange(index, e);
                          }}
                          maxLength={5}
                        />
                         </div>

                        {index >= 1 && (
                          <div className="col-md-12">
                            <Button
                              type="button"
                              label="Remove"
                              onClick={() => handleRemoveHolidayInput(index)}
                              className="btn btn-danger"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="d-flex gap-3 justify-content-end py-2 col-md-12">
                    <Button type="button" label="Add More" onClick={handleAddMoreHolidayInput} />
                    <Button
                      type="submit"
                      label="Save & Continue"
                      onClick={handleSaveAndContinue}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="border rounded p-3">

                  <div className="row">
                    <div className="col-4">
                      <Dropdown
                        valueKey="name"
                        parentclasses="col-12"
                        required
                        placeholder="Select Country"
                        options={selectCountry}
                        onChange={(e) => {
                          handleRightSelectCountry(e.target.value);
                        }}
                        value={rightSelectedCountry}
                      />
                    </div>
                       <div className="col-4">
                      <Dropdown
                        valueKey="name"
                        parentclasses=""
                        required
                        placeholder="Select State"
                        options={rightSelectState}
                        onChange={(e) => handleRightSelectState(e.target.value)}
                        value={rightSelectedState}
                      />
                    </div>
                    <div className="col-4">
                      <SelectPickerInput
                        sidelabel="Select Year"
                        defaultselect={rightSelectedYear}
                        data={dropdownData}
                        value={rightSelectedYear}
                        valueKey={"value"}
                        onChange={handleRightYearChange}
                      />
                    </div>
                  </div>

                  <div className="row p-3">
                    <Table
                      columns={columns}
                      btn_name="view"
                      secondary_btn_label="Edit"
                      Data={tableData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
};

export default AddHolidayForm;
