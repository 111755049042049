import { Table } from "UI_Components";
import { Button, CommonModal } from "common/components";

import React, { useEffect, useState } from "react";
import LeaveView from "./LeaveView";
import SelectPickerInput from "common/components/SelectPicker";
import { Pagination } from "rsuite";
function LeaveComplete({ completeData, fetchapi }) {
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState({});
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [filteredData, setFilteredData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);

  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredData]);
  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "employee_name" },
    { header: "Leave Type", field: "type" },
    { header: "Start Date", field: "start_date", customHtml: (data) => dateHtml(data.start_date) },
    { header: "End Date", field: "end_date", customHtml: (data) => dateHtml(data.end_date) },
    { header: "Total Leave", field: "leave_days" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status.toLowerCase() === "approved"
            ? "approvedClass"
            : data.status.toLowerCase() === "rejected"
            ? "rejectedClass"
            : "actionsPending";

        return <span className={statusColor}>{data.status}</span>;
      },
    },
    { header: "Actions", field: "actions", btnType: "primary", customHtml },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleYearChange = (value) => {
    const newSelectedYear = value != null ? value : currentYear;
    setSelectedYear(newSelectedYear);
  };
  useEffect(() => {
    {
      try {
        const updatedData = completeData.filter(
          (item) => new Date(item.start_date).getFullYear().toString() === selectedYear.toString()
        );
        setFilteredData(updatedData);
      } catch (error) {
        console.error("Error processing data:", error);
      }
    }
  }, [completeData, selectedYear]);

  let dropdownData = years.map((year) => ({ label: year.toString(), value: year.toString() }));

  return (


    <div className="card mt-3">

<div className="card-top align-items-center">
 <div className="card-head-left">
  <div className="card-title">Complete Request</div>

 </div>

<div className="card-head-right">
<SelectPickerInput
            sidelabel="Select Year"
            defaultselect={currentYear}
            data={dropdownData}
            value={selectedYear}
            valueKey={"value"}
            onChange={handleYearChange}
          />
        </div>
  
      </div>
      <Table
        columns={columns}
        Data={displayedData}
        btn_name="view"
        LeaveTableHeight="LeaveTableHeight"
        custom_btn="custom_btn"
        custom_btn2="custom_btn2"
      />

      <div className="d-flex justify-content-end">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={filteredData.length}
          limit={itemsPerPage}
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>

      {
        <CommonModal open={open} onClose={handleClose} title="Leave Details">
          <LeaveView setOpen={setOpen} Data={modalContent} fetchapi={fetchapi} showButton={true} />
        </CommonModal>
      }
    </div>
  );
}

export default LeaveComplete;
