
import moment from "moment";

export const MainContentReport = ({ startDate, reportData, message }) => {
  const weekArray = ["M", "Tu", "W", "Th", "F", "S", "S"];

  const startOfMonth = moment(startDate.firstDayOfMonth);

  // Subtract one year if the month is December
  if (startOfMonth.month() === 11) { // 11 represents December (0-based index)
    startOfMonth.subtract(1, "year");
  }
  
  const endOfMonth = startOfMonth.clone().endOf("month");
  const lastDateOfMonth = endOfMonth.date();
  
  const getDayName = (date, weekArray) => {
    // Adjusting for weekArray to match moment's weekday (0 = Sunday)
    const dayIndex = (date.day() + 6) % 7; // Shift 0 (Sunday) to end of array
    return weekArray[dayIndex];
  };

  const dynamicDate = (baseDate, index) => moment(baseDate).add(index, "days");
  const checkHoliday = (date) => {
    const holidays = [
      moment("2025-01-01"), // Example holiday: January 1st, 2025
      moment("2025-01-15"), // Example holiday: January 15th, 2025
    ];
    return holidays.some((holiday) => holiday.isSame(date, "day"));
  };

  return (
    <>
      <table className="main_table ">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col">
              Name
            </th>
            {Array.from({ length: lastDateOfMonth }, (_, index) => {
              const currentDate = dynamicDate(startOfMonth, index); // Calculate current date
   
              const isSunday = currentDate.day() === 0; // Check if it's Sunday (0 = Sunday in moment.js)
              const isHoliday = checkHoliday(currentDate); // Function to check if it's a holiday
              const formattedDate = `${currentDate.format('MMM').charAt(0).toUpperCase()}${currentDate.format('MMM').slice(1).toLowerCase()}`; // Format date with short month name

              return (
                <th
                  key={index}
                  style={{
                    color: isSunday
                      ? "red" // Sunday = Red color
                      : isHoliday
                        ? "#00cc99" :// Holiday = Green color
                        isSunday && isHoliday ? "#ff66ff" : "#B5B7C0", // Default color
                  }}
                >
                  {formattedDate}
                  <br />
                  {currentDate.date()} 
                  <br /> {getDayName(currentDate, weekArray)}
                </th>
              );
            })}

          </tr>
        </thead>
        <tbody>
          {reportData  ? (
            reportData.map((employee, index) => (
              <tr key={employee.employeeID}>
                <td>{index + 1}</td>
                <td>
                  {employee.name} 
                </td>
                {Array.from({ length: lastDateOfMonth }, (_, index) => {
                  const currentDate = moment(startOfMonth).add(index, 'days'); // Date for each cell
                  const today = moment(); // Define today here for each loop iteration
                  const attendanceForDate = employee?.attendance?.find(att =>
                    moment(att.date).isSame(currentDate, 'day') // Match attendance date with calendar date
                  );
                  return (
                    <td key={index}>
                      {attendanceForDate ? (
                        <p style={{ color: `${attendanceForDate.color}` }}>
                          {attendanceForDate.status || "A"} {/* Default to "P" */}
                        </p>
                      ) : currentDate.isBefore(today, 'day') || currentDate.isSame(today, 'day') ? (
                        <p style={{ color: "gray" }}></p>
                      ) : null /* Don't show anything for future dates */}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={lastDateOfMonth + 2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4rem",
                    flexDirection: "column",
                    fontFamily: "cursive",
                    fontSize: "1.8rem",
                  }}
                >
                  {message}
                </div>
              </td>
            </tr>
          )}
        </tbody>

      </table>
    </>
  );
};
